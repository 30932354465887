<template>
  <div class="legend" :class="loading">
    <p class="ml-20 font-uppercase" v-if="!currentList.length">
      по указанным критериям поиска объекты не найдены
    </p>
    <flex
      v-for="item in currentList"
      :key="item.id"
      class="tree py-10 pr-20"
      :class="item.node.guid ? 'link' : 'group'"
      :style="{
        paddingLeft: `${20 + item.lvl * 10}px`
      }"
      tabindex="1"
      align-items="center"
      @click="onClickItem(item)"
    >
      <icon
        name="down-small"
        class="icon-toggle mr-10"
        :class="{
          open: hasOpen(item.id),
          hidden: !hasChildren(item.id)
        }"
      />
      <span
        v-if="!item.node.guid && item.node.violation_type_id"
        :class="
          `violation-icon property-${item.node.property_type_id} violation-${item.node.violation_type_id}`
        "
        class="mr-10"
      ></span>
      <flex fill class="title">{{ item.node.name }}</flex>
      <flex class="cnt ml-10">{{ item.node.cnt }}</flex>
    </flex>
  </div>
</template>

<script>
import useObjectsTree from '@/composition/map/useObjectsTree';
import useDefaultValues from '@/composition/useDefaultValues';
import { computed, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import {
  treeToFlat,
  setExpander,
  cleanState,
  hasOpen,
  hasChildren,
  setIdKey,
  setPidKey
} from '@/composition/useFlatTree';

export default {
  name: 'iopr-legend',

  props: {
    list: {
      default: []
    },
    parentId: {
      type: [String, Number],
      default: null
    },
    selectedId: {
      default: ''
    },
    deepLevel: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { defaultValues } = useDefaultValues();
    const { load: loadSections, loading } = useObjectsTree();
    const init = async () => {
      loadSections({
        areaId: route.query.areaId || defaultValues.value.AREA_ID,
        districtId: route.query.districtId
      });
    };
    init();

    setIdKey('id');
    setPidKey('parent_id');
    const currentList = computed(() => {
      return treeToFlat(props.list);
    });

    const onClickItem = item => {
      if (item.node.guid) {
        emit('open-object', item.node);
      } else {
        setExpander(item.id, !hasOpen(item.id));
      }
    };

    onUnmounted(async () => {
      cleanState();
    });

    return {
      loading,
      currentList,
      hasOpen,
      hasChildren,
      onClickItem
    };
  }
};
</script>

<style lang="scss" scoped>
.legend {
  .tree {
    min-height: 50px;
    font-size: 13px;
    border-bottom: 1px solid #eee;
    outline: none;

    &.group {
      text-transform: uppercase;
      color: $gray;
    }

    &.link {
      font-size: 13px;
      color: $primary;
    }
  }
  .icon-toggle {
    &:before {
      transform: rotate(-90deg);
    }

    &.open:before {
      transform: none;
    }

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
