import app from '@/app.js';
import mapPanel from './controls/MapPanel.vue';
import filter from './controls/Filters.vue';
import search from './controls/Search.vue';
import zoomMap from './controls/zoom.vue';

app.component('MapPanel', mapPanel);
app.component('MapFilters', filter);
app.component('MapSearch', search);
app.component('zoomMap', zoomMap);
/*
import mapContainer from "./mapContainer.vue"

app.component("MapContainer", mapContainer);






*/
