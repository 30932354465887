<template>
  <flex
    class="modal-subslot"
    :class="{ border_top: border_top, border_bottom: border_bottom }"
  >
    <div class="mt-15">
      <template v-if="!disabled">
        <div class="title-common mb-10">
          <div v-if="!titleDisabled">КОПИИ ДОКУМЕНТОВ:</div>
          {{ tooltipText }}
        </div>
        <div class="disc_slot mb-10">
          Не более 10 файлов общим объемом <br />
          не более 10 Mb
        </div>
      </template>
      <div v-if="!uploadedFiles.length">----</div>
      <div class="row">
        <div class="modal-slot__files">
          <div v-for="(item, z) in uploadedFiles" :key="z" class="">
            <div class="modal-slot__docs-name">
              <a :href="item.url" download="">{{ item.title }} </a>
              <icon
                v-if="(!disabled_edit || item.now) && !disabled"
                @click="remove(z)"
                class="ml-10"
                :clolor="'#fd6b6b'"
                :name="'close'"
              ></icon>
              <!--<span class="modal-slot__docs-date">{{
                formatDate(item.creation_date)
              }}</span>-->
            </div>
          </div>
          <template v-if="uploadFolder == state_uploadFolder && !disabled">
            <div
              v-for="(item, i) in queue"
              :key="i"
              class="modal-slot__photos-item"
            >
              <flex class="modal-slot__photos-add">
                <div class="button_bord_blue btn_loading">
                  <div class="file-loading">
                    <div class="uploadProgressPercent">
                      {{ uploadProgressPercent }}%
                    </div>
                    <div class="file-loading__bar">
                      <div
                        v-bind:style="{ width: uploadProgressPercent + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </flex>
            </div>
          </template>

          <div v-if="!disabled" class="modal-slot__photos-item">
            <div class="modal-slot__photos-add" :class="{ disabled: disabled }">
              <input
                :id="uploadFolder"
                ref="uploadInput"
                type="file"
                multiple
                name="file"
                @change="change($event)"
                class="input-file"
                :disabled="disabled"
                title=""
              />
              <div class="button_bord_blue btn_add_photo">
                <span>+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </flex>
</template>

<script>
import useEditUpload from '@/composition/useEditUpload';
import objectHelpers from '@/composition/object/objectHelpers';
import { computed } from 'vue';

export default {
  name: 'EditPhotos',
  props: {
    border_bottom: { default: true },
    border_top: { default: true },
    titleDisabled: Boolean,
    disabled_edit: { default: false },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    disabledDeleteTooltipText: {
      type: String,
      default: 'Удаление невозможно'
    },
    proxyData: {
      default: []
    },
    uploadFolder: {}
  },
  setup(props, { emit }) {
    const formatDate = value => {
      return value ? objectHelpers.formatDate(value) : '';
    };

    const uploadedFiles = computed({
      get() {
        return props.proxyData;
      },
      set(data) {
        emit('update:proxyData', data);
      }
    });

    let {
      state_uploadFolder,
      uploadProgressPercent,
      queue,
      cancel,
      sync
    } = useEditUpload();

    const remove = i => {
      uploadedFiles.value.splice(i, 1);
      emit('update:modelValue', uploadedFiles.value);
    };

    const change = async event => {
      await sync(
        event.target.name,
        event.target.files,
        props.uploadFolder
      ).then(a => {
        a.forEach(el => {
          if (el.folder_code == props.uploadFolder) {
            el.now = true;
            uploadedFiles.value.push(el);
          }
          emit('update:modelValue', uploadedFiles.value);
        });
      });
      const uploadInput = document.getElementById(props.uploadFolder);
      if (uploadInput) {
        uploadInput.value = '';
      }
    };

    return {
      remove,
      state_uploadFolder,
      change,
      formatDate,
      uploadedFiles,
      uploadProgressPercent,
      queue,
      cancel,
      sync
    };
  }
};
</script>

<style lang="scss" scoped>
.border_bottom {
  border-bottom: 1px solid #e8e8e8;
}
.disc_slot {
  color: #7b898d;
}
.modal-slot__docs-name {
  display: flex;
  align-items: center;
  a {
    color: #6d8bd0;
    text-decoration: none;
  }
  .modal-slot__docs-date {
    display: block;
    margin-left: 25px;
    font-size: 13px;
    color: #aaa;
  }
}
.modal-slot__photos-add {
  height: 68px;
  width: 68px !important;
  margin-top: 10px;
  input {
    z-index: 100;
    height: 68px;
    width: 68px !important;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  align-items: center;
  justify-content: center;
  border: solid 1px #6d8bd0;
  border-radius: 5px;
  position: relative;
  display: inline-block;
}

.modal-slot__photos-item {
  height: 68px;
  width: 68px !important;
  margin-right: 10px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}

.modal-slot__photos-file {
  position: relative;
}

.title-common {
  color: #333333 !important;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}
.el-collapse-item__content {
  color: #808080;
}

.modal-subslot {
  padding-bottom: 10px;
}

.row {
  display: inline-block;
}

.modal-slot__files {
  .btn_loading {
    margin-top: 31px;
    height: 70px;
    width: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .file-loading {
      .uploadProgressPercent {
        top: 50%;
        left: 40%;
        position: absolute;
        text-align: center;
        color: #6d8bd0;
        line-height: 0px;
        font: normal normal bold 13px/16px;
        font-weight: 100;
      }
      .file-loading__bar {
        position: absolute;
        top: 40%;
        left: 20%;
        background: #e8e8e8 0% 0% no-repeat padding-box;
        margin-top: 20px;
        min-width: 40px;
        min-height: 8px;
        div {
          height: 8px;
          background: #808080;
        }
      }
    }
  }
  .btn_add_photo {
    margin-top: -2px;
    height: 70px;
    width: 70px !important;
    display: flex;
    justify-content: center;
    span {
      position: absolute;
      margin-top: 13px;
      margin-left: -2px;
      color: #6d8bd0;
      line-height: 0px;
      font-size: 26px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      width: 40px;
      height: 40px;
      border: 1px solid #6d8bd0;
      border-radius: 50%;
    }
  }
}
</style>
