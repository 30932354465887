import loader from './loader';
import { h, ref, provide, onMounted } from 'vue';

export default {
  setup(_, { slots }) {
    const isInit = ref(false);
    const loading = ref(false);

    let ymaps = null;
    provide('getYmaps', () => ymaps);

    onMounted(async () => {
      loading.value = true;
      ymaps = await loader();
      loading.value = false;
      isInit.value = true;
    });

    return () => {
      const slot = () => {
        if (isInit.value) {
          return h(slots.default);
        }
      };
      return h(
        'div',
        {
          class: {
            loading: loading.value
          },
          style: {
            height: '100%',
            width: '100%'
          }
        },
        slot()
      );
    };
  }
};
