export default class {
  constructor({ provider }) {
    this.provider = provider;
  }

  get(url, params = {}) {
    return this.provider.get(url, params);
  }

  post(url, body = {}) {
    return this.provider.post(url, body);
  }
}
