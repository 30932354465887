<template>
  <flex class="py-20 fonts_t">
    {{ title }}
  </flex>
</template>

<script>
export default {
  props: { title: String }
};
</script>

<style lang="scss">
.fonts_t {
  color: #333333;
  font: normal normal bold 13px/18px Roboto;
}
</style>
