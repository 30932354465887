<template>
  <flex
    justify-content="space-between"
    @click="$emit('changesection', i)"
    v-bind="$attrs"
    :class="[
      'link',
      column ? linkColumnClass : linkClass,
      active == title ? 'active' : ''
    ]"
  >
    <div style="position: relative">
      {{ title }}
    </div>
    <div v-if="title == 'Протоколы'" class="sp font-grey">
      (<span>Только</span> просмотр)
    </div>
  </flex>
</template>

<script>
export default {
  props: {
    active: {},
    title: {},
    i: { type: Number },
    column: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const linkClass = 'link-row';
    const linkColumnClass = 'link-col';

    return {
      linkClass,
      linkColumnClass
    };
  }
};
</script>

<style lang="scss" scoped>
.sp {
  font-weight: unset !important;
  margin-left: 80px;
  float: right !important;
  text-transform: lowercase;
  span {
    text-transform: capitalize;
  }
}
.link {
  display: flex;
  font-weight: 900;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 13px;
  text-transform: uppercase;

  &.active {
    position: relative;
    color: $black;

    &:after {
      content: '';
      position: absolute;
      background: $primary;
    }
  }

  &.link-row {
    height: 100%;

    &:last-child {
      margin-right: 0 !important;
    }

    &.active:after {
      bottom: 0;
      height: 3px;
      width: 100%;
    }
  }

  &.link-col {
    flex: 0 0 50px;
    border-top: 1px solid #ddd;

    &:last-child {
      border-bottom: 1px solid #ddd;
    }

    &.active:after {
      left: 0;
      width: 3px;
      height: 100%;
    }
  }
}
</style>
