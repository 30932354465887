import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';
import useLands from '@/composition/useLands';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();

const state = reactive({
  object: null,
  loading: false,
  status: null
});

const lastKey = ref(null);

const load = async ({ guid }) => {
  if (state.object && state.object.guid === guid) return;
  try {
    state.loading = true;
    const url = (() => {
      if (currentLandId.value === 1) return apiRouts.value.GET_MUNICIPAL_OBJECT;
      if (currentLandId.value === 2) return apiRouts.value.GET_PRIVATE_OBJECT;
      if (currentLandId.value === 3) return apiRouts.value.GET_OBJECT_LEGAL;
    })();
    const { data: object } = await api.post(url, { guid });
    if (currentLandId.value != 3) {
      state.object = object.data;
    } else {
      state.object = object;
    }
  } finally {
    state.loading = false;
  }
};

const save = async params => {
  const currentKey = Object.entries({
    ...params
  })
    .flat()
    .sort()
    .join();
  try {
    state.loading = true;
    const url = (() => {
      if (currentLandId.value === 2) return apiRouts.value.SAVE_OBJECT_PRIVATE;
      if (currentLandId.value === 1) return apiRouts.value.SAVE_OBJECT;
    })();
    const {
      data: { data: object }
    } = await api.post(url, {
      ...params,
      system_entry_point_id: 2
    });
    state.object = object;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

const register = async params => {
  const currentKey = Object.entries({
    ...params
  })
    .flat()
    .sort()
    .join();
  try {
    state.loading = true;
    const url = (() => {
      if (currentLandId.value === 2) {
        return apiRouts.value.REGISTER_OBJECT_PRIVATE;
      }
      if (currentLandId.value === 1) {
        return apiRouts.value.REGISTER_OBJECT;
      }
    })();
    params.system_entry_point_id = 2;
    const {
      data: { data: object }
    } = await api.post(url, params);
    state.object = object;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

const removeObject = async params => {
  function isFull() {
    return params.path.indexOf('full') != -1;
  }

  function isAsot() {
    return apiRouts.value.REMOVE_OBJECT_FULL.indexOf('asot') != -1;
  }

  function isDo() {
    return apiRouts.value.REMOVE_OBJECT_FULL.indexOf('ro') != -1;
  }

  try {
    state.loading = true;
    const url = (() => {
      if (isFull()) {
        if ((isAsot() || isDo()) && currentLandId.value === 2) {
          return apiRouts.value.REMOVE_OBJECT_FULL_PRIVATE;
        }
        return apiRouts.value.REMOVE_OBJECT_FULL;
      } else {
        if ((isAsot() || isDo()) && currentLandId.value === 2) {
          return apiRouts.value.REMOVE_OBJECT_PRIVATE;
        }
        return apiRouts.value.REMOVE_OBJECT;
      }
    })();
    const {
      data: { data: data }
    } = await api.post(url, {
      guid: params.guid,
      delete_comment: params.delete_comment,
      delete_reason: params.delete_reason,
      system_entry_point_id: 2
    });
    state.object = null;
    state.status = data;
    if (isFull()) {
      state.object = null;
    }
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load,
    save,
    removeObject,
    register
  };
};
