<template>
  <div
    class="object-protocol"
    v-if="object.offence.length"
    :class="{ loading }"
  >
    <object-protocol-grid :schema="protocolSchema" v-slot="{ item, group }">
      <div class="_content " v-for="(section, i) in sections[group]" :key="i">
        <expansion-panel>
          <template v-slot:header="{ isOpen, toggleOpen }">
            <flex
              @click.stop="toggleOpen"
              class="header-protocol ml-10"
              :class="{ selected: isOpen }"
              align-items="flex-start"
            >
              <flex
                :class="{
                  opacity: !getKey(item, section.params) ? 1 : 0
                }"
                class="changes-inform mr-10"
              ></flex>
              <flex>
                <icon
                  name="down"
                  class="icon"
                  :class="{ 'icon-rotate': isOpen }"
                />
              </flex>

              <flex fill class="px-10 font-bold section-title_">{{
                section.title
              }}</flex>
            </flex>
          </template>
          <template v-slot:content>
            <section-tree
              class="ml-20"
              :currentLandId="currentLand.id"
              :protocol="item"
              :sections="section.params"
              :entity="entity"
            ></section-tree>
          </template>
        </expansion-panel>
      </div>
    </object-protocol-grid>
  </div>
  <flex v-else justify-content="center" class="font-color-gray">
    Нет протоколов
  </flex>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';
import useLands from '@/composition/useLands';
import useProtocol from '@/composition/useProtocol';
import useDirectories from '@/composition/useDirectories';
import useDirectoriesNTO from '@/composition/useDirectoriesNTO';

export default {
  props: {
    object: { type: Object },
    entity: String
  },
  setup(props) {
    const { currentLand } = useLands();
    const { load: LoadDirectories } = useDirectories();
    const { load: LoadProtocol } = useProtocol();
    const { load: LoadNTO, all: allNTO } = useDirectoriesNTO();

    const loading = ref(false);

    const sections = ref([]);

    const rawSchema = ref(null);

    const protocolSchema = computed(() => {
      if (!rawSchema.value) return [];
      return rawSchema.value(props.object.offence);
    });

    onBeforeMount(async () => {
      loading.value = true;

      if (props.entity != 'ro' || props.entity != 'asot') {
        await LoadProtocol(props.entity);
      }

      if (props.entity == 'ro' || props.entity == 'asot') {
        await LoadDirectories(props.entity);
      }

      if (props.entity == 'nto') {
        if (currentLand.value.id == 2) {
          await LoadDirectories(props.entity);
        }
        await LoadNTO().then(() => {
          allNTO();
        });
      }

      rawSchema.value = await import(
        `@/schema/${props.entity}/protocol/grid/${currentLand.value.alias}`
      ).then(module => module.default);
      if (currentLand.value.id == 1) {
        await import(`@/schema/${props.entity}/protocol/protocol`).then(
          data => {
            sections.value.push(data.default);
          }
        );
      } else {
        if (props.entity != 'rk' && props.entity != 'ro') {
          await import(
            `@/schema/${props.entity}/protocol/protocol-owner-object`
          ).then(data => {
            sections.value.push(data.default);
          });
        }

        await import(
          `@/schema/${props.entity}/protocol/protocol-owner-place`
        ).then(data => {
          sections.value.push(data.default);
        });
      }

      loading.value = false;
    });

    const getKey = (protocol, k) => {
      let checkElem = false;
      k.forEach(el => {
        let key = el.key;
        if (key.indexOf(' ') != -1) {
          key = key.split(' ')[0];
        }

        if (
          protocol.log.includes(key) &&
          key != 'protocol_type' &&
          key != 'parent_offence_id'
        ) {
          if (checkElem == false) {
            checkElem = true;
          }
        }
      });
      return checkElem;
    };

    return {
      loading,
      getKey,
      protocolSchema,
      sections,
      currentLand
    };
  }
};
</script>

<style lang="scss" scoped>
.object-protocol {
  width: 100wh;
  overflow-x: auto;
  font-size: 13px;
  line-height: 15px;
}
.header-protocol {
  padding: 10px 0;
  background: #f2f2f2;
  //background: #7097f11f;
}
.icon-rotate {
  transform: rotate(0deg) !important;
}
.icon {
  color: #6d8bd0;
  transform: rotate(-90deg);
}

.row_expansion {
  min-width: 300px;
  border-bottom: 1px solid #e8e8e8;
  .h-border {
    border-bottom: 1px solid #e8e8e8;
  }
}

.changes-inform {
  margin-top: 5px;
  margin-left: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $primary;
}

._content {
  background: #f7f3f3 0% 0% no-repeat padding-box;
}
.opacity {
  opacity: 0;
}
</style>
