import { moduleOptions, apiRouts, defaultValues } from '@/data/asot';
import navbarSchema from '@/schema/asot/navbar';
import gridSchema from '@/schema/asot/grid';
import filtersObjectsSchema from '@/schema/asot/filters-objects';

export default [
  {
    path: '/asot',
    redirect: '/asot/objects',
    component: () =>
      import(/* webpackChunkName: "asot" */ '@/views/module/index.vue'),
    meta: {
      apiRouts,
      defaultValues
    },
    props: () => {
      return {
        moduleOptions,
        navbarSchema
      };
    },
    children: [
      {
        path: 'objects',
        props: () => {
          return {
            gridSchema,
            filtersSchema: filtersObjectsSchema
          };
        },
        component: () =>
          import(
            /* webpackChunkName: "asot/objects" */
            '@/views/module/objects.vue'
          )
      },
      {
        path: 'map',
        component: () =>
          import(
            /* webpackChunkName: "asot/yandex-map" */ '@/views/module/map.vue'
          )
      }
    ]
  }
];
