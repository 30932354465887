<template>
  <div>
    <modal full>
      <iopr-filters :schema="filtersSchema" @close="close" @apply="apply" />
    </modal>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import filters from '@/schema/map/objects-filters';

export default {
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const close = () => {
      emit('show');
    };
    function apply(data) {
      if (data.areaId == -1) {
        data.districtId = null;
      }
      router
        .push({
          path: route.path,
          query: {
            areaId: data.areaId,
            districtId: data.districtId,
            districtsAll: !data.districtId ? 1 : 0
          }
        })
        .then(() => {
          emit('filter', data);
        });
    }

    const filtersSchema = filters;

    return {
      filtersSchema,
      close,
      apply
    };
  }
};
</script>
