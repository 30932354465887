<template>
  <modal full class="filters">
    <flex column class="">
      <iopr-header class="pt-20 px-20 eds-header">
        <template #title>
          <div>
            <span class="bold font-13">
              {{ 'Подписать электронно-цифровой подписью' }}
            </span>
          </div>
        </template>
        <template #action>
          <icon
            name="close"
            :size="20"
            class="text-gray"
            @click="$emit('close')"
          />
        </template>
      </iopr-header>
      <flex v-if="!loading" fill :class="{ loading }" column class="block">
        <div class="modal-slot">
          <div>{{ user.last_name }}</div>
          <div>{{ user.first_name }} {{ user.middle_name }}</div>
        </div>
        <div class="modal-slot">
          {{ user.organization }}
        </div>
        <div class="modal-slot">
          {{ user.position }}
        </div>
        <div class="modal-slot">
          {{ dateNow }}
        </div>
      </flex>
      <flex class="py-10 controls">
        <flex fill class="px-10 ">
          <ui-button class="btn_res font-uppercase" @click="$emit('close')"
            >Отменить</ui-button
          >
        </flex>
        <flex fill class="px-10 font-uppercase">
          <ui-button :loading="load" @click="submit()" primary
            >ПОДПИСАТЬ</ui-button
          >
        </flex>
      </flex>
    </flex>
  </modal>
</template>

<script>
import { computed, ref } from 'vue';
import useUser from '@/composition/useUser.js';

export default {
  setup(props, { emit }) {
    const save = () => {};
    const dateNow = computed({
      get: () => {
        let date = new Date();
        date = date.toLocaleString();
        return date;
      }
    });
    const load = ref(false);

    const { user, loading } = useUser();

    const loadEDS = async () => {
      await new Promise(resolve => setTimeout(resolve, 3000));
    };

    const submit = async () => {
      load.value = true;
      await loadEDS();
      load.value = false;
      emit('submit');
    };

    return { user, loading, load, save, submit, dateNow };
  }
};
</script>

<style lang="scss" scoped>
.btn_res {
}
.eds-header {
  z-index: 20000 !important;
}
.uiButton {
  max-height: 50px !important;
}
.block {
  overflow-wrap: break-word;
}
.controls {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
.modal-slot {
  padding: 20px 30px;
  border-bottom: 1px solid #e8e8e8;
}
</style>
