export default {
  searchEl(
    requiredFieldsData,
    key,
    el,
    fields,
    data,
    prop,
    required = null,
    remove_ = null
  ) {
    if (
      (key == el && requiredFieldsData.includes(prop.field)) ||
      (required && key == el)
    ) {
      let element = document.getElementById(key);
      let child,
        child2 = [];

      let componentName = fields.filter(x => x.property == el).pop();

      if (componentName != undefined && element != null) {
        switch (componentName.component) {
          case 'editFiles':
            child = element.querySelectorAll('.modal-slot__photos-add');
            child2 = element.querySelectorAll('.disc_slot');
            break;
          case 'uiSelect':
            child = element.getElementsByTagName('select');
            break;

          case 'uiInput':
            child = element.getElementsByTagName('input');
            break;

          case 'auto-address':
            child = element.getElementsByTagName('input');
            break;

          case 'editPhotos':
            child = element.querySelectorAll('.modal-slot__photos-add');
            child2 = element.querySelectorAll('.disc_slot');
            break;

          case 'uiDatePicker':
            child = element.getElementsByTagName('input');
            break;

          case 'uiRadio':
            child = element.querySelectorAll('.radio-option');
            break;

          case 'uiTextArea':
            child = element.getElementsByTagName('textarea');
            break;
        }
        if (child) {
          child.forEach(l => {
            /*
            if ((prop.value === null || prop.value === '') && !remove_) {
              if (prop.value !== false) {
                l.style.backgroundColor = 'rgba(245, 120, 95, 0.2)';
              } else {
                l.style.backgroundColor = '';
              }
            } else {
              l.style.backgroundColor = '';
            }*/

            let fillArray = true;

            if (Array.isArray(data.value[el])) {
              if (!data.value[el].length) {
                fillArray = false;
              }
            }

            if (
              (data.value[key] === null ||
                data.value[key] === '' ||
                !fillArray) &&
              !remove_
            ) {
              if (data.value[key] !== false) {
                l.style.backgroundColor = 'rgba(245, 120, 95, 0.2)';
              } else {
                l.style.backgroundColor = '';
              }
            } else {
              l.style.backgroundColor = '';
            }

            if (prop.value == 'white') {
              l.style.backgroundColor = '';
            }
          });
        }
        if (child2) {
          child2.forEach(l => {
            if ((prop.value === null || prop.value === '') && !remove_) {
              l.style.color = 'rgba(242, 57, 20, 0.72)';
            } else {
              l.style.color = '';
            }
          });
        }
      }
    }
  }
};
