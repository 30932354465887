<template>
  <div>
    <template v-if="files.length">
      <div class="sidebar-card__doc py-5" v-for="(item, i) in files" :key="i">
        <a :href="item.url" target="_blank">{{ item.title }}</a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CardFiles',
  props: {
    files: {
      type: Array,
      required: true
    },
    changes: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
};
</script>

<style lang="scss"></style>
