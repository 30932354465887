<template>
  <div class="display-contents">
    <tr @click="toggle" :class="{ 'cursor-pointer': isExpand }">
      <template v-for="(column, i) in columnList" :key="i">
        <td
          :colspan="column.colspan || 1"
          :style="{ paddingLeft: i === 0 ? `${20 + deepLevel * 15}px` : 0 }"
          :align="column.align || 'left'"
          valign="middle"
        >
          <div v-if="i === 0" :class="{ 'expand-icon': true, hide: !isExpand }">
            <i class="down-small" :class="{ 'expand-open': open }"></i>
          </div>
          <slot :name="`col-${column.name}`"></slot>
        </td>
      </template>
    </tr>
    <div v-if="$slots.childrens && open" class="display-contents">
      <slot name="childrens"></slot>
    </div>
    <tr v-if="$slots.content && open">
      <td :colspan="columns.length">
        <div
          class="content"
          :style="{ paddingLeft: `${(deepLevel + 1) * 15}px` }"
        >
          <slot name="content"></slot>
        </div>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  inject: {
    columns: {
      from: 'columns',
      default: []
    }
  },
  props: {
    mergeColumns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false,
      deepLevel: this.$parent.deepLevel + 1 || 0
    };
  },
  computed: {
    columnList() {
      return this.columns.reduce((res, column) => {
        const merge = this.mergeColumns.find(item => {
          return item.columns.includes(column.name);
        });
        if (!merge) {
          res.push(column);
        } else if (!res.find(item => item.name === merge.name)) {
          res.push({
            ...column,
            name: merge.name,
            colspan: merge.columns.length
          });
        }
        return res;
      }, []);
    },
    isExpand() {
      const { content, childrens } = this.$slots;
      return content || childrens;
    }
  },
  methods: {
    toggle() {
      const { content, childrens } = this.$slots;
      if (!content && !childrens) return;
      this.open = !this.open;
    }
  }
};
</script>

<style scoped>
.display-contents {
  display: contents;
}
.cursor-pointer {
  cursor: pointer;
}
.expand-icon {
  display: inline-block;
  width: 15px;
}
.expand-icon.hide {
  /*display: none;*/
  visibility: hidden;
}
.expand-icon i::before {
  transform: rotate(-90deg);
}
.expand-icon .expand-open::before {
  transform: rotate(0deg);
}
/* .content {
  padding-left: 15px;
} */
</style>
