export const moduleOptions = {
  icon: 'ro',
  alias: 'ro',
  name: `Демонтаж\nдорожных объектов`
};

export const apiRouts = {
  GET_DISTRICTS: '/ro/directory/districts',
  GET_YEARS: '/ro/municipal/years',
  GET_MUNICIPAL_OBJECTS: '/ro/municipal/objects',
  GET_MUNICIPAL_OBJECT: '/ro/municipal/object',
  GET_MUNICIPAL_STATUSES: '/ro/municipal/filters',

  GET_MUNICIPAL_DIRECTORIES: '/ro/municipal/dictionaries',

  REGISTER_OBJECT: '/ro/municipal/register',

  GET_OBJECT_HISTORY: '/ro/municipal/history',
  GET_POLYGONS: '/dict/ro/polygon',
  GET_OBJECT_TREE: '/ro/map-object-tree',
  GET_MAP_OBJECTS: '/ro/objects-map',
  GET_MAP_AREAS: '/ro/map-area-list',
  GET_VIOLATION_TYPES: '/ro/municipal/dictionaries',
  GET_OBJECT_TYPES: '/ro/municipal/dictionaries',
  SAVE_OBJECT: '/ro/municipal/save',

  GET_PROTOCOL: '/ro/municipal/dictionaries',

  REMOVE_OBJECT: '/ro/municipal/delete',
  REMOVE_OBJECT_FULL: '/ro/municipal/delete-full',

  SEARCH_MAP: '/ro/map/search',
  //GET_LAND_USAGE_TYPES: '/ro/municipal/dictionaries',
  GET_KEEP_PLACES: '/ro/directory/keeps'
};

export const defaultValues = {
  LAND_ID: 1,
  AREA_ID: 41,
  DISTRICT_ID: null,
  MUNICIPAL_STATUS_ID: 1,
  PRIVATE_STATUS_ID: 218
};
