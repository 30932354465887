export default [
  {
    to: '/rk/objects',
    title: 'Объекты'
  },
  {
    to: '/rk/map',
    title: 'Карта'
  }
];
