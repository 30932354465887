<template>
  <flex column class="full-height">
    <modal-slots v-if="menu">
      <template #header>
        <iopr-header class="shadow py-10 px-20">
          <template #icon>
            <icon :name="entity" :size="20" class="text-gray" />
          </template>
          <template v-slot:title>
            <div class="px-10">
              <span class="bold font-13">
                {{ title }}
              </span>
            </div>
          </template>
          <template #action>
            <icon
              name="close"
              :size="20"
              class="text-gray"
              @click="$emit('closemenu')"
            />
          </template>
        </iopr-header>
      </template>
      <flex column fill class="content menu_line">
        <div class="text-gray column">
          <menu-item
            @changesection="$emit('changesection', $event)"
            :i="i"
            v-for="(item, i) in items"
            :key="i"
            :title="item.title"
            :active="active"
            class="px-20 py-10"
          >
          </menu-item>
        </div>
      </flex>
    </modal-slots>
  </flex>
</template>

<script>
export default {
  props: {
    menu: {
      type: Boolean,
      default: false
    },
    title: { type: String },
    entity: { type: String },
    active: {},
    items: {}
  },

  setup() {
    //
  }
};
</script>

<style lang="scss" scoped>
// .menu {
//   .object_ {
//     color: #333333;
//     font-weight: bold !important;
//   }
// }
// .item {
//   border-top: 1px solid grey;
//   .text {
//     padding: 10px 0px 10px 0px;
//     margin-left: 20px;
//   }
// }
// .menu_line {
//   background: #fff;
//   height: 100%;

//   &.column {
//     height: auto;
//   }
// }
</style>
