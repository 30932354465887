import useDistricts from '@/composition/useDistricts';
import useDefaultValues from '@/composition/useDefaultValues';

const { defaultValues } = useDefaultValues();
const { areas, districts } = useDistricts();

//здесь undefined
const getAreaList = () => {
  return areas.value.map(district => {
    return {
      value: district.area_id,
      text: district.area_name
    };
  });
};

const getDistrictList = areaId => {
  if (!areaId) return [];
  return [
    {
      value: null,
      text: 'Все'
    },
    ...districts.value(areaId).map(district => {
      return {
        value: district.area_id,
        text: district.area_name
      };
    })
  ];
};

export default data => {
  return [
    {
      label: 'го/мр',
      fieldClass: 'px-20 mb-15 combobox-wrapper',
      component: 'uiSelect',
      componentAttrs: {
        items: getAreaList()
      },
      property: 'areaId',
      isNumber: true,
      default: defaultValues.value.AREA_ID
    },
    {
      label: 'район',
      fieldClass: 'px-20 mb-15 combobox-wrapper',
      component: 'uiSelect',
      componentAttrs: {
        items: getDistrictList(data?.value.areaId),
        disabled:
          !districts.value(data?.value.areaId).length ||
          data?.value.areaId == -1
      },
      property: 'districtId',
      isNumber: true,
      default: null
    }
  ];
};
