<template>
  <flex class="iopr-module-icon" align-items="center" justify-content="center">
    <icon class="icon" :name="icon" :size="16"></icon>
  </flex>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.iopr-module-icon {
  background: $primary;
  border-radius: 5px;
  width: 32px;
  height: 32px;

  .icon {
    color: white;
  }
}
</style>
