<template>
  <div>
    <eds
      @submit="SEND($event), clean()"
      @close="$router.go(-2)"
      v-if="isOpenEDS"
    ></eds>
    <confirmation
      @submit="(isOpenConfirm = 0), (isOpenEDS = 1)"
      @close="$router.go(-1)"
      :btnLabel="'Сохранить объект'"
      :text="'Вы уверены, что хотите сохранить объект?'"
      v-if="isOpenConfirm && !isOpenEDS"
    ></confirmation>

    <modal full v-if="object && !isOpenEDS && !isOpenConfirm">
      <flex column class="bg-white sticky-top shadow" style="z-index: 1">
        <iopr-header class="px-20 py-10">
          <template #icon>
            <icon :name="entity" :size="20" class="text-gray" />
          </template>
          <template #title>
            <div class="px-10">
              <span class="bold font-13"> {{ objectTitle }} </span>
            </div>
          </template>
          <template #action>
            <icon name="close" :size="20" class="text-gray" @click="close()" />
          </template>
          <template v-slot:about="{ isOpen, toggle }">
            <object-about
              :open="isOpen"
              @isOpen="toggle"
              :object="object"
            ></object-about>
          </template>
        </iopr-header>
      </flex>

      <div>
        <div class="content_box" :class="{ loading }">
          <div
            class="_content_form py-10 iopr-status-list"
            v-for="(section, i) in sections"
            :key="i"
          >
            <expansion-panel
              v-if="'editable' in section"
              class="border-bottom"
              :open="true"
            >
              <template #header>
                <flex
                  @click.stop="toggleSection(i)"
                  class="pr-20 header px-20"
                  :class="{ selected: section.isOpen }"
                  align-items="flex-start"
                >
                  <flex>
                    <icon
                      name="down-small"
                      :size="15"
                      class="icon-toggle px-10 section-title_ font-bold"
                      :class="{ open: section.isOpen }"
                    />
                  </flex>
                  <flex fill class="px-5 font-bold section-title_">{{
                    section.title
                  }}</flex>
                </flex>
              </template>
              <template v-slot:content>
                <transition>
                  <div v-show="section.isOpen" class="card_content">
                    <div class="card-title_ px-35">
                      <form-generator
                        :uniqId="i + 1"
                        :validation="validationFields"
                        :requiredFields="requiredFields"
                        v-model="data"
                        :fields="section.params"
                      >
                        <template v-slot:label="{ text }">
                          <flex fill column>
                            <flex fill>
                              <span
                                class="font-color-gray font-uppercase font-bold font-size-13 mb-10"
                                >{{ text }}</span
                              >
                            </flex>
                          </flex>
                        </template>
                        <template v-slot:required="{ property }">
                          <flex
                            :class="{
                              opacity1:
                                requiredFields.includes(property) &&
                                (data[property] == null ||
                                  data[property] == '') &&
                                data[property] !== false &&
                                submitBtn
                            }"
                            class="opacity mt-10"
                            style="color: rgba(242, 57, 20, 0.72)"
                            >обязательное поле</flex
                          >
                        </template>
                      </form-generator>
                    </div>
                  </div>
                </transition>
              </template>
            </expansion-panel>
          </div>
        </div>
        <div class="block__"></div>
        <div class="btn_obj_form py-10">
          <flex class="px-20">
            <flex fill class="px-10">
              <ui-button
                :disabled="countChangeValues == 0"
                @click="save()"
                class="font-uppercase"
                primary
                >сохранить</ui-button
              >
            </flex>
            <flex fill class="px-10">
              <ui-button @click="$router.go(-1), clean()" class="font-uppercase"
                >отменить</ui-button
              >
            </flex>
          </flex>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue';

import useObject from '@/composition/useObject';
import objectHelpers from '@/composition/object/objectHelpers';
import useLands from '@/composition/useLands';
import { useRoute, useRouter } from 'vue-router';
import useDefaultValues from '@/composition/useDefaultValues';

import useDirectories from '@/composition/useDirectories';
import useDistricts from '@/composition/useDistricts';
import useViolationTypes from '@/composition/useViolationTypes';
import useObjectTypes from '@/composition/useObjectTypes';
import useLandUsageTypes from '@/composition/useLandUsageTypes';
import useKeepPlaces from '@/composition/useKeepPlaces';
import formHelpers from '@/composition/object/formHelpers';
import objectFormFields from '@/composition/object/objectFormFields';

export default {
  props: {
    entity: {},
    guid: {},
    dataParams: {}
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const requiredFields = ref([]);
    const validationFields = ref(objectFormFields);

    const { defaultValues } = useDefaultValues();
    const { load: loadViolationTypes } = useViolationTypes();
    const { load: loadObjectTypes } = useObjectTypes();
    const { load: loadDirectories } = useDirectories();
    const { load: loadLandUsageTypes } = useLandUsageTypes();
    const { load: loadKeepPlaces } = useKeepPlaces();
    const { areas, load: loadDistricts } = useDistricts();

    const areaId = computed(
      () => parseInt(route.query.areaId) || defaultValues.value.AREA_ID
    );

    const submitBtn = ref(null);

    const about = ref({
      isOpen: false,
      height: 0
    });

    const aboutToggle = event => {
      about.value = event;
    };

    const files = ref([]);

    const isOpenEDS = computed({
      get() {
        return Boolean(route.query.eds);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            eds: value
          }
        });
      }
    });

    const loading = ref(false);
    const {
      object,
      load: loadObject,
      save: saveObject,
      loading: loadingObject
    } = useObject();
    const { currentLandId } = useLands();

    const newObj = ref(null);
    if (props.guid) {
      loadObject({
        guid: props.guid
      }).then(() => {
        files.value = object.value.files;
        data.value = object.value;

        //заменяем строки файлами
        for (let k in data.value) {
          if (k.indexOf('file_') != -1) {
            let folder = k.replace('file_', '');
            data.value[k] = data.value.files.filter(
              el => el.folder_code == folder
            );
          }
        }
        init();
      });
    } else {
      newObj.value = true;
      if (currentLandId.value == 1) {
        import(`@/schema/${props.entity}/municipal`).then(() => {
          setAttributes();
        });
      } else {
        import(`@/schema/${props.entity}/private`).then(() => {
          setAttributes();
        });
      }
    }

    const setAttributes = async () => {
      let Idarea = props.dataParams.area_id
        ? props.dataParams.area_id
        : defaultValues.value.AREA_ID;

      let geo_polygon = areas.value.filter(el => el.area_id == Idarea);
      geo_polygon = geo_polygon.pop();

      object.value = {
        offence: [],
        files: [],
        log: [],
        geo_polygon: props.dataParams.coords || [
          geo_polygon?.area_lon,
          geo_polygon?.area_lat
        ]
      };

      await init();
      sections.value.forEach(section => {
        section.params.forEach(field => {
          if ('property' in field) {
            data.value[field.property] = null;
          }
        });
      });

      const arrayFields = [
        'offence',
        'files',
        'log',
        'geo_polygon',
        'file_object_issued_to_owner_files'
      ];

      arrayFields.forEach(el => {
        data.value[el] = [];
      });

      data.value.system_entry_point_name = 'mobile';

      data.value.property_type_name =
        currentLandId.value == 1 ? 'Муниципальная земля' : 'Частная земля';
      if (props.entity != 'asot') {
        data.value.geo_polygon = props.dataParams.coords;
        data.value.geo_type = 'point';
      }

      data.value.area_id = props.dataParams.area_id || 41;

      if ('district' in props.dataParams) {
        data.value.address_district_id = props.dataParams.district.id;
      }

      object.value = data.value;
      dataFills.value = data.value;
    };

    const scrollToSection = async (section = props.dataParams.section) => {
      if (section) {
        if (props.dataParams.section) {
          await openSection(section);
        }

        setTimeout(() => {
          let previos = document.getElementById(section);
          if (previos) {
            let intId = previos.dataset.parent.split('_');
            let parent = document.getElementById(
              intId[1] > 1
                ? `${intId[0]}_${intId[1] - 1}`
                : `${intId[0]}_${intId[1]}`
            );
            parent.scrollIntoView();
          }
        }, 400);
      }
    };

    const isOpenConfirm = computed({
      get() {
        return Boolean(route.query.confirm);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            confirm: value
          }
        });
      }
    });

    const checkFillFilds = (key = false) => {
      let count = 0;

      let searchDistrict = requiredFields.value.indexOf('address_district_id');

      if (data.value.area_id != 41) {
        requiredFields.value.splice(searchDistrict, 1);
      } else if (searchDistrict == -1) {
        requiredFields.value.push('address_district_id');
      }

      requiredFields.value.forEach(el => {
        let fillArray = true;
        if (Array.isArray(data.value[el])) {
          if (!data.value[el].length) {
            fillArray = false;
          }

          if (el == 'geo_polygon' && data.value[el].length) {
            if (!data.value[el][0].coordinates.length) {
              fillArray = false;
              data.value[el] = [];
            }
          }
        }

        if (data.value[el] === null || data.value[el] === '' || !fillArray) {
          if (key) {
            sections.value.forEach(section => {
              section.params.forEach(field => {
                if ('property' in field) {
                  formHelpers.searchEl(
                    requiredFields.value,
                    el,
                    el,
                    section.params,
                    data,

                    {
                      field: field.property,
                      value: data.value[el],
                      required: 'required' in field ? field.required : false,
                      component: field.component
                    }
                  );
                }
              });
            });
          }

          ++count;
        }
      });
      return count == 0;
    };

    const objectTitle = computed(() => {
      if (object.value != null) {
        if (!object.value.obj_registration_number) {
          return !newObj.value ? 'УЧЕТНЫЙ НОМЕР НЕ ПРИСВОЕН' : 'НОВЫЙ ОБЪЕКТ';
        } else {
          return `УЧ №  ${object.value.obj_registration_number}  от
                 ${objectHelpers.changeValue(
                   object.value,
                   'obj_registration_date'
                 )}`;
        }
      }
      return '';
    });

    const thumbler = reactive({
      section: true,
      id: null
    });
    const toggleSection = i => {
      sections.value[i].isOpen = !sections.value[i].isOpen;
    };
    const sections = ref([]);
    let data = ref({});

    const dataFills = ref({});

    const openSection = el => {
      sections.value.forEach(section => {
        section.params.forEach(component => {
          if ('property' in component) {
            if (component.property == el) {
              section.isOpen = true;
            }
          }
        });
      });
    };

    const save = () => {
      submitBtn.value = true;
      if ('violation_type_id' in data.value) {
        if (!requiredFields.value.includes('violation_type_id')) {
          requiredFields.value.unshift('violation_type_id');
        }
      }
      const removeUnusedFields = () => {
        if (data.value.owner_object_status == 9) {
          const owner_object = ['owner_object_opf', 'owner_object_opf_name'];
          owner_object.forEach(el => {
            if (el in data.value) {
              data.value[el] = null;
            }
          });
        }

        if (data.value.owner_place_status == 9) {
          const owner_place = ['owner_place_opf', 'owner_place_opf_name'];
          owner_place.forEach(el => {
            if (el in data.value) {
              data.value[el] = null;
            }
          });
        }
      };

      removeUnusedFields();

      function checkValueField(key, field, alreadyScrolled) {
        if (key == 'geo_polygon' && Array.isArray(field) && !field.length) {
          return true;
        }

        if ((field === null || field === '') && !alreadyScrolled) {
          return true;
        }
        return false;
      }

      if (!checkFillFilds(true)) {
        let alreadyScrolled = false;
        let notFilled = [];

        requiredFields.value.forEach(el => {
          new Promise(resolve => {
            if (checkValueField(el, data.value[el], alreadyScrolled)) {
              openSection(el);
              notFilled.push(el);
              return;
            }
            resolve();
          }).then(() => {
            notFilled.some(el => {
              if (!alreadyScrolled) {
                scrollToSection(el);
              }
              alreadyScrolled = true;
            });
          });
        });

        return;
      }
      data.value.files = files.value;
      if (props.entity == 'asot') {
        data.value.violation_type_id = 261;
      }

      makeFielsToString();
      isOpenConfirm.value = 1;
      //isOpenEDS.value = 1;
    };

    const makeFielsToString = () => {
      object.value.files = [];
      for (var key in data.value) {
        if (key in data.value && key != 'files') {
          if (key.indexOf('file_') != -1) {
            if (data.value[key] != null && typeof data.value[key] != 'string') {
              let newField = [];
              data.value[key].forEach(ell => {
                newField.push(ell.guid);
                object.value.files.push(ell);
              });
              object.value[key] = JSON.stringify(newField);
            }
          } else {
            object.value[key] = data.value[key];
          }
        }
      }
    };

    const clean = () => {
      data.value = {};
      //object.value = {};
    };

    const SEND = async () => {
      await saveObject(object.value);
      clean();
      emit('saved', object.value);
    };

    let countChangeValues = ref(0);

    watch(
      () => data.value,
      () => {
        ++countChangeValues.value;
      }
    );

    const init = async () => {
      loading.value = true;
      switch (props.entity) {
        case 'rk':
          await loadObjectTypes(props.entity);
          await loadViolationTypes(props.entity);

          break;
        case 'nto':
          await loadLandUsageTypes(props.entity);
          await loadObjectTypes(props.entity);
          await loadViolationTypes(props.entity);

          if (currentLandId.value !== 1) {
            await loadDirectories(props.entity);
          }
          break;

        case 'ro':
          await loadDirectories(props.entity);
          break;
        case 'asot':
          await loadDirectories(props.entity);
          break;
      }

      await loadKeepPlaces();
      await loadDistricts({ areaId: areaId.value });

      if (currentLandId.value == 1) {
        await import(`@/schema/${props.entity}/object_form/municipal`).then(
          d => {
            sections.value = d.default.sections(object.value, areaId.value);
            requiredFields.value = d.default.requiredFields();
          }
        );
      } else {
        if (props.entity != 'ro') {
          await import(`@/schema/${props.entity}/object_form/private`).then(
            d => {
              sections.value = d.default.sections(object.value, areaId.value);

              requiredFields.value = d.default.requiredFields();
            }
          );
        }
      }
      loading.value = false;
      setTimeout(() => {
        countChangeValues.value = 0;
      }, 100);
      scrollToSection();
    };

    const close = () => {
      makeFielsToString();
      emit('formmap', object.value);
      clean();
    };

    return {
      validationFields,
      aboutToggle,
      about,
      countChangeValues,
      isOpenConfirm,
      newObj,
      submitBtn,
      close,
      loading,
      loadingObject,
      clean,
      checkFillFilds,
      requiredFields,
      isOpenEDS,
      save,
      data,
      SEND,
      toggleSection,
      objectTitle,
      object,
      thumbler,
      sections
    };
  }
};
</script>

<style lang="scss" scoped>
.card-header-form {
  z-index: 1000;
  position: fixed;
  width: 100%;
  background-color: white;
}
.ml-100 {
  margin-left: 200px !important;
}
.shadow {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.opacity {
  opacity: 0;
}

.opacity1 {
  opacity: 1 !important;
}

.block__ {
  height: 100px;
}

.btn_obj_form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
}

.section-title_ {
  color: #6d8bd0;
}
.content_box {
  transition: 0.5s;
  z-index: 1 !important;
  background-color: white;
}
._content_form {
  input,
  select,
  textarea {
    border: 1px solid #e8e8e8 !important;
  }
}
</style>
