<template>
  <table class="iopr-protocols-grid">
    <thead>
      <tr>
        <th
          v-for="(column, i) in columns"
          :key="i"
          :align="column.align || 'left'"
          :width="column.width"
          v-html="column.label"
        ></th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  provide() {
    return {
      columns: this.columns
    };
  }
};
</script>

<style scoped>
.iopr-protocols-grid {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  line-height: 15px;
}
</style>
