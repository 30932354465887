<template>
  <flex :style="{ height }" class="map-editor">
    <yandex-maps class="map">
      <yandex-map :center="center" :zoom="zoom">
        <yandex-geolocation
          v-if="!disabled"
          class="geolocation-button font-color-gray shadow"
          provider="browser"
          :position="{ top: '10px', left: '10px' }"
          :size="40"
          :options="{ mapStateAutoApply: true }"
        >
          <icon name="geolocation" :size="30" />
        </yandex-geolocation>
        <zoom-map
          v-if="!disabled"
          :position="{ bottom: '10px', left: '10px' }"
          :size="40"
        />
        <yandex-polygon
          v-for="(coord, i) in polygons"
          :key="i"
          :coord="coord"
          :options="polygonStyle"
          @click="onClick"
        />
        <component
          v-if="editComponent"
          :is="editComponent"
          v-model:coord="coord"
          :edit="!disabled && polygonPoint && polygonEditMode"
          :options="{
            editorMenuManager() {
              return [];
            },
            ...polygonStyle,
            fillOpacity: 0.5
          }"
          @vertexAdd="changePolygonCoords"
          @vertexDragEnd="changePolygonCoords"
        />
      </yandex-map>
    </yandex-maps>
    <flex column class="controls" v-if="!disabled">
      <!-- <flex class="shadow-3 bg-white border-radius-5 mb-10">
        <radio-group
          column
          button
          :disabled="disabled"
          v-model="type"
          @change="clear"
        >
          <radio-option v-for="(type, i) in types" :key="i" :value="type">
            <icon :name="type" :size="20" />
          </radio-option>
        </radio-group>
      </flex> -->
      <uiButton
        :class="
          `shadow-3 mb-10 border-radius-5 bg-${
            polygonEditMode ? 'primary' : 'white'
          } font-color-${polygonEditMode ? 'white' : 'gray'}`
        "
        @click="polygonEditMode = !polygonEditMode"
      >
        <icon :name="type" :size="20" />
      </uiButton>
      <uiButton class="shadow-3 bg-white" :disabled="disabled" @click="clear">
        <icon class="font-color-gray" name="close" :size="20" />
      </uiButton>
    </flex>
  </flex>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '250px'
    },
    center: {
      type: Array,
      required: true
    },
    zoom: {
      type: Number
    },
    polygon: {
      type: Array
    },
    types: {
      type: Array,
      default: () => ['placemark'],
      validator(value) {
        const types = ['placemark', 'polygon'];
        return value.every(type => types.includes(type));
      }
    },
    defaultType: {
      type: String,
      default: 'placemark',
      validator(value) {
        return ['placemark', 'polygon'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const type = ref(props.defaultType);
    const polygonStyle = {
      fillColor: '#6DA8CD',
      fillOpacity: 0.1,
      strokeColor: '#707070',
      strokeOpacity: 1,
      strokeStyle: 'solid',
      strokeWidth: 1.5
    };

    const polygons = computed(() => {
      if (!props.polygon) return [];
      return props.polygon.length > 1 ? props.polygon : [props.polygon];
    });

    const coord = computed({
      get() {
        switch (type.value) {
          case 'placemark':
            return props.modelValue || [];
          case 'polygon':
            if (Array.isArray(props.modelValue)) {
              return props.modelValue[0]?.coordinates || [];
            }
        }
        return [];
      },
      set(value) {
        let coord;
        switch (type.value) {
          case 'placemark':
            coord = value;
            break;
          case 'polygon':
            coord = [{ type: 'Polygon', coordinates: value }];
        }
        emit('update:modelValue', coord);
      }
    });

    const editComponent = computed(() => {
      if (!type.value) return null;
      return `yandex-${type.value}`;
    });

    const polygonEditMode = ref(false);
    const polygonPoint = ref(false);

    const onClick = event => {
      if (props.disabled) return;
      const clickCoords = event.get('coords');
      switch (type.value) {
        case 'placemark':
          coord.value = clickCoords;
          break;
        case 'polygon':
          if (!polygonEditMode.value) break;
          coord.value = [[clickCoords, clickCoords]];
          polygonPoint.value = true;
          break;
      }
    };

    const changePolygonCoords = event => {
      coord.value = event.get('target').geometry.getCoordinates();
    };

    const clear = () => {
      console.log(coord.value);
      polygonEditMode.value = false;
      polygonPoint.value = false;
      coord.value = [];
    };

    return {
      type,
      polygonStyle,
      polygons,
      coord,
      editComponent,
      polygonEditMode,
      polygonPoint,
      onClick,
      changePolygonCoords,
      clear
    };
  }
};
</script>

<style lang="scss" scoped>
.map-editor {
  position: relative;
  overflow: hidden;

  .map {
    position: absolute;
  }

  .controls {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
