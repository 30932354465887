<template>
  <div class="block_about ">
    <flex column v-if="object">
      <div id="about_content1" v-show="thumbler.content" class="box_content">
        <div class="status_ py-10">
          <flex class="box-unmount">
            <flex fill column>
              <span class="font-uppercase py-5">
                {{ object.status_name || object.d_object_status_name }}
              </span>
            </flex>
            <flex class="pl-10" v-if="!'guid' in object">
              <icon
                name="info"
                :size="20"
                class="text-gray"
                @click="thumbler.status_desc = !thumbler.status_desc"
              />
            </flex>
          </flex>
          <modal
            style="border-radius: 5px !important"
            v-if="thumbler.status_desc"
            :height="'85%'"
            class="mx-10 modal-expansion"
          >
            <icon
              name="close"
              :size="20"
              class="text-gray icon_close"
              @click="thumbler.status_desc = !thumbler.status_desc"
            />
            <div class="_block"></div>
            <div
              class="expansion"
              v-html="object.status_desc || object.d_object_status_desc"
            ></div>
          </modal>
        </div>
        <span class="property_type_name font-uppercase">
          {{ object.property_type_name }}
        </span>
        <div v-if="object.move_data" class="move font-uppercase">
          <div v-if="object.property_type_id == 1">
            Переведен из частной собственности
          </div>
          <div v-else>Переведен из муниципальной собственности</div>

          {{ getValue('move_data') }}
        </div>
        <div
          v-if="object['system_entry_point_name'] == 'mobile'"
          class="createdIn py-10"
        >
          Создан в мобильной версии
        </div>
      </div>

      <div
        style="background-color:white"
        class="toggle-content font-size-13 mt-10"
        @click="toggle()"
      >
        <span v-if="thumbler.content">Скрыть информацию</span>
        <span v-else>Показать больше</span>
      </div>
    </flex>
  </div>
</template>

<script>
import { ref, onUnmounted } from 'vue';
import objectHelpers from '@/composition/object/objectHelpers';

export default {
  props: {
    open: {
      default: false
    },
    shadow: {
      default: false
    },
    newObj: {},
    object: {}
  },
  setup(props, { emit }) {
    const thumbler = ref({
      status_desc: false,
      content: false
    });

    const init = () => {
      if (props.open) {
        thumbler.value.content = props.open;
      }
    };
    init();

    onUnmounted(async () => {
      emit('update:open', thumbler.value.content);
    });

    const toggle = () => {
      new Promise(resolve => {
        thumbler.value.content = !thumbler.value.content;
        resolve();
      }).then(() => {
        setTimeout(() => {
          emit('isOpen', {
            isOpen: thumbler.value.content,
            height:
              document.getElementById('about_content1').offsetHeight || 120
          });
        }, 100);
      });
    };

    const checkStatus = () => {
      function a(val) {
        return props.object.log.includes(val);
      }

      if ('dismantling_date' in props.object) {
        return a('dismantling_date');
      }

      if ('remove_voluntary_date_from' in props.object) {
        return a('remove_voluntary_date_from'); //remove_voluntary_date_to
      }
    };

    const getValue = key => {
      return objectHelpers.changeValue(props.object, key);
    };

    return {
      toggle,
      checkStatus,
      thumbler,
      getValue
    };
  }
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
}
/*.block_about {
  height: 140px;
  position: relative;
  display: block;
  margin-top: 10px;
}*/
.block_about {
  top: 50px;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.box_content {
  .expansion {
    border: 1px solid #808080;
    border-radius: 5px;
    padding: 4px 8px 4px 8px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .move {
    font: normal normal normal 13px/15px Roboto;
    color: #6d8bd0;
    border: 1px solid #6d8bd0;
    border-radius: 5px;
    padding: 5px;
    max-width: 246px;
    margin-top: 10px;
  }
  .createdIn {
    font: normal normal normal 13px/15px Roboto;
    margin-top: 10px;
    border: 1px solid #808080;
    border-radius: 5px;
    color: #808080;
    padding: 5px;
    max-width: 188px;
  }

  .status_ {
    span {
      color: #808080;
      font: normal normal normal 13px/15px Roboto;
    }
    .icon-info {
      display: inline-block;
    }
    margin-bottom: 5px;
  }
  .property_type_name {
    font: normal normal bold 13px/15px Roboto;
    color: #333333;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 6px;
  }
}

.toggle-content {
  color: #6d8bd0 !important;
}
.modal-expansion {
  border-radius: 5px !important;
}
.box_content .expansion {
  border: unset;
}
.icon_close {
  position: absolute;
  right: 10px;
  top: 25px;
}
._block {
  height: 20px;
}
</style>
