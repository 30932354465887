let mainData = {
  placemarkObj: null,
  mainCollection: {}
};
const ymap = {
  setMainData(val) {
    mainData = val;
  }
};

export { ymap, mainData };
