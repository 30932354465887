import { reactive, toRefs, computed } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';
import useLands from '@/composition/useLands';
import useDefaultValues from '@/composition/useDefaultValues';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();
const { defaultValues } = useDefaultValues();

const state = reactive({
  statusList: [],
  cache: {},
  loading: false
});

const defaultStatusId = computed(() => {
  if (currentLandId.value === 1) return defaultValues.value.MUNICIPAL_STATUS_ID;
  if (currentLandId.value === 2) return defaultValues.value.PRIVATE_STATUS_ID;
});

const load = async params => {
  const url = (() => {
    if (currentLandId.value === 1) return apiRouts.value.GET_MUNICIPAL_STATUSES;
    if (currentLandId.value === 2) return apiRouts.value.GET_PRIVATE_STATUSES;
  })();
  const key = Object.entries({
    ...params,
    landId: currentLandId.value,
    url
  })
    .flat()
    .sort()
    .join();
  if (state.cache[key]) {
    state.statusList = state.cache[key];
    return;
  }
  try {
    state.loading = true;
    const {
      data: { objects: list }
    } = await api.post(url, {
      area_id: params.areaId,
      district_id: params.districtId,
      year: params.year,
      search: ''
    });
    state.statusList = list;
    state.cache[key] = list;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    defaultStatusId,
    load
  };
};
