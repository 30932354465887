<template>
  <table class="uiTable" cellspacing="0">
    <thead class="head">
      <tr class="row">
        <th v-for="(column, i) in columns" :key="i" class="cell">
          <div>
            {{ column.name || '' }}
          </div>
        </th>
      </tr>
      <th class="row">
        <slot name="header"> </slot>
      </th>
    </thead>
    <tbody class="body">
      <slot name="body"> </slot>

      <tr
        v-for="(item, i) in items"
        :key="i"
        @click="$emit('rowclick', item)"
        class="row"
      >
        <td
          v-for="(column, i) in columns"
          :key="i"
          class="cell"
          :style="{
            minWidth: `${column.width}px`,
            height: rowHeight ? `${rowHeight}px` : 'auto'
          }"
        >
          <span
            v-on="column.events ? column.events.call(context, item) : {}"
            v-html="column.value(item)"
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from 'vue';
export default {
  props: {
    columns: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    rowHeight: {
      type: Number
    }
  },
  setup() {
    const WIDTH = computed(() => window.innerWidth);

    return {
      WIDTH
    };
  },
  data() {
    return {
      context: this
    };
  }
};
</script>

<style lang="scss" scoped>
.uiTable {
  width: 100%;
  font-size: 13px;

  .cell {
    text-align: left;
    vertical-align: top;
    padding: 10px;
    border-collapse: collapse;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  .head {
    .cell {
      position: sticky;
      top: 0;
      background: #fff;
      border-top: 1px solid #ddd;
      border-bottom: 3px solid #ddd;
      color: adjust-color($gray, $alpha: -0.6);
      border-right: 0;
      border-left: 0;
    }
  }

  .body {
    .row {
      &:nth-child(odd) {
        background: adjust-color($primary, $alpha: -0.93);
      }
    }

    // .cell {
    //   //
    // }
  }
}
</style>
