<template>
  <flex column class="expansion-panel">
    <flex column class="header">
      <slot name="header" :isOpen="isOpen" :toggleOpen="toggle"></slot>
    </flex>
    <template v-if="mode === 'if'">
      <flex column v-if="isOpen">
        <slot name="content"></slot>
      </flex>
    </template>
    <template v-else-if="mode === 'show'">
      <flex column v-show="isOpen">
        <slot name="content"></slot>
      </flex>
    </template>
  </flex>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'if'
    }
  },
  setup(props) {
    const isOpen = ref(props.open);

    const toggle = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggle
    };
  }
};
</script>

<style lang="scss" scoped>
.expansion-panel {
  .header {
    cursor: pointer;
  }
}
</style>
