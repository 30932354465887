import Api from '@/api/api';
import FetchProvider from '@/api/providers/fetch';
//import useDefaultValues from '@/composition/useDefaultValues';
import useDistricts from '@/composition/useDistricts';

import { reactive, toRefs } from 'vue';

const { areas, districts } = useDistricts();
const token = '194e6564fdfbeb90b76fc001583eba0ee0d0eb97';

const api = new Api({
  provider: new FetchProvider({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${token}`
    }
  })
});

const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';

const state = reactive({
  searchAddressResults: [],
  lastArea: null,
  kladr_id: 41,
  loading: false
});

const setKladrId = (areaId, val = null) => {
  if (state.lastArea != areaId && !val) {
    state.kladr_id = areas.value.find(item => item.area_id === areaId).kladr_id;
    state.lastArea = areaId;
  } else if (val != null) {
    let kladr = districts.value(areaId).find(item => item.area_id === val);
    if (kladr != null) {
      state.kladr_id = kladr.kladr_id;
      state.lastArea = kladr.kladr_id;
    }
  }
};

const search = async body => {
  if (body.query.length < 2) {
    return;
  }
  body.locations = [{ kladr_id: state.kladr_id }];
  state.loading = true;
  const {
    data: { suggestions: list }
  } = await api.post(url + '/suggest/address', body);
  state.searchAddressResults = list;
  state.loading = false;
};

const searchByFias = async body => {
  const {
    data: { suggestions: list }
  } = await api.post(url + '/findById/address', body);
  return list;
};

export default () => {
  return {
    ...toRefs(state),
    search,
    setKladrId,
    searchByFias
  };
};
