<template>
  <flex id="baseApp" fill class="height-100vh relative">
    <router-view />
  </flex>

  <modal full v-if="isOpenInfo">
    <iopr-welcome-info @close="isOpenInfo = false" />
  </modal>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const isOpenInfo = ref(
      parseInt(window.sessionStorage.getItem('showRedirectDesktop')) ?? true
    );

    return {
      isOpenInfo
    };
  }
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 24px;
}
</style>
