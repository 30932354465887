import { moduleOptions as ntoOptions } from '@/data/nto';
import { moduleOptions as rkOptions } from '@/data/rk';
import { moduleOptions as asotOptions } from '@/data/asot';
import { moduleOptions as roOptions } from '@/data/ro';

export default [
  {
    ...ntoOptions,
    path: '/nto'
  },
  {
    ...rkOptions,
    path: '/rk'
  },
  {
    ...asotOptions,
    path: '/asot'
  },
  {
    ...roOptions,
    path: '/ro'
  }
];
