let eventObjValue = null;
import { mainData } from '../map';

const getEl = el => {
  return document.getElementById(el);
};
const append_obj = `append_obj${Math.random()}`;
const append_obj_private = `append_obj_private${Math.random()}`;

const BalloonAppend = {
  template: `
    <div class="ballon">
        <div class="title-ymap mb-5">
            Добавить объект
        </div>
        <div id='${append_obj}' class="append_obj wrap-btn w230 mb-10">
            <div class="button_ymap button_blue">МУНИЦИПАЛЬНАЯ ЗЕМЛЯ</div>
        </div>
        <div id='${append_obj_private}' class="append_obj_private wrap-btn w230">
            <div class="button_ymap button_blue">ЧАСТНАЯ ЗЕМЛЯ</div>
        </div>
        </div>
        `,
  config: {
    build: function() {
      // First, we call the "build" method of the parent class.
      this.constructor.superclass.build.call(this);
      // Then we perform additional steps.
      getEl(append_obj).addEventListener('click', this.handleAppendClick);
      getEl(append_obj_private).addEventListener(
        'click',
        this.handleAppendPrivateClick
      );
    },

    clear: function() {
      getEl(append_obj).removeEventListener('click', this.handleAppendClick);
      getEl(append_obj_private).removeEventListener(
        'click',
        this.handleAppendPrivateClick
      );
      this.constructor.superclass.clear.call(this);
    },

    handleAppendClick: function() {
      eventObjValue = 'main';
      getEl('eventMap').click();
      mainData.mainCollection.remove(mainData.placemarkObj);
      mainData.placemarkObj = null;
    },
    handleAppendPrivateClick: function() {
      eventObjValue = 'private';
      getEl('eventMap').click();
      mainData.mainCollection.remove(mainData.placemarkObj);
      mainData.placemarkObj = null;
    }
  }
};

const BalloonAppendDo = {
  template: `<div id="${append_obj}" class="append_obj wrap-btn w230 mb-10">
            <div class = "button_ymap button_blue" > Добавить объект </div>
        </div>
        `,
  config: {
    build: function() {
      // First, we call the "build" method of the parent class.
      this.constructor.superclass.build.call(this);
      // Then we perform additional steps.
      getEl(append_obj).addEventListener('click', this.handleAppendClick);
    },

    clear: function() {
      getEl(append_obj).removeEventListener('click', this.handleAppendClick);
      this.constructor.superclass.clear.call(this);
    },

    handleAppendClick: function() {
      eventObjValue = 'main';
      getEl('eventMap').click();
      mainData.mainCollection.remove(mainData.placemarkObj);
      mainData.placemarkObj = null;
    }
  }
};

export { BalloonAppend, BalloonAppendDo, eventObjValue };
