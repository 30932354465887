import helpers from '@/helpers';

export default [
  {
    name: 'Уч. №',
    width: 70,
    value: object => object.obj_registration_number
  },
  {
    name: 'Адрес',
    width: 250,
    value: object => {
      return helpers.addressConcat({
        street: object.address_street,
        house: object.address_house
      });
    }
  }
];
