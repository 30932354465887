import { reactive, computed, toRef } from 'vue';
import useUser from '@/composition/useUser.js';
import router from '@/router';
import api from '@/api';

const { user } = useUser();

const ls = window.localStorage;

const state = reactive({
  access_token: ls.getItem('access_token') || ls.getItem('rsaa-token'),
  expiration:
    parseInt(ls.getItem('expiration') || ls.getItem('rsaa-token-exp')) ||
    undefined,
  loading: false
});

const access_token = computed(() => {
  if (!state.access_token || !state.expiration) return;
  if (Date.now() >= state.expiration) return;
  return state.access_token;
});

const login = async ({ email, password }) => {
  try {
    state.loading = true;
    const {
      data: { access_token, expires_in }
    } = await api.post(process.env.VUE_APP_GET_TOKEN_URL, {
      client_id: 4,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: 'password',
      scope: '*',
      username: email,
      password
    });
    state.access_token = access_token;
    state.expiration = Date.now() + parseInt(expires_in) * 1000;
    ls.setItem('access_token', state.access_token);
    ls.setItem('expiration', state.expiration);
    router.push('/');
  } finally {
    state.loading = false;
  }
};

const logout = () => {
  state.access_token = null;
  state.expiration = null;
  ls.removeItem('access_token');
  ls.removeItem('expiration');
  user.value = null;
  router.push('/login');
};

export default () => {
  return {
    loading: toRef(state, 'loading'),
    access_token,
    login,
    logout
  };
};
