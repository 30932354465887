<template>
  <flex column class="iopr-user">
    <flex class="mb-10">{{ user.name }}</flex>
    <flex class="font-color-gray mb-10">{{ user.organization }}</flex>
    <flex class="font-color-gray mb-20">{{ user.position }}</flex>
    <a
      href="mailto:support_iopr@permkrai.ru"
      class="mail-link flex align-items__center font-color-primary text-decoration-none py-15"
    >
      <icon name="mail" :size="20" />
      <span class="ml-20">Написать в поддержку</span>
    </a>
    <div
      class="exit-link flex align-items__center font-color-gray text-decoration-none pt-15"
      @click="moduleOut(), $router.push({ path: '/' })"
    >
      <icon name="exit" :size="20" />
      <span class="ml-20">Выход из системы</span>
    </div>
  </flex>
</template>

<script>
import useUser from '@/composition/useUser';
import useObjectsTree from '@/composition/map/useObjectsTree';
import useAreas from '@/composition/map/useAreas';

export default {
  setup() {
    const { user } = useUser();
    const { tree } = useObjectsTree();
    const { areas } = useAreas();

    const moduleOut = () => {
      tree.value = null;
      areas.value = null;
    };

    return {
      user,
      moduleOut
    };
  }
};
</script>

<style lang="scss" scoped>
.iopr-user {
  background-color: white;
  word-break: break-word;
  font-size: 15px;
  line-height: 18px;

  .mail-link,
  .exit-link {
    border-top: 1px solid scale-color($gray, $lightness: 85%);
  }
}
</style>
