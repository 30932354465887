export default class {
  constructor(options) {
    this.baseURL = this.normalizeURL(options.baseURL);
    this.headers = options.headers || {};
    this.requestInterceptor = config => config;
    this.responseErrorInterceptor = response => response;
  }

  normalizeURL(url) {
    if (!url) return '';
    return url.replace(/^\/+|\/+$/g, '');
  }

  async responseHandling(response) {
    const data = await response.json();
    if (response.status === 200) return data;
    if (!data) throw new Error(response.statusText);
    this.responseErrorInterceptor(response);
    if (data.message && typeof data.message === 'string')
      throw new Error(data.message);
    if (data.error && typeof data.error === 'string')
      throw new Error(data.error);
    throw new Error(response.statusText);
  }

  async get(url, params) {
    const requestURL = new URL(
      this.normalizeURL(url),
      this.baseURL ? `${this.baseURL}/` : undefined
    );
    requestURL.search = new URLSearchParams(params).toString();
    const config = this.requestInterceptor({
      headers: this.headers
    });
    if (!config) throw new Error('cofig undefined');
    const response = await fetch(requestURL, config);
    const data = await this.responseHandling(response);
    return { data };
  }

  async post(url, body) {
    const requestURL = new URL(
      this.normalizeURL(url),
      this.baseURL ? `${this.baseURL}/` : undefined
    );
    const headers = {};
    if (!(body instanceof FormData)) {
      headers['Accept'] = 'application/json, text/plain, */*';
      headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    const config = this.requestInterceptor({
      method: 'post',
      headers: {
        ...this.headers,
        ...headers
      },
      body: body instanceof FormData ? body : JSON.stringify(body)
    });
    if (!config) throw new Error('cofig undefined');
    const response = await fetch(requestURL, config);
    const data = await this.responseHandling(response);
    return { data };
  }
}
