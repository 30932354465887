<template>
  <div>
    <div>
      <eds
        @close="$router.go(-2)"
        @submit="register(), $router.go(-2)"
        v-if="isOpenEDS"
      ></eds>
      <confirmation
        @close="$router.go(-1)"
        @submit="register(true)"
        :btnLabel="'Добавить в реестр'"
        v-if="isOpenConfirm && !isOpenEDS"
      ></confirmation>
      <card-remove-object
        :permission="permission"
        @action="DeleteObject($event), (openDeleteAction = !openDeleteAction)"
        @close="openDeleteAction = !openDeleteAction"
        v-if="openDeleteAction"
      ></card-remove-object>
      <modal
        :height="'50%'"
        :width="'80%'"
        style="border-radius: 10px"
        v-if="openDelete"
      >
        <iopr-header class="py-10 px-20 border-bottom">
          <template #title>
            <div class="px-20">
              <span class="bold font-13 font-uppercase font-color-gray">
                удаление объекта</span
              >
            </div>
          </template>
          <template #action>
            <icon
              name="close"
              :size="20"
              class="text-gray"
              @click="openDelete = !openDelete"
            />
          </template>
        </iopr-header>
        <flex style="margin-top: 20%" column class="px-40">
          <flex fill>
            <ui-button
              primary
              @click="checkPermissionUser(`${entity}.btn_object_delete_full`)"
              >Полное удаление</ui-button
            >
          </flex>
          <flex class="py-20" fill>
            <ui-button
              @click="
                checkPermissionUser(`${entity}.btn_set_object_delete_status`)
              "
              >Установить статус "удален"</ui-button
            >
          </flex>
        </flex>
      </modal>
    </div>

    <div class="_content" v-for="(section, i) in reestr" :key="i">
      <expansion-panel
        v-if="currentLandId != logic.PROPERTY_TYPE_LEGAL"
        :open="true"
      >
        <template v-slot:header>
          <flex
            @click.stop="section.active = !section.active"
            class="pr-20 header px-20 py-10"
            :class="{ selected: section.active }"
            align-items="center"
          >
            <flex>
              <icon
                name="down"
                class="icon"
                :class="{ 'icon-rotate': section.active }"
              />
            </flex>
            <flex
              class="changes-inform ml-10"
              v-if="isChangesGroup(section.params)"
            ></flex>
            <flex fill class="ml-10 font-uppercase section-title_">
              {{ section.title }}
            </flex>
          </flex>
        </template>
        <template v-slot:content>
          <div v-if="section.active" class="card_content">
            <object-reestr-grid
              :object="object"
              :entity="entity"
              :isChangesGroup="isChangesGroup"
              :section="section"
            ></object-reestr-grid>
          </div>
        </template>
      </expansion-panel>
      <div v-else class="card_content">
        <object-reestr-grid
          :object="object"
          :entity="entity"
          :isChangesGroup="isChangesGroup"
          :section="section"
        ></object-reestr-grid>
      </div>
    </div>
    <div v-if="currentLandId != logic.PROPERTY_TYPE_LEGAL">
      <div class="block__"></div>
      <flex class="px-25 py-10 reestr_control">
        <flex fill>
          <ui-button
            :loading="loadingRegister"
            @click="isOpenConfirm = 1"
            :disabled="object.obj_registration_number"
            class="font-uppercase btn_smol_size"
            :class="{ success: !object.obj_registration_number }"
            >Добавить в реестр</ui-button
          >
        </flex>
        <flex class="ml-10">
          <ui-button
            v-if="canDelete"
            @click="openDelete = !openDelete"
            class="danger"
          >
            <icon :name="'delete'" />
          </ui-button>
        </flex>
        <flex class="ml-40">
          <ui-button
            @click="
              $emit('edit', {
                guid: object.guid,
                section: null
              })
            "
            primary
            ><icon :name="'edit'"
          /></ui-button>
        </flex>
      </flex>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import logic from '@/data/logic';

import useLands from '@/composition/useLands';
import useUser from '@/composition/useUser';
import useObject from '@/composition/useObject';
import useObjectsTree from '@/composition/map/useObjectsTree';

import useDirectories from '@/composition/useDirectories';
import useViolationTypes from '@/composition/useViolationTypes';
import useObjectTypes from '@/composition/useObjectTypes';
import useLandUsageTypes from '@/composition/useLandUsageTypes';

export default {
  props: {
    object: { type: Object },
    entity: {}
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const { load: loadViolationTypes } = useViolationTypes();
    const { load: loadObjectTypes } = useObjectTypes();
    const { load: loadDirectories } = useDirectories();
    const { load: loadLandUsageTypes } = useLandUsageTypes();

    const { checkPermission } = useUser();
    const { currentLandId } = useLands();
    const {
      removeObject,
      register: registerObj,
      loading: loadingRegister
    } = useObject();
    const { load } = useObjectsTree();
    const openDelete = ref(null);
    const openDeleteAction = ref(null);
    const reestr = ref([]);

    const canDelete = computed(() => {
      return (
        checkPermission(`${props.entity}.btn_object_delete_full`) ||
        checkPermission(`${props.entity}.btn_set_object_delete_status`)
      );
    });

    const isOpenConfirm = computed({
      get() {
        return Boolean(route.query.confirm);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            confirm: value
          }
        });
      }
    });

    const isOpenEDS = computed({
      get() {
        return Boolean(route.query.eds);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            eds: value
          }
        });
      }
    });

    const init = async () => {
      if (currentLandId.value !== logic.PROPERTY_TYPE_LEGAL) {
        if (props.entity == 'nto') {
          await loadLandUsageTypes();
          await loadObjectTypes();
          await loadViolationTypes();

          if (currentLandId.value !== logic.PROPERTY_TYPE_PUBLIC) {
            await loadDirectories();
          }
        }

        if (props.entity == 'ro') {
          await loadDirectories();
        }

        if (props.entity == 'asot') {
          await loadDirectories();
        }
      }

      // await LoadProtocol();
      if (currentLandId.value == logic.PROPERTY_TYPE_PUBLIC) {
        await import(`@/schema/${props.entity}/reestr/Reestr`).then(data => {
          reestr.value = data.default.sections(props.object);
        });
      } else if (currentLandId.value == logic.PROPERTY_TYPE_PRIVATE) {
        await import(`@/schema/${props.entity}/reestr/Reestr-private`).then(
          data => {
            reestr.value = data.default.sections(props.object);
          }
        );
      } else if (currentLandId.value == logic.PROPERTY_TYPE_LEGAL) {
        await import(`@/schema/${props.entity}/reestr/Reestr-legal`).then(
          data => {
            reestr.value = data.default.sections(props.object);
          }
        );
      }
    };
    init();

    const register = async (eds = false) => {
      if (eds) {
        isOpenConfirm.value = 0;
        isOpenEDS.value = 1;
      } else {
        await registerObj({ guid: props.object.guid }).then(() => {});
      }
    };
    const permission = ref(null);

    const DeleteObject = async statusDelete => {
      await removeObject({
        guid: props.object.guid,
        delete_comment: statusDelete.comment,
        path: permission.value,
        delete_reason: statusDelete.files
      });
      if (permission.value.indexOf('full') != -1) {
        emit('close', { removed: props.object.guid });
      } else {
        emit('close', {});
      }
      await load({ new: props.object.guid });

      permission.value = null;
    };

    const checkPermissionUser = permis => {
      openDelete.value = null;
      if (checkPermission(permis)) {
        permission.value = permis;
        openDeleteAction.value = true;
      }
    };

    const thumbler = ref({
      section: true,
      id: null
    });

    const toggleSection = i => {
      thumbler.value.id = thumbler.value.id == i ? null : i;
    };

    const checkReestr = key => {
      let keys = ['address_district_id', 'address_info', 'geo_polygon'];
      if (keys.includes(key)) {
        return props.object.obj_registration_number == null;
      }
      if (!key || key == 'title') {
        return false;
      }
      return true;
    };

    const isChangesGroup = (params, key = false) => {
      function checkKey(el) {
        let key = el.key;
        let findEl = key.indexOf('_name');
        if (findEl != -1) {
          key = key.split('_name')[0];
        }
        return (props.object.log || []).includes(key);
      }
      if (key) {
        return checkKey({ key: params });
      } else {
        return params.some(checkKey);
      }
    };

    return {
      logic,
      currentLandId,
      checkReestr,
      permission,
      isOpenConfirm,
      isOpenEDS,
      loadingRegister,
      register,
      canDelete,
      checkPermissionUser,
      DeleteObject,
      openDeleteAction,
      openDelete,
      reestr,
      thumbler,
      toggleSection,
      isChangesGroup
    };
  }
};
</script>

<style lang="scss" scoped>
.changes-inform {
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $primary;
}
.down {
  width: 16px;
  height: 16px;
}
.block__ {
  height: 60px;
}
.reestr_control {
  background: #f2f2f2;
  z-index: 10;
  bottom: 0px;
  position: fixed;
  right: 0px;
  left: 0px;
}
.section-title_ {
  color: #333333;
  font: normal normal bold 13px/15px Roboto;
}

.icon {
  color: #6d8bd0;
  transform: rotate(-90deg);
}
.edit {
  transform: unset;
}
.icon-rotate {
  transform: unset;
}
.card_content {
  .unset_border {
    border-top: unset !important;
  }
  .card-title_ {
    border-top: 1px solid #bebebe;
    padding-top: 10px;
    font: normal normal bold 13px/15px Roboto;
    color: #333333;
    opacity: 0.8;
  }
  .card_value {
    color: #808080;
    font: normal normal normal 13px/15px Roboto;
    margin-top: 5px;
  }
}
._content {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  margin: 2px;
}
</style>
