<template>
  <flex fill class="iopr-filters height-100">
    <iopr-header class="px-20 py-15 bg-gray_l-10 header">
      <template #title>
        <span class="font-uppercase font-bold font-color-gray">Фильтр</span>
      </template>
      <template #action>
        <icon
          class="font-color-gray"
          name="close"
          :size="20"
          @click="$emit('close')"
        />
      </template>
    </iopr-header>
    <flex fill column class="py-15 bg-gray_l-10 overflow-auto">
      <form-generator v-model="data" :fields="fields">
        <template v-slot:label="{ text }">
          <span
            class="font-color-gray font-uppercase font-bold font-size-13 mb-10"
            >{{ text }}</span
          >
        </template>
      </form-generator>
    </flex>
    <flex class="px-20 py-10">
      <flex fill class="mr-20">
        <uiButton
          primary
          class="font-uppercase"
          :disabled="!changes"
          @click="$emit('apply', data)"
          >применить</uiButton
        >
      </flex>
      <flex fill>
        <uiButton class="font-uppercase" @click="$emit('close')"
          >отмена</uiButton
        >
      </flex>
    </flex>
  </flex>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  props: {
    schema: {
      type: Function,
      default: () => []
    }
  },
  setup(props) {
    const route = useRoute();

    const initData = props.schema().reduce((result, field) => {
      const defaultValue = field.default;
      const queryValue = field.isNumber
        ? parseInt(route.query[field.property])
        : route.query[field.property];
      result[field.property] = queryValue || defaultValue;
      return result;
    }, {});

    const data = ref(initData);

    const fields = computed(() => {
      return props.schema(data);
    });

    const obj2str = obj => {
      return Object.entries(obj)
        .flat()
        .sort()
        .join();
    };

    const changes = computed(() => {
      return obj2str(initData) !== obj2str(data.value);
    });

    return {
      data,
      fields,
      changes
    };
  }
};
</script>

<style lang="scss" scoped>
.iopr-filters {
  .header {
    border-bottom: 1px solid scale-color($gray, $lightness: 70%);
  }
}
</style>
