import { reactive, toRefs, ref } from 'vue';
import useApiRouts from './useApiRouts.js';
import api from '@/api';
import useLands from '@/composition/useLands';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();

const state = reactive({
  directories: null,
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const currentKey = Object.entries({
    ...params
  })
    .flat()
    .sort()
    .join();
  if (currentKey === lastKey.value) return;
  try {
    state.loading = true;

    const url = (() => {
      if (currentLandId.value == 1) {
        return apiRouts.value.GET_MUNICIPAL_DIRECTORIES;
      }
      if (currentLandId.value == 2)
        return apiRouts.value.GET_PRIVATE_DIRECTORIES;
    })();

    const {
      data: { data: directories }
    } = await api.post(url);
    state.directories = directories;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
