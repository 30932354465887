<template>
  <div>
    <div class="sidebar-card-history__sort px-20" @click="sort">
      <i :class="{ sortcalendardown: !srt, sortcalendarup: srt }"></i>
    </div>
    <div class="sidebar-card-history__stages flex">
      <div class="sidebar-card-history__groups px-20">
        <div class="history-group" v-for="(item, i) in historyFiltred" :key="i">
          <div class="history-stage attribute row">
            <div class="history-stage-container">
              <div class="history-stage__icon">
                <i
                  v-if="item.action_slug === 'status_changed'"
                  title="Работа согласована"
                  class="icon-status ok"
                ></i>
                <div v-else class="icon-dot-step"></div>
              </div>
              <div class="history-stage__status">
                <div v-if="item.action_slug === 'status_changed'">
                  <span class="primary">{{ item.action_name }}</span> :
                  {{ item.attribute_value }}
                </div>
                <div v-else>
                  {{ item.action_name }}
                  <span v-if="item.action_slug !== 'created'">
                    : {{ item.attribute_value }}</span
                  >
                </div>
              </div>
              <div class="history-stage__content mt-5">
                <div class="date">
                  {{ formatDate(item.action_date) }}
                </div>
              </div>
              <div class="history-stage__content mt-5">
                {{ item.user_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import helpers from '@/helpers';

export default {
  props: {
    history: { type: Array }
  },
  setup(props) {
    const srt = ref(null);

    const data = ref(props.history);

    const historyFiltred = computed(() =>
      data.value.filter(item => {
        return [
          'created',
          'registered',
          'moved',
          'deleted',
          'archived',
          'status_changed'
        ].includes(item.action_slug);
      })
    );

    const formatDate = date => {
      return helpers.dateFormat(date);
    };

    const sort = () => {
      srt.value = !srt.value;
      data.value = data.value.reverse();
    };

    return {
      srt,
      sort,
      formatDate,
      historyFiltred
    };
  }
};
</script>

<style lang="scss" scoped>
.sidebar-card-history {
  height: 100%;

  .toggle-attrs {
    color: $primary;
    cursor: pointer;
  }

  &__header {
    margin-left: -30px;
    margin-right: -25px;
    padding: 20px 25px 20px 30px;
    border-bottom: 1px solid $soft-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__groups {
    //margin-left: 55px;
    width: 100%;
  }

  &__sort {
    padding-top: 12px;
    color: $primary;
    font-size: 18px;

    i {
      cursor: pointer;
    }
  }

  &__stages {
    .history-group {
      &:last-child {
        .history-stage:last-child {
          &:before {
            // display: none;
          }
        }
      }
    }

    .history-stage {
      padding: 35px;
      position: relative;
      border-radius: 5px;
      margin-bottom: 10px;

      &.attribute {
        padding-top: 8px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: calc(100% - 33px);
        background-color: #e8e8e8;
        top: 43px;
        left: 6px;
        //left: -26px;
      }

      &.highlighted {
        background-color: rgba($primary, 0.05);
      }

      &__icon {
        position: absolute;
        left: -2px;
        top: 16px;

        i {
          color: $primary;
          font-size: 20px;
        }

        .icon-dot-step {
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: $gray_common;
          margin-left: 7px;
        }
      }

      &__status {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
      }

      &__expired {
        color: #f65f41;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
      }

      &__content {
        font-size: 13px;
        line-height: 15px;
        word-break: break-all;

        .date,
        .name {
          color: $gray_common;
        }

        &:first-letter {
          text-transform: uppercase;
        }

        &.line-through {
          text-decoration: line-through;
          opacity: 0.4;
        }
      }

      &_current {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &__inner-wp {
    margin-left: -35px;

    & + & {
      margin-top: 20px;
    }
  }
}
</style>
