import { h, inject } from 'vue';

export default {
  props: {
    position: {
      type: Object,
      default: () => ({ top: '10px', left: '10px' })
    },
    size: {
      type: Number,
      default: 56
    },
    provider: {
      type: String,
      default: 'auto'
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { slots }) {
    const getYmaps = inject('getYmaps');
    const getMap = inject('getMap');

    const ymaps = getYmaps();
    const map = getMap();

    const onClick = async () => {
      try {
        const location = await ymaps.geolocation.get({
          provider: props.provider,
          ...props.options
        });
        location.geoObjects.options.set('iconLayout', false);
        map.geoObjects.add(location.geoObjects);
      } catch (error) {
        alert(error.message);
      }
    };

    return () => {
      return h(
        'div',
        {
          style: {
            position: 'absolute',
            cursor: 'pointer',
            width: `${props.size}px`,
            height: `${props.size}px`,
            ...props.position
          },
          onClick
        },
        h(slots.default)
      );
    };
  }
};
