<template>
  <div class="py-10" v-for="(_section, x) in section.params" :key="x">
    <div
      v-if="
        ('_if' in _section && _section._if != false) ||
          _section._if == undefined
      "
    >
      <flex
        class="card-title_ px-10"
        :class="{
          unset_border: 'unset_border' in _section
        }"
        align-items="center"
      >
        <!-- v-if="isChangesGroup(_section.key, true)"-->
        <flex class="changes-inform mr-10"></flex>
        <flex class="font-uppercase" fill>{{ _section.title }}</flex>
        <!-- <flex align-items="flex-end">
                      <icon
                        @click="
                          $emit('edit', {
                            guid: object.guid,
                            section: null
                          })
                        "
                        v-if="
                          'editable' in section && checkReestr(_section.key)
                        "
                        name="edit"
                        class="icon font-color-gray"
                      />
                    </flex> -->
      </flex>
      <div v-if="_section.key != 'title'" class="card_value px-20">
        <div v-if="isFiles(_section) == 'files'">
          <edit-files
            :border_bottom="false"
            :disabled="true"
            :proxyData="getFiles(_section.key)"
            :uploadFolder="_section.key"
          ></edit-files>
        </div>
        <div v-else-if="isFiles(_section) == 'photos'">
          <edit-photos
            :border_top="false"
            :border_bottom="false"
            disabled
            :proxyData="getFiles(_section.key)"
            :uploadFolder="_section.key"
          ></edit-photos>
        </div>
        <div v-else-if="'map' in _section && object[_section.key] != null">
          <map-place
            v-if="entity != 'asot'"
            :center="getValue(_section.key, _section)"
            disabled
          ></map-place>
          <map-place-asot
            v-else
            :modelValue="getValue(_section.key, _section)"
            :center="getValue('polygon_center', _section)"
            :zoom="16"
            disabled
            :types="['polygon']"
            defaultType="polygon"
          ></map-place-asot>
        </div>
        <div v-else>
          {{ getValue(_section.key, _section) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objectHelpers from '@/composition/object/objectHelpers';
import mapPlaceAsot from './map-place-asot.vue';

export default {
  components: { mapPlaceAsot },
  props: {
    section: {},
    isChangesGroup: { type: Function },
    object: {},
    entity: {}
  },
  setup(props) {
    const getValue = (key, _section) => {
      return objectHelpers.changeValue(
        props.object,
        key,
        props.entity,
        _section
      );
    };

    const isFiles = _section => {
      if (_section.key) {
        if ('files' in _section || _section.key.indexOf('files') != -1) {
          return 'files';
        }

        if (_section.key.indexOf('docs') != -1) {
          return 'files';
        }

        if (_section.key.indexOf('photos') != -1) {
          return 'photos';
        }

        if ('photos' in _section) {
          return 'photos';
        }
      }
      return null;
    };

    const getFiles = value => {
      return objectHelpers.getFiles(props.object, value);
    };

    return {
      isFiles,
      getFiles,
      getValue
    };
  }
};
</script>
