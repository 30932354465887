import app from '@/app.js';
import yandexMaps from './yandex-maps.js';
import yandexMap from './yandex-map.js';
import yandexPlacemark from './yandex-placemark.js';
import yandexPolygon from './yandex-polygon.js';
import yandexObjectManager from './yandex-object-manager.js';
import yandexLayoutTemplate from './yandex-layout-template.js';
import yandexGeolocation from './yandex-geolocation.js';

app.component('yandex-geolocation', yandexGeolocation);
app.component('yandex-maps', yandexMaps);
app.component('yandex-map', yandexMap);
app.component('yandex-placemark', yandexPlacemark);
app.component('yandex-polygon', yandexPolygon);
app.component('yandex-object-manager', yandexObjectManager);
app.component('yandex-layout-template', yandexLayoutTemplate);
