<template>
  <flex :column="column">
    <slot>
      <radio-option v-for="(item, i) in items" :key="i" :value="item.value">
        {{ item.text }}
      </radio-option>
    </slot>
  </flex>
</template>

<script>
import { computed, provide } from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean]
    },
    items: {
      type: Array,
      default: () => []
    },
    column: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    provide('model', model);
    provide('button', props.button);
    provide('column', props.column);
    provide('small', props.small);
    provide('large', props.large);
  }
};
</script>
