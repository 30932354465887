import Filds from '@/schema/fields';
import useDistricts from '@/composition/useDistricts';
import useDefaultValues from '@/composition/useDefaultValues';
//import useAreas from '@/composition/map/useAreas';
//const { Areas } = useAreas();
const { areas } = useDistricts();

export default {
  init(field, dataFields, value) {
    if (field.property == ('area_id' || 'areaId')) {
      const { defaultValues } = useDefaultValues();
      defaultValues.value.AREA_ID = value;
      let state = {
        kladr_id: null
      };
      //Areas.value = null;
      dataFields.map(el => {
        if (el.property == ('address_district_id' || 'districtId')) {
          el.componentAttrs.disabled =
            Filds.districts(value).length < 2 || value == -1;
          el.componentAttrs.items = Filds.districts(value);
        }
        if (el.property == 'address_full') {
          el.componentAttrs.areaId = value;
        }

        if (el.property == 'geo_polygon') {
          let area = areas.value.filter(area => area.area_id == value).pop();

          state.kladr_id = area.kladr_id;
          el.componentAttrs.center = [area.area_lon, area.area_lat];
          el.componentAttrs.polygon = JSON.parse(area.area_polygon);
        }

        if (el.property == 'address_full' && state.kladr_id != null) {
          el.componentAttrs.areaId = state.kladr_id;
          state.kladr_id = null;
        }

        return el;
      });
    }

    if (field.property == 'address_district_id') {
      dataFields.map(el => {
        if (el.property == 'address_full') {
          el.componentAttrs.districtId = value;
        }
        return el;
      });
    }

    if (field.property == 'address_district_id') {
      dataFields.map(el => {
        if (el.property == 'address_full') {
          el.componentAttrs.districtId = value;
        }
        return el;
      });
    }

    return dataFields;
  }
};
