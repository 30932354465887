<template>
  <div class="width-100">
    <flex
      fill
      class="uiInput"
      :class="{ before: $slots.before, after: $slots.after }"
    >
      <flex>
        <flex v-if="$slots.before" align-items="center" class="slot-before">
          <slot name="before"></slot>
        </flex>
        <input :class="{ small, large }" v-bind="$attrs" v-model="model" />
        <flex v-if="$slots.after" align-items="center" class="slot-after">
          <slot name="after"></slot>
        </flex>
      </flex>
    </flex>
    <slot name="content"></slot>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    return {
      model
    };
  }
};
</script>

<style lang="scss" scoped>
.uiInput {
  input {
    border: 1px solid scale-color($gray, $lightness: 85%);
    border-radius: 5px;
    background-color: white;
    width: 100%;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    outline: none;

    &:disabled {
      opacity: 0.7;
    }

    &.small {
      line-height: 32px;
      height: 32px;
    }

    &.large {
      line-height: 50px;
      height: 50px;
    }
  }

  &.before {
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 0;
    }
  }

  &.after {
    input {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 0;
    }
  }

  .slot-before,
  .slot-after {
    background-color: white;
    border: 1px solid scale-color($gray, $lightness: 85%);
    padding: 0 10px;
  }

  .slot-before {
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .slot-after {
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
