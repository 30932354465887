import { reactive, toRefs } from 'vue';
import api from '@/api';

const state = reactive({
  ownerInfo: null,
  ownerStatus: null,
  administativeDecision: null,
  paymentStatus: null,
  transferResolutionStatus: null,
  voluntaryPaymentStatus: null,
  courtDecision: null,
  articleOffence: null,
  baseArticleOffence: null,
  loading: false,
  allData: []
});
// state.ownerInfo,
//state.ownerStatus,
const all = () => {
  let all = [
    // ...state.ownerInfo.data,
    //...state.ownerStatus.data,
    ...state.administativeDecision.data,
    ...state.paymentStatus.data,
    ...state.transferResolutionStatus.data,
    ...state.voluntaryPaymentStatus.data,
    ...state.courtDecision.data,
    ...state.articleOffence.data,
    ...state.baseArticleOffence.data
  ];
  state.allData = all;
};

const loadOwner = async () => {
  const {
    data: { data }
  } = await api.post('/dict/nto/protocol/owner');
  state.ownerInfo = data.info;
  state.ownerStatus = data.status;
};
const loadAdministativeDecision = async () => {
  const { data: list } = await api.post(
    '/dict/nto/protocol/administative_decision'
  );
  state.administativeDecision = list;
};
const loadPaymentStatus = async () => {
  const { data: list } = await api.post('/dict/nto/protocol/payment_status');
  state.paymentStatus = list;
};
const loadTransferResolutionStatus = async () => {
  const { data: list } = await api.post(
    '/dict/nto/protocol/transfer_resolution_status'
  );
  state.transferResolutionStatus = list;
};
const loadVoluntaryPaymentStatus = async () => {
  const { data: list } = await api.post(
    '/dict/nto/protocol/voluntary_payment_status'
  );
  state.voluntaryPaymentStatus = list;
};
const loadCourtDecision = async () => {
  const { data: list } = await api.post('/dict/nto/protocol/court_decision');
  state.courtDecision = list;
};
const loadArticleOffence = async () => {
  const { data: list } = await api.post('/dict/nto/protocol/article_offence');
  state.articleOffence = list;
};
const loadBaseArticleOffence = async () => {
  const { data: list } = await api.post(
    '/dict/nto/protocol/base_article_offence'
  );
  state.baseArticleOffence = list;
};

const load = async () => {
  try {
    state.loading = true;
    await Promise.all([
      loadOwner(),
      loadAdministativeDecision(),
      loadPaymentStatus(),
      loadTransferResolutionStatus(),
      loadVoluntaryPaymentStatus(),
      loadCourtDecision(),
      loadArticleOffence(),
      loadBaseArticleOffence()
    ]);
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load,
    all
  };
};
