import useDirectories from '@/composition/useDirectories';
import useDistricts from '@/composition/useDistricts';
import useViolationTypes from '@/composition/useViolationTypes';

import useObjectTypes from '@/composition/useObjectTypes';
import useLandUsageTypes from '@/composition/useLandUsageTypes';
import useKeepPlaces from '@/composition/useKeepPlaces';

const { areas, districts } = useDistricts();

const { directories } = useDirectories();

const { violationTypes } = useViolationTypes();

const { objectTypes } = useObjectTypes();

const { landUsageTypes } = useLandUsageTypes();

const { keepPlaces } = useKeepPlaces();

export default {
  getPolygon(id, center = false) {
    if (id == undefined) {
      id = 41;
    }
    let area = areas.value.filter(a => a.area_id == id);
    area = area.pop();

    if (center && 'area_lat' in area) {
      return [area.area_lon, area.area_lat];
    }

    if ('area_polygon' in area) {
      return JSON.parse(area.area_polygon);
    }
    return null;
  },
  getArea(id) {
    if (id == undefined) {
      id = 41;
    }
    let area = areas.value.find(a => a.area_id == id);

    return area;
  },
  getDirectories(nsi_dictionary_id = null) {
    if (nsi_dictionary_id) {
      return directories.value.filter(
        el => el.nsi_dictionary_id == nsi_dictionary_id
      );
    } else {
      return directories.value;
    }
  },
  getDirectoriesList(number) {
    if (directories.value) {
      return directories.value
        .filter(e => e.nsi_dictionary_id === number)
        .map(el => {
          return {
            text: el.name,
            value: el.id
          };
        });
    }
    return [];
  },
  areas: () => {
    return areas.value.map(district => {
      return {
        value: district.area_id,
        text: district.area_name
      };
    });
  },
  districts: areaId => {
    if (!areaId) return [];
    return [
      ...districts.value(areaId).map(district => {
        return {
          value: district.area_id,
          text: district.area_name
        };
      })
    ];
  },
  permittedUseKindListGroup: val => {
    if (val) {
      return directories.value;
    }
    if (directories.value) {
      const truncString = (s, l, b = '...') =>
        s.substring(0, l) + (s.length > l ? b : '');

      let kindsList = [
        {
          label: ' ',
          text: '',
          value: -1,
          options: []
        }
      ];

      let permittedUseKindList = directories.value.filter(
        e => e.nsi_dictionary_id === 6
      );

      permittedUseKindList
        .filter(kind => kind.parent_id === null)
        .sort(function(a, b) {
          return ('' + a.name).localeCompare(b.name); // a.sort_order - b.sort_order
        })
        .forEach(kind => {
          let row = {
            value: kind.id,
            label: truncString(kind.name, 80),
            text: kind.name,
            options: permittedUseKindList
              .filter(subkind => subkind.parent_id === kind.id)
              .map(subkind => {
                return {
                  value: subkind.id,
                  label: truncString(subkind.name, 80),
                  text: subkind.name
                  // sort_order: subkind.sort_order
                };
              })
            // sort_order: 0 // kind.sort_order
          };

          if (row.options.length > 0) {
            kindsList.push(row);
          } else {
            kindsList[0].options.push(row);
          }
        });
      if (kindsList[0].options.length > 0) {
        kindsList.push(kindsList.shift());
      } else {
        kindsList.shift();
      }
      kindsList.forEach(item =>
        item.options.sort(function(a, b) {
          return ('' + a.label).localeCompare(b.label); // a.sort_order - b.sort_order
        })
      );
      return kindsList;
    }
    return [];
  },

  object_functioning_marks: () => {
    return [
      {
        text: 'да',
        value: 188
      },
      {
        text: 'нет',
        value: 189
      }
    ];
  },
  boolRadio: () => {
    return [
      {
        text: 'да',
        value: true
      },
      {
        text: 'нет',
        value: false
      }
    ];
  },
  violationTypes: (key = null) => {
    let violation = violationTypes.value;
    if (!violation) {
      violation = directories.value.filter(el => el.nsi_dictionary_id == 32);
    } else if (key) {
      if (directories.value.length) {
        violation = directories.value.filter(el => el.nsi_dictionary_id == key);
      } else {
        violation = violation.filter(el => el.nsi_dictionary_id == key);
      }
    }

    if (violation) {
      return violation.map(el => {
        return {
          value: el.id,
          text: el.name
        };
      });
    }
  },
  objectTypes: (key = null) => {
    let objectType = objectTypes.value;

    if (key) {
      objectType = directories.value.filter(el => el.nsi_dictionary_id == key);
    } else if (!objectType) {
      objectType = directories.value.filter(el => el.nsi_dictionary_id == 4);
    }

    if (objectType) {
      return objectType.map(el => {
        return {
          value: el.id,
          text: el.name
        };
      });
    }
  },
  landUsageTypes: (key = null) => {
    let landUsage = landUsageTypes.value;

    if (key) {
      if (directories.value.length) {
        landUsage = directories.value.filter(el => el.nsi_dictionary_id == key);
      } else {
        landUsage = landUsage.filter(el => el.nsi_dictionary_id == key);
      }
    }
    if (landUsage) {
      return landUsage.map(el => {
        return {
          value: el.id,
          text: el.name
        };
      });
    }
  },
  keepPlaces: (key = null) => {
    let keeps = keepPlaces.value;

    if (key) {
      if (directories.value.length) {
        keeps = directories.value.filter(el => el.nsi_dictionary_id == key);
      } else {
        keeps = keeps.filter(el => el.nsi_dictionary_id == key);
      }
    }
    if (keeps) {
      return keeps.map(el => {
        return {
          value: el.id,
          text: el.name
        };
      });
    }
  }
};
