export const moduleOptions = {
  icon: 'nto',
  alias: 'nto',
  name: `Демонтаж нестационарных\nторговых объектов`
};

export const apiRouts = {
  GET_DISTRICTS: '/dict/nto/districts',
  GET_YEARS: '/nto/years',
  GET_MUNICIPAL_OBJECTS: '/nto/objects',
  GET_PRIVATE_OBJECTS: '/nto/private/objects',
  GET_MUNICIPAL_OBJECT: '/nto/object',
  GET_PRIVATE_OBJECT: '/nto/private/object',
  GET_OBJECT_LEGAL: '/nto/object-legal',
  GET_MUNICIPAL_STATUSES: '/nto/filters',
  GET_PRIVATE_STATUSES: '/nto/private/filters',

  GET_OBJECT_HISTORY: '/nto/object/history',
  GET_PRIVATE_OBJECT_HISTORY: '/nto/private/object/history',
  GET_POLYGONS: '/dict/nto/polygon',
  GET_OBJECT_TREE: '/nto/map-object-tree',
  GET_MAP_OBJECTS: '/nto/objects-map',
  GET_MAP_AREAS: '/nto/map-area-list',

  GET_PRIVATE_DIRECTORIES: '/nto/private/directories',

  GET_VIOLATION_TYPES: '/dict/nto/violations',
  GET_OBJECT_TYPES: '/dict/nto/objtypes',
  SAVE_OBJECT: '/nto/save',

  GET_PROTOCOL: '/dict/nto/protocol/article_offence',
  SAVE_OBJECT_PRIVATE: '/nto/private/save',
  REMOVE_OBJECT: '/nto/delete',
  REMOVE_OBJECT_FULL: '/nto/delete-full',

  SEARCH_MAP: '/nto/search-map',

  GET_LAND_USAGE_TYPES: '/dict/nto/lands',
  GET_KEEP_PLACES: '/dict/nto/keeps',

  REGISTER_OBJECT_PRIVATE: '/nto/private/register',
  REGISTER_OBJECT: '/nto/register'
};

export const defaultValues = {
  LAND_ID: 1,
  AREA_ID: 41,
  DISTRICT_ID: null,
  MUNICIPAL_STATUS_ID: 1,
  PRIVATE_STATUS_ID: 218
};
