import {
  h,
  ref,
  provide,
  inject,
  onMounted,
  watch,
  onBeforeUnmount
} from 'vue';
//import ZoomLayout from './layouts/zoom';

export default {
  props: {
    height: {
      default: '100vh'
    },
    center: {
      type: Array,
      required: true
    },
    zoom: {
      type: Number,
      default: 10
    },
    controls: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit, slots }) {
    const mapId = `map_${Math.random()}`;
    const isInit = ref(false);

    const getYmaps = inject('getYmaps');
    const ymaps = getYmaps();

    let map = null;
    provide('getMap', () => map);

    const onClick = event => {
      emit('click', event);
    };

    onMounted(async () => {
      ymaps.ready(() => {
        map = new ymaps.Map(
          mapId,
          {
            center: props.center,
            zoom: props.zoom,
            controls: props.controls,
            behaviors: props.behaviors
          },
          {
            suppressMapOpenBlock: true,
            ...props.options
          }
        );

        isInit.value = true;
      });
    });

    watch(
      () => props.center,
      value => {
        if (!map) return;
        map.setCenter(value);
      }
    );

    watch(
      () => props.zoom,
      value => {
        if (!map) return;
        map.setZoom(value);
      }
    );

    onBeforeUnmount(() => {
      map.events.remove('click', onClick);
      map.destroy();
    });

    return () => {
      const slot = () => {
        if (isInit.value && slots.default) {
          return h(slots.default);
        }
      };
      return h(
        'div',
        {
          id: mapId,
          style: {
            height: '100%',
            width: '100%',
            position: 'relative'
          }
        },
        slot()
      );
    };
  }
};
