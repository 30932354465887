<template>
  <div>
    <div>
      <object-menu
        v-if="currentLandId != logic.PROPERTY_TYPE_LEGAL"
        @changesection="changeSection($event), (thumbler.menu = false)"
        @closemenu="thumbler.menu = !thumbler.menu"
        :entity="entity"
        :menu="thumbler.menu"
        :active="menu[menuSection].title"
        :title="objectTitle"
        :items="menu"
      ></object-menu>

      <modal
        full
        class="filters"
        v-if="!thumbler.menu"
        :class="{ loading: loadingObject }"
      >
        <flex
          v-if="object && !thumbler.menu"
          column
          class="bg-white sticky-top"
          style="z-index: 1"
        >
          <iopr-header class="px-20 py-10">
            <template #icon>
              <icon :name="entity" :size="20" class="text-gray" />
            </template>
            <template #title>
              <div v-if="!isObjectRegistr" class="px-10">
                <span v-if="object.is_deleted" class="font-uppercase">
                  Объект удален
                </span>
                <span v-if="currentLandId === logic.PROPERTY_TYPE_LEGAL">
                  ЗАКОННЫЙ ОБЪЕКТ
                </span>
                <span v-else class="red_c font-uppercase">
                  учетный номер не присвоен
                </span>
              </div>
              <div v-else class="px-10">{{ objectTitle }}</div>
            </template>
            <template #action>
              <icon
                name="close"
                :size="20"
                class="text-gray"
                @click="thumblercard"
              />
            </template>
            <template v-slot:about="{ isOpen, toggle }">
              <object-about
                v-if="currentLandId != logic.PROPERTY_TYPE_LEGAL"
                :open="isOpen"
                @isOpen="toggle"
                :object="object"
              ></object-about>
            </template>
          </iopr-header>
        </flex>
        <flex
          v-if="currentLandId != logic.PROPERTY_TYPE_LEGAL"
          column
          class="object-menu shadow"
        >
          <iopr-header class="px-20 py-10">
            <template #title>
              <span class="font-bold font-uppercase font-color-black">{{
                menu[menuSection].title
              }}</span>
            </template>
            <template #action>
              <div @click="toggle('menu')" class="menu_el">
                <icon name="mobile" :size="20" class="px text-gray" />
              </div>
            </template>
          </iopr-header>
        </flex>

        <flex v-if="object && !thumbler.menu" column class="">
          <object-reestr
            :entity="entity"
            v-if="menuSection == 0"
            :object="object"
            @edit="$emit('edit', $event)"
            @close="$emit('close', $event)"
          ></object-reestr>

          <object-owner v-if="menuSection == 3" :object="object">
          </object-owner>

          <object-history
            :history="history"
            v-if="menuSection == 1 && history.length"
          ></object-history>

          <object-protocol
            :entity="entity"
            :object="object"
            v-if="menuSection == 2"
          ></object-protocol>
        </flex>
      </modal>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import logic from '@/data/logic';

import useObject from '@/composition/useObject';
import useObjectHistory from '@/composition/useObjectHistory';
import objectHelpers from '@/composition/object/objectHelpers';
import useViolationTypes from '@/composition/useViolationTypes';
import useObjectTypes from '@/composition/useObjectTypes';
import useLands from '@/composition/useLands';

export default {
  props: {
    guid: { type: String },
    entity: {
      type: String
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const { currentLandId } = useLands();

    const { load: loadViolationTypes } = useViolationTypes();
    const { load: loadObjectTypes } = useObjectTypes();

    const about = ref({
      isOpen: false,
      height: 0
    });

    const aboutToggle = event => {
      about.value = event;
    };

    const menuSection = ref(0);
    // const route = useRoute();
    // const router = useRouter();
    const menu = ref([
      {
        title: 'Реестровые данные'
      },
      {
        title: 'История изменений'
      },
      {
        title: 'Протоколы'
      },
      {
        title: 'Ответственный'
      }
    ]);
    const thumbler = ref({
      section: true,
      id: null,
      content: false,
      status_desc: false,
      menu: false,
      cardOpen: true
    });
    const { object, load: loadObject, loading: loadingObject } = useObject();
    const { history, load: loadObjectHistory } = useObjectHistory();

    const isObjectGuid = computed({
      get() {
        return route.query.guid || props.guid;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            guid: value
          }
        });
      }
    });

    if (isObjectGuid.value) {
      loadObject({
        guid: isObjectGuid.value
      }).then(() => {
        object.value.entity = props.entity;
      });
      if (currentLandId.value != logic.PROPERTY_TYPE_LEGAL) {
        loadObjectHistory({
          guid: isObjectGuid.value
        });
      }
    }

    const changeSection = val => {
      menuSection.value = val;
    };

    const getValue = key => {
      return objectHelpers.changeValue(object.value, key);
    };

    const thumblercard = () => {
      emit('close');
      thumbler.value.cardOpen = false;

      setTimeout(() => {
        object.value = {};
      }, 100);
    };

    const openObj = obj => {
      thumbler.value.cardOpen = !thumbler.value.cardOpen;
      emit('openObject', obj);
    };

    const isObjectRegistr = computed(() => {
      if (!object.value) return;
      return object.value.obj_registration_number;
    });

    const objectTitle = computed(() => {
      if (!object.value) return;
      return `УЧ № ${object.value.obj_registration_number} от ${getValue(
        'obj_registration_date'
      )}`;
    });

    const init = async () => {
      if (currentLandId != logic.PROPERTY_TYPE_LEGAL) {
        switch (props.entity) {
          case 'rk':
            await loadViolationTypes();
            await loadObjectTypes();
            break;
          case 'nto':
            break;

          case 'ro':
            await loadViolationTypes();
            await loadObjectTypes();
            break;
          case 'asot':
            // await loadViolationTypes();
            //await loadObjectTypes();
            break;
        }
      }
    };
    init();

    const toggle = item => {
      thumbler.value[item] = !thumbler.value[item];
    };

    return {
      currentLandId,
      logic,
      aboutToggle,
      about,
      loadingObject,
      toggle,
      object,
      menuSection,
      menu,
      thumbler,
      history,
      changeSection,
      getValue,
      thumblercard,
      openObj,
      objectTitle,
      isObjectRegistr
    };
  }
};
</script>

<style lang="scss" scoped>
.object-menu {
  border-top: 1px solid #ccc;
}
// .block_items {
//   transition: 0.5s;
// }
// .card_menu_header {
//   transition: 0.8s;
//   margin-top: 50px;
//   padding: 20px;
// }
// .card-header-form {
//   z-index: 1000;
//   position: fixed;
//   width: 100%;
//   background-color: white;
// }
.red_c {
  color: #f5785f;
}
// .shadow {
//   border-top: 1px solid #00000029;
//   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
//   margin-bottom: 30px;
//   max-height: 25px;
// }
// .shadow_ {
//   border-top: 1px solid #00000029;
//   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
//   margin-bottom: 20px;
//   max-height: 25px;
// }

// .box-unmount {
//   display: inline-block;
// }

// .menu_el {
//   position: relative;
// }

// .header {
//   padding-top: 8px;
//   padding-bottom: 8px;

//   .icon-down {
//     color: #6d8bd0 !important;
//     width: 14px;
//     height: 8px;
//   }

//   .empty {
//     visibility: hidden;
//   }

//   .icon.close {
//     transform: rotate(-90deg);
//   }

//   .selected {
//     background: unset !important;
//   }
// }
</style>
