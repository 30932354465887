import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from '@/composition/useApiRouts.js';

const { apiRouts } = useApiRouts();

const state = reactive({
  areas: [],
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const currentKey = Object.entries({
    ...params
  })
    .flat()
    .sort()
    .join();
  if (currentKey === lastKey.value && state.areas != null) return;
  try {
    state.loading = true;
    const url = (() => {
      return apiRouts.value.GET_MAP_AREAS;
    })();
    const {
      data: { data: areas }
    } = await api.post(url, {
      search: '',
      type: 'object',
      per_page: 10,
      page: 1,
      system_entry_point_id: 2
    });
    state.areas = areas;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
