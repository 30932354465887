<template>
  <flex column class="m-10 px-10 py-20 border-radius-5 bg-primary_l-10">
    <flex class="mb-30 font-color-gray">
      Вы находитесь в Мобильной версии Подсистемы «ИНФОРМАЦИОННОЕ ОБЕСПЕЧЕНИЕ И
      АНАЛИТИКА ОБЪЕКТОВ ПОТРЕБИТЕЛЬСКОГО РЫНКА ПЕРМСКОГО КРАЯ (ИОПР ПК,
      ПОДСИСТЕМА)»
    </flex>
    <flex column class="mb-50 font-color-gray">
      Данная версия представляет ограниченный функционал Подсистемы, а именно:
      <ol class="list-normalize">
        <li>Создание объекта</li>
        <li>Просмотр карточки объекта</li>
        <li>Просмотр карточки протокола</li>
        <li>Редактирование ограниченного числа атрибутов</li>
        <li>Работу с картой объектов</li>
        <li>Поиск объектов</li>
      </ol>
    </flex>
    <flex column>
      <flex justify-content="center">
        <uiButton primary class="font-uppercase px-30" @click="skip">
          продолжить
        </uiButton>
      </flex>
      <flex justify-content="center" class="mt-20">
        <a
          :href="fullVersionLink"
          class="font-color-primary text-decoration-none"
        >
          Перейти на полную версию
        </a>
      </flex>
    </flex>
  </flex>
</template>

<script>
const fullVersionLink = process.env.VUE_APP_FULL_VERSION_URL;

export default {
  setup(props, { emit }) {
    const skip = () => {
      window.sessionStorage.setItem('showRedirectDesktop', 0);
      emit('close');
    };

    return {
      fullVersionLink,
      skip
    };
  }
};
</script>
