import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';

const { apiRouts } = useApiRouts();

const state = reactive({
  keepPlaces: null,
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const currentKey = Object.entries({
    ...params
  })
    .flat()
    .sort()
    .join();
  if (currentKey === lastKey.value) return;
  try {
    state.loading = true;
    const url = (() => {
      return apiRouts.value.GET_KEEP_PLACES;
    })();
    const {
      data: { data: keepPlaces }
    } = await api.post(url, {
      id: url
    });
    state.keepPlaces = keepPlaces;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
