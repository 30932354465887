<template>
  <div>
    <flex class="py-10 px-10">
      <flex fill>
        <ui-input
          v-if="values"
          class="px-10 py-5"
          :placeholder="'ПОИСК ПО НОМЕРУ ИЛИ АДРЕСУ'"
          @update:modelValue="search"
        >
          <template #after>
            <div class="px-10">
              <icon name="search" :size="20" class="text-gray" @click="1" />
            </div>
          </template>
        </ui-input>
      </flex>
      <flex>
        <icon
          name="close"
          :size="20"
          class="text-gray px-10 py-5"
          @click="close"
        />
      </flex>
    </flex>

    <flex column class="full-height">
      <flex column fill class="overflow-auto">
        <ui-table
          v-if="ObjectsPage.length"
          :columns="gridSchema"
          :items="ObjectsPage[params.index]"
          :row-height="70"
          @rowclick="openObject($event)"
        />
      </flex>
      <flex class="px-20 py-10" justify-content="space-between">
        <flex align-items="baseline">
          <flex class="bold text-gray font-13">Стр:</flex>
          <flex class="pl-10">
            <ui-select
              base
              :modelValue="params.page"
              @update:modelValue="changeParams('page', $event)"
              :items="pagesCount"
            >
            </ui-select>
          </flex>
        </flex>
        <flex align-items="baseline">
          <flex class="bold text-gray font-13">На странице:</flex>
          <flex class="pl-10">
            <ui-select
              base
              :modelValue="params.limit"
              :items="[10, 50, 100]"
              @update:modelValue="changeParams('limit', $event)"
            />
          </flex>
        </flex>
      </flex>

      <router-view />
    </flex>
  </div>
</template>

<script>
import gridSchem from '@/schema/nto/map-grid.js';
import { computed, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

import useDefaultValues from '@/composition/useDefaultValues';
import useObjects from '@/composition/useSearch';

export default {
  setup(props, { emit }) {
    const route = useRoute();

    const { defaultValues } = useDefaultValues();

    const { objects, load: loadObjects } = useObjects();

    const loading = reactive({
      loading: false
    });
    const areaId = computed(
      () => parseInt(route.query.areaId) || defaultValues.value.AREA_ID
    );
    const districtId = computed(
      () => parseInt(route.query.districtId) || defaultValues.value.DISTRICT_ID
    );

    const gridSchema = reactive(gridSchem);

    const ObjectsMap = reactive([]);

    const ObjectsPage = ref([]);

    const params = ref({
      index: 0,
      page: 1,
      limit: 10
    });

    const pagesCount = computed(() => ObjectsPage.value.length || 1);

    const close = () => {
      clean();
      emit('close');
    };

    const values = reactive({
      value: null
    });

    const clean = () => {
      values.value = '';
      ObjectsMap.value = [];
      ObjectsPage.value = [];
      params.value = {
        index: 0,
        page: 1,
        limit: 10
      };
    };

    const search = async val => {
      if (val != null) {
        loadObjects({
          area_id: areaId.value,
          district_id: districtId.value,
          search: val
        }).then(() => {
          ObjectsMap.value = objects.value;
          setPagesItems();
        });
      }
    };

    const setPagesItems = () => {
      let size = params.value.limit;
      let array = ObjectsMap.value;
      ObjectsPage.value = [];
      for (let i = 0; i < Math.ceil(array.length / size); i++) {
        ObjectsPage.value[i] = array.slice(i * size, i * size + size);
      }
    };

    const openObject = obj => {
      emit('openObjectMap', obj);
      clean();
    };

    const changeParams = (param, val) => {
      if (param == 'page') {
        params.value.index = --val;
      }

      if (param == 'limit') {
        params.value.limit = val;
        setPagesItems();
      }
    };

    return {
      values,
      gridSchema,
      ObjectsMap,
      ObjectsPage,
      params,
      pagesCount,
      close,
      search,
      openObject,
      changeParams,
      loading
    };
  }
};
</script>

<style></style>
