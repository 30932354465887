<template>
  <button
    class="uiButton"
    :style="{ background: color }"
    :class="{ primary, small, large, load: loading }"
    v-bind="$attrs"
  >
    <slot v-if="!loading"></slot>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: String,
    primary: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.uiButton {
  border: 0;
  border-radius: 5px;
  background-color: $gray;
  color: white;
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
  outline: none;
  font-size: 12px;

  &:disabled {
    opacity: 0.5;
  }

  &.primary {
    background-color: $primary;
  }
  &.success {
    background-color: $green;
  }
  &.danger {
    background-color: $red;
  }

  &.small {
    line-height: 32px;
    height: 32px;
    // padding: 0 10px;
  }

  &.large {
    line-height: 50px;
    height: 50px;
    // padding: 0 20px;
  }

  &.load {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border: 3px solid white;
      border-top: 3px solid transparent;
      border-radius: 50%;
      animation: spin 0.6s linear infinite;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
