<template>
  <div>
    <div class="card_content">
      <div style="position: relative" v-for="(section, u) in data" :key="u">
        <div class="py-10" v-if="section.data._active != false">
          <div>
            <div
              class="card-title_ px-20"
              :class="{ 'border-tt': section.border_top }"
            >
              <div
                :class="{
                  opacity: !protocol.log.includes(getKey(section.key)) ? 1 : 0
                }"
                class="changes-inform mr-10"
              ></div>
              {{ section.title }}
            </div>
          </div>
          <div class="card_value px-20">
            <div
              v-if="section.data.template == 'card-files' || section.data.files"
            >
              <edit-files
                disabled
                :border_bottom="false"
                :proxyData="getFiles(section.key)"
                :uploadFolder="section.data.value"
              ></edit-files>
            </div>
            <div v-else-if="section.data.template == 'base'">
              <div v-if="'if' in section">
                <div v-if="section.data.value">{{ section.data.value }}</div>
              </div>
              <div v-else>
                {{ section.data.value }}
              </div>
            </div>
            <div v-else>
              {{ section.data.value }} {{ section.data.template }}
            </div>
          </div>
          <div
            class="card_params px-20"
            v-if="'params' in section && section.data._active"
          >
            <section-tree
              :currentLandId="currentLandId"
              :entity="entity"
              :sections="section.params"
              :protocol="protocol"
            ></section-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import objectHelpers from '@/composition/object/objectHelpers';

export default {
  name: 'section-tree',
  props: {
    currentLandId: {},
    entity: {},
    protocol: { type: Object },
    sections: { type: Array }
  },
  setup(props) {
    let data = computed(() => props.sections);

    const getFiles = value => {
      return objectHelpers.getFiles(props.protocol, value);
    };

    const getKey = k => {
      if (k.indexOf(' ') != -1) {
        return k.split(' ')[0];
      } else {
        if (k == 'protocol_type' || k == 'parent_offence_id') {
          k = false;
        }

        return k;
      }
    };

    data.value.forEach(section => {
      section.data = objectHelpers.changeValueProtocol(
        props.protocol,
        section,
        props.entity,
        props.currentLandId
      );
    });

    return { data, getFiles, getKey };
  }
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0;
}
.border-tt {
  border-top: unset !important;
}
.card_content {
  overflow: hidden;
}
.changes-inform {
  position: absolute;
  left: 0;
  width: 5px;
  margin-top: 5px;
  margin-left: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $primary;
}
</style>
