<template>
  <div>
    <modal full>
      <flex column class="">
        <iopr-header class="pt-20 px-20">
          <template #title>
            <div>
              <span class="bold  font-13 font-uppercase">
                {{ 'ПОДТВЕРДИТЕ ДЕЙСТВИЕ' }}
              </span>
            </div>
          </template>
          <template #action>
            <icon
              name="close"
              :size="20"
              class="text-gray"
              @click="$emit('close')"
            />
          </template>
        </iopr-header>
        <flex fill column class="block px-20 py-20">
          {{ text }}
        </flex>
        <flex class="py-10 controls">
          <flex fill class="px-10">
            <ui-button
              class="font-uppercase btn_smol_size"
              @click="$emit('close')"
              >Отменить</ui-button
            >
          </flex>
          <flex fill class="px-10">
            <ui-button
              class="font-uppercase btn_smol_size"
              @click="$emit('submit')"
              primary
              >{{ btnLabel }}</ui-button
            >
          </flex>
        </flex>
      </flex>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: 'Вы уверены, что хотите добавить объект в реестр?'
    },
    btnLabel: {
      default: 'подтверждаю'
    }
  }
};
</script>

<style lang="scss" scoped>
.block {
  overflow-wrap: break-word;
}

@media (max-width: 576px) {
  .btn_smol_size {
    font-size: 11px;
  }
}

.controls {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
