<template>
  <i :class="name" :style="{ fontSize: `${size}px`, color: clolor }"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    clolor: {
      defautl: null
    },
    size: {
      type: Number,
      default: 16
    }
  }
};
</script>

<style scoped></style>
