import { inject, computed, watch, onBeforeUnmount } from 'vue';

export default {
  props: {
    coord: {
      type: Array,
      required: true
    },
    fitToViewport: {
      type: Object,
      default() {
        return {};
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    properties: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const getYmaps = inject('getYmaps');
    const ymaps = getYmaps();

    const getMap = inject('getMap');
    const map = getMap();

    const fitToViewport = computed(() => {
      return Object.keys(props.fitToViewport).length;
    });

    const setBoundsToViewport = () => {
      const bounds = polygon.geometry.getBounds();
      map.setBounds(bounds, props.fitToViewport);
    };

    const onClick = event => {
      emit('click', event);
    };

    const polygon = new ymaps.Polygon(
      props.coord,
      props.properties,
      props.options
    );
    polygon.events.add('click', onClick);
    map.geoObjects.add(polygon);
    if (fitToViewport.value) setBoundsToViewport();

    const editorEvents = {
      vertexadd(event) {
        emit('vertexAdd', event);
      },
      vertexdragend(event) {
        emit('vertexDragEnd', event);
      }
    };

    const addEditorEvents = () => {
      Object.keys(editorEvents).forEach(event => {
        polygon.editor.events.add(event, editorEvents[event]);
      });
    };

    const removeEditorEvents = () => {
      Object.keys(editorEvents).forEach(event => {
        polygon.editor.events.add(event, editorEvents[event]);
      });
    };

    watch(
      () => props.coord,
      value => {
        polygon.geometry.setCoordinates(value);
        if (fitToViewport.value) setBoundsToViewport();
      }
    );

    watch(
      () => props.edit,
      value => {
        if (value) {
          polygon.editor.startEditing();
          polygon.editor.startDrawing();
          addEditorEvents();
        } else {
          polygon.editor.stopEditing();
          polygon.editor.stopDrawing();
          removeEditorEvents();
        }
      },
      {
        immediate: true
      }
    );

    onBeforeUnmount(() => {
      removeEditorEvents();
      polygon.events.remove('click', onClick);
      map.geoObjects.remove(polygon);
    });

    return () => null;
  }
};
