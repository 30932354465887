export default [
  {
    to: '/nto/objects',
    title: 'Объекты'
  },
  {
    to: '/nto/map',
    title: 'Карта'
  }
];
