import { reactive } from 'vue';

const state = reactive({
  opened: new Set()
});

const map = new Map();
const tree = [];
let idKey = 'id';
let pidKey = 'parent_id';

// счетчики проблемных объектов, которые порождают бесконечный цикл
let nodesWithoudId = 0;
let recursiveLinks = 0;

export const treeToFlat = data => {
  map.clear();
  tree.length = 0;
  data.forEach(node => {
    let nodeKey = node[idKey];
    if (typeof nodeKey === 'undefined' || nodeKey === null) {
      // отсутствует ключ
      nodesWithoudId++;
    } else {
      map.set(nodeKey, {
        node,
        id: nodeKey,
        lvl: 0,
        parent: null,
        children: []
      });

      if (node.is_open) {
        state.opened.add(nodeKey);
      }
    }
  });

  for (let item of map.values()) {
    if (!item.id) continue;
    let parent = map.get(item.node[pidKey]);

    if (!parent) {
      tree.push(item);
      continue;
    } else if (parent === item) {
      // рекурсивная ссылка
      recursiveLinks++;
      continue;
    }

    if (parent.lvl > 0) {
      item.lvl = parent.lvl + 1;
    } else {
      let lvl = 1;
      let parentOfParent = map.get(parent.node[pidKey]);
      let recursive = false;

      while (parentOfParent) {
        if (parentOfParent === item) {
          // циклическая рекурсивная ссылка
          recursive = true;
          break;
        }
        lvl += 1;
        parentOfParent = map.get(parentOfParent.node[pidKey]);
      }

      if (recursive) {
        recursiveLinks++;
        continue;
      }

      item.lvl = lvl;
      parent.lvl = lvl - 1;
    }

    item.parent = parent;
    parent.children.push(item);
  }

  if (nodesWithoudId > 0) {
    new Error(`У ${nodesWithoudId} объектов данных отсутствует ключ`);
  }
  if (recursiveLinks > 0) {
    new Error(
      `У ${recursiveLinks} объектов данных обнаружена рекурсивная ссылка на себя`
    );
  }

  const stack = [...tree];
  let list = [];

  while (stack.length) {
    let item = stack.shift();

    list.push(item);

    if (item.children.length > 0 && state.opened.has(item.node[idKey])) {
      stack.unshift(...item.children);
    }
  }

  return list;
};

export const cleanState = () => {
  state.opened = new Set();
};

export const setExpander = (id, value) => {
  value ? state.opened.add(id) : state.opened.delete(id);
};

export const hasOpen = id => {
  return state.opened.has(id);
};

export const hasChildren = id => {
  return map.get(id).children.length > 0;
};

export const setIdKey = value => {
  return (idKey = value);
};
export const setPidKey = value => {
  return (pidKey = value);
};
