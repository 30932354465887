<template>
  <div>
    {{ label }}
    <date-picker
      :placeholder="placeholder"
      :disabled="disabled"
      :id-attr="name"
      :name-attr="name"
      :class-attr="class_"
      :locale="locale"
      :value-attr="date"
      :year-minus="yearMinus"
      :from="from"
      :to="to"
      @update:modelValue="update($event)"
    ></date-picker>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    label: {},
    placeholder: {
      default: 'Выберите дату'
    },
    id: {},
    name: {},
    class_: {},
    modelValue: {
      default: ''
    },
    yearMinus: {},
    from: {},
    to: {},
    disabled: {}
  },
  setup(props, { emit }) {
    const locale = {
      format: '{1}.{2}.{3}',
      weekday: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    };

    const date = computed({
      get: () => {
        if (props.modelValue != null) {
          return props.modelValue.split('-').join('/');
        }
        return null;
      }
    });

    const update = event => {
      if (event) {
        emit('update:modelValue', event.split('/').join('-'));
      }
    };

    return { locale, date, update };
  }
};
</script>

<style></style>
