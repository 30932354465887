import { reactive, toRefs, ref } from 'vue';
import api from '@/api';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

const proxyData = ref([]);

const state = reactive({
  state_uploadFolder: null,
  inputValue: null
});

const queue = ref([]);
const uploadProgressPercent = ref(0);
const uploadError = ref(null);
const currentStatus = ref(STATUS_INITIAL);

const sync = (fieldName, fileList, uploadFolder) => {
  state.state_uploadFolder = uploadFolder;
  currentStatus.value = STATUS_SAVING;
  let formData = new FormData();

  formData.set('upload_folder_code', state.state_uploadFolder);
  if (!fileList.length) return;
  Array.from(Array(fileList.length).keys()).map(x => {
    queue.value.push({
      index: x,
      title: fileList[x].name
    });
    formData.append(`${fieldName}[${x}]`, fileList[x], fileList[x].name);
  });
  return save(formData);
};

const save = async formData => {
  currentStatus.value = STATUS_SAVING;
  return await upload(formData)
    .then(x => {
      x = x.filter((f, i) => queue.value.map(q => q.index).indexOf(i) >= 0);
      proxyData.value.concat(x);
      currentStatus.value = STATUS_SUCCESS;
      return x;
    })
    .catch(err => {
      uploadError.value = err.response;
      currentStatus.value = STATUS_FAILED;
    })
    .finally(() => {
      queue.value = [];
    });
};
const upload = async formData => {
  try {
    const url = '/upload';
    const { data: data } = await api.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      {
        id: 'upload',
        onUploadProgress: progressUpdate
      }
    );
    return data;
    // eslint-disable-next-line no-empty
  } finally {
    // ToDo: Если ты используешь try, то где обработка ошибок?
  }
};

// progress bar update
const progressUpdate = progressEvent => {
  uploadProgressPercent.value = 0;
  if (progressEvent.lengthComputable) {
    uploadProgressPercent.value = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
  }
};

// удаление загруженного файла
const remove = file => {
  proxyData.value.filter(item => item !== file);
};

export default () => {
  return {
    ...toRefs(state),
    uploadProgressPercent,
    state_uploadFolder: state.state_uploadFolder,
    queue,

    sync,
    save,
    upload,
    remove
  };
};
