<template>
  <flex
    class="radio-option"
    :class="{ button, small, large }"
    align-items="baseline"
  >
    <input type="radio" :id="id" v-bind="$attrs" v-model="model" />
    <label :for="id" :class="{ column }">
      <slot></slot>
    </label>
  </flex>
</template>

<script>
import { inject } from 'vue';

export default {
  inheritAttrs: false,
  setup() {
    const model = inject('model');
    const button = inject('button');
    const column = inject('column');
    const small = inject('small');
    const large = inject('large');

    const id = Math.random();

    return {
      id,
      model,
      button,
      column,
      small,
      large
    };
  }
};
</script>

<style lang="scss" scoped>
// .radio-option {
//   input,
//   label {
//     cursor: pointer;
//   }

//   //input:disabled ~ label {
//   //  opacity: 0.7;
//   //}

//   &.button {
//     &.small {
//       label {
//         height: 32px;
//       }
//     }

//     &.large {
//       label {
//         height: 50px;
//       }
//     }

//     label {
//       flex: 1;
//       display: flex;
//       cursor: pointer;
//       background-color: adjust-color($primary, $alpha: -0.9);
//       padding: 0 10px;
//       height: 40px;
//       align-items: center;
//       justify-content: center;
//       font-size: 13px;
//       line-height: 15px;
//       color: $gray;
//     }

//     input {
//       display: none;

//       &:checked + label {
//         background-color: $primary;
//         color: #fff;
//       }
//     }

//     &:first-child label {
//       border-radius: 5px 0 0 5px;
//     }

//     &:last-child label {
//       border-radius: 0 5px 5px 0;
//     }
//   }
// }

.radio-option {
  input,
  label {
    cursor: pointer;
  }

  // input:disabled ~ label {
  //   opacity: 0.7;
  // }

  &.button {
    &.small {
      label {
        height: 32px;
      }
    }

    &.large {
      label {
        height: 50px;
      }
    }

    label {
      flex: 1;
      display: flex;
      cursor: pointer;
      background-color: rgba(white, 0);
      padding: 0 10px;
      height: 40px;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      line-height: 15px;
      color: $gray;
    }

    input {
      display: none;

      &:checked + label {
        background-color: $primary;
        color: #fff;
      }
    }

    &:first-child {
      label {
        &.column {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:not(.column) {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }

    &:last-child {
      label {
        &.column {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &:not(.column) {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
</style>
