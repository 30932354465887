import app from '@/app.js';
import ioprHeader from './iopr-header.vue';
import ioprModuleIcon from './iopr-module-icon.vue';
import ioprNavbar from './iopr-navbar.vue';
import ioprUser from './iopr-user.vue';
import ioprFilters from './iopr-filters.vue';
import ioprStatusList from './iopr-status-list.vue';
import ioprWelcomeInfo from './iopr-welcome-info.vue';
import ioprProtocolsGrid from './iopr-protocols-grid.vue';
import ioprProtocolsGridRow from './iopr-protocols-grid-row.vue';

import ObjectCard from './object/ObjectCard.vue';

import ioprLegend from './iopr-legend.vue';
import ObjectMenu from './object/ObjectMenu.vue';
import confirmation from './object/confirmation.vue';
import ObjectHistory from './object/ObjectHistory.vue';
import ObjectProtocol from './object/ObjectProtocol.vue';
import ObjectProtocolGrid from './object/ObjectProtocolGrid.vue';
import ObjectReestr from './object/ObjectReestr.vue';
import ObjectReestrGrid from './object/object-reestr-grid.vue';
import ObjectOwner from './object/ObjectOwner.vue';
import SectionTree from './object/section-tree.vue';
import CardFiles from './object/card-files.vue';
import ObjectForm from './object/ObjectForm.vue';
import ObjectAbout from './object/object-about.vue';
import MapPlace from './object/map-place.vue';
import MapPlaceAsot from './object/map-place-asot.vue';
import eds from './object/eds.vue';
import cardRemoveObject from './object/card-remove-object';
import autoAddress from './object/auto-address';

app.component('ObjectReestrGrid', ObjectReestrGrid);
app.component('autoAddress', autoAddress);
app.component('confirmation', confirmation);
app.component('cardRemoveObject', cardRemoveObject);
app.component('eds', eds);
app.component('MapPlace', MapPlace);
app.component('MapPlaceAsot', MapPlaceAsot);
app.component('ObjectAbout', ObjectAbout);
app.component('ObjectForm', ObjectForm);
app.component('CardFiles', CardFiles);
app.component('SectionTree', SectionTree);
app.component('ObjectOwner', ObjectOwner);
app.component('ObjectProtocol', ObjectProtocol);
app.component('ObjectProtocolGrid', ObjectProtocolGrid);
app.component('ObjectHistory', ObjectHistory);
app.component('ObjectCard', ObjectCard);
app.component('ObjectMenu', ObjectMenu);
app.component('ObjectReestr', ObjectReestr);

app.component('iopr-legend', ioprLegend);
app.component('iopr-header', ioprHeader);
app.component('iopr-module-icon', ioprModuleIcon);
app.component('iopr-navbar', ioprNavbar);
app.component('iopr-user', ioprUser);
app.component('iopr-filters', ioprFilters);
app.component('iopr-status-list', ioprStatusList);
app.component('iopr-welcome-info', ioprWelcomeInfo);
app.component('iopr-protocols-grid', ioprProtocolsGrid);
app.component('iopr-protocols-grid-row', ioprProtocolsGridRow);
