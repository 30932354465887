export const moduleOptions = {
  icon: 'rk',
  alias: 'rk',
  name: `Демонтаж рекламных\nконструкций`
};

export const apiRouts = {
  GET_DISTRICTS: '/dict/rk/districts',
  GET_YEARS: '/rk/years',
  GET_MUNICIPAL_OBJECTS: '/rk/objects',
  GET_PRIVATE_OBJECTS: '/rk/private/objects',
  GET_OBJECT_LEGAL: '/rk/object-legal',
  GET_MUNICIPAL_OBJECT: '/rk/object',
  GET_PRIVATE_OBJECT: '/rk/private/object',
  GET_MUNICIPAL_STATUSES: '/rk/filters',
  GET_PRIVATE_STATUSES: '/rk/private/filters',

  GET_DIRECTORIES: '/ro/municipal/dictionaries',

  GET_OBJECT_HISTORY: '/rk/object/history',
  GET_PRIVATE_OBJECT_HISTORY: '/rk/private/object/history',
  GET_POLYGONS: '/dict/rk/polygon',
  GET_OBJECT_TREE: '/rk/map-object-tree',
  GET_MAP_OBJECTS: '/rk/objects-map',
  GET_MAP_AREAS: '/rk/map-area-list',

  GET_VIOLATION_TYPES: '/dict/rk/violations',
  GET_OBJECT_TYPES: '/dict/rk/objtypes',
  SAVE_OBJECT: '/rk/save',

  SAVE_OBJECT_PRIVATE: '/rk/private/save',
  GET_PROTOCOL: '/dict/rk/protocol/owner',
  REMOVE_OBJECT: '/rk/delete',
  REMOVE_OBJECT_FULL: '/rk/delete-full',

  SEARCH_MAP: '/rk/search-map',

  GET_LAND_USAGE_TYPES: '/dict/rk/lands',
  GET_KEEP_PLACES: '/dict/rk/keeps',

  REGISTER_OBJECT_PRIVATE: '/rk/private/register',
  REGISTER_OBJECT: '/rk/register'
};

export const defaultValues = {
  LAND_ID: 1,
  AREA_ID: 41,
  DISTRICT_ID: null,
  MUNICIPAL_STATUS_ID: 1,
  PRIVATE_STATUS_ID: 1
};
