import { reactive, toRefs } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';

const { apiRouts } = useApiRouts();

const state = reactive({
  years: [],
  loading: false
});

const load = async () => {
  if (state.years.length) return;
  try {
    state.loading = true;
    const {
      data: { data: years }
    } = await api.post(apiRouts.value.GET_YEARS);
    state.years = years.map(item => item.year);
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
