<template>
  <flex
    class="modal-subslot"
    :class="{ border_top: border_top, border_bottom: border_bottom }"
  >
    <div class="mt-15">
      <template v-if="!disabled">
        <div class="title-common mb-10">ФОТОГРАФИИ ОБЪЕКТА</div>
        <div class="disc_slot mb-10">
          Не более 10 файлов общим объемом <br />
          не более 10 Mb
        </div>
      </template>

      <div v-if="!uploadedFiles.length">----</div>

      <modal
        v-if="imgShow.show"
        style="border-radius: 5px !important;background-color:unset"
        :height="'75%'"
        class="mx-10 modal-expansion mt-40"
      >
        <icon
          style="position:absolute;top:5px;right:20px;color:white"
          name="close"
          :size="20"
          class="text-gray icon_close"
          @click="(imgShow = {}), (imgShow.show = false)"
        />
        <img
          class="img_show px-20"
          :src="imgShow.url"
          :alt="imgShow.title"
          loading="lazy"
        />
      </modal>

      <div class="row">
        <div class="modal-slot__photos">
          <div
            v-for="(item, z) in uploadedFiles"
            :key="z"
            class="modal-slot__photos-item"
          >
            <div
              v-if="(!disabled_edit || item.now) && !disabled"
              @click="remove(z)"
            >
              <icon class="closeImg" :clolor="'#fd6b6b'" :name="'close'"></icon>
            </div>
            <div @click="setShow(item)" class="modal-slot__photos-file">
              <img
                :src="item.preview_url || item.url"
                :alt="item.title"
                loading="lazy"
              />
            </div>
          </div>
          <template v-if="uploadFolder == state_uploadFolder">
            <div
              v-for="(item, i) in queue"
              :key="i"
              class="modal-slot__photos-item"
            >
              <flex class="modal-slot__photos-add">
                <div class="button_bord_blue btn_loading">
                  <div class="file-loading">
                    <div class="uploadProgressPercent">
                      {{ uploadProgressPercent }}%
                    </div>
                    <div class="file-loading__bar">
                      <div
                        v-bind:style="{ width: uploadProgressPercent + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </flex>
            </div>
          </template>
          <div v-if="!disabled" class="modal-slot__photos-item">
            <div class="modal-slot__photos-add" :class="{ disabled: disabled }">
              <input
                :id="uploadFolder"
                ref="uploadInput"
                type="file"
                multiple
                name="file"
                @change="change($event)"
                accept="image/*"
                class="input-file"
                :disabled="disabled"
                title=""
              />
              <div class="button_bord_blue btn_add_photo">
                <span>+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </flex>
</template>

<script>
import useEditUpload from '@/composition/useEditUpload';
import objectHelpers from '@/composition/object/objectHelpers';
import { computed, ref } from 'vue';

export default {
  name: 'EditPhotos',
  props: {
    border_top: { default: true },
    border_bottom: { default: true },
    disabled_edit: { default: false },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    uploadFolder: {},
    proxyData: {},
    disabledDeleteTooltipText: {
      type: String,
      default: 'Удаление невозможно'
    }
  },
  setup(props, { emit }) {
    const formatDate = value => {
      return value ? objectHelpers.formatDate(value) : '';
    };

    const {
      state_uploadFolder,
      uploadProgressPercent,
      queue,
      cancel,
      sync
    } = useEditUpload();

    const imgShow = ref({
      show: false
    });

    const setShow = item => {
      console.log(1);
      imgShow.value.show = true;
      imgShow.value.url = item.preview_url ? item.preview_url : item.url;
      imgShow.value.title = item.title;
    };
    const remove = i => {
      uploadedFiles.value.splice(i, 1);
      emit('update:modelValue', uploadedFiles.value);
    };
    /*
    const uploadedFiles = computed({
      get: () => {
        return uploadedFiles.value
          ? uploadedFiles.value.filter(
              item => item.folder_code === props.uploadFolder
            )
          : [];
      },
      set: item => {
        item.forEach(el => {
          uploadedFiles.value.push(el);
        });
      }
    });
    */

    const uploadedFiles = computed({
      get() {
        return props.proxyData;
      },
      set(data) {
        emit('update:proxyData', data);
      }
    });

    const change = async event => {
      await sync(
        event.target.name,
        event.target.files,
        props.uploadFolder
      ).then(a => {
        a.forEach(el => {
          if (el.folder_code == props.uploadFolder) {
            el.now = true;

            uploadedFiles.value.push(el);
          }
        });
        emit('update:modelValue', uploadedFiles.value);
      });
      const uploadInput = document.getElementById(props.uploadFolder);
      if (uploadInput) {
        uploadInput.value = '';
      }
    };

    return {
      setShow,
      imgShow,
      state_uploadFolder,
      change,
      formatDate,
      uploadedFiles,
      uploadProgressPercent,
      queue,
      cancel,
      sync,
      remove
    };
  }
};
</script>

<style lang="scss" scoped>
.img_show {
  margin-top: 30px;
  width: 100%;
  height: auto;
}
.closeImg:before {
  margin-left: 50px;
  margin-bottom: 10px;
}
.disc_slot {
  color: #7b898d;
}
.modal-slot__photos-add {
  height: 68px;
  width: 68px !important;
  margin-top: 10px;
  input {
    z-index: 100;
    height: 68px;
    width: 68px !important;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  align-items: center;
  justify-content: center;
  border: solid 1px #6d8bd0;
  border-radius: 5px;
  position: relative;
  display: inline-block;
}

.modal-slot__photos-item {
  height: 68px;
  width: 68px !important;
  margin-right: 10px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}

.modal-slot__photos-file {
  position: relative;
  width: 66px;
  height: 66px;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
    //position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    height: 100%;
  }
}

.title-common {
  color: #333333 !important;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}
.el-collapse-item__content {
  color: #808080;
}

.border_top {
  border-top: 1px solid #e8e8e8;
}
.modal-subslot {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
}

.row {
  display: inline-block;
}

.modal-slot__photos {
  .btn_loading {
    margin-top: 31px;
    height: 70px;
    width: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .file-loading {
      .uploadProgressPercent {
        top: 50%;
        left: 40%;
        position: absolute;
        text-align: center;
        color: #6d8bd0;
        line-height: 0px;
        font: normal normal bold 13px/16px;
        font-weight: 100;
      }
      .file-loading__bar {
        position: absolute;
        top: 40%;
        left: 20%;
        background: #e8e8e8 0% 0% no-repeat padding-box;
        margin-top: 20px;
        min-width: 40px;
        min-height: 8px;
        div {
          height: 8px;
          background: #808080;
        }
      }
    }
  }
  .btn_add_photo {
    margin-top: -2px;
    height: 70px;
    width: 70px !important;
    display: flex;
    justify-content: center;
    span {
      position: absolute;
      margin-top: 13px;
      margin-left: -2px;
      color: #6d8bd0;
      line-height: 0px;
      font-size: 26px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      font-weight: 100;
      width: 40px;
      height: 40px;
      border: 1px solid #6d8bd0;
      border-radius: 50%;
    }
  }
}
</style>
