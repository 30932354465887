import { reactive, toRefs } from 'vue';
import api from '@/api';

const state = reactive({
  user: null,
  loading: false
});

const checkPermission = permission => {
  if (!state.user) return false;
  return state.user.permissions.includes(permission);
};

const load = async () => {
  try {
    state.loading = true;
    const { data: user } = await api.post(process.env.VUE_APP_GET_USER_URL);
    state.user = user;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    checkPermission,
    load
  };
};
