<template>
  <div :style="{ ...position }" class="controls_zoom">
    <div @click="zoomIn" :style="btnStyle" class="btn_zoom">
      <i class="plus"></i>
    </div>
    <div @click="zoomOut" :style="btnStyle" class="btn_zoom s2">
      <i class="minus"></i>
    </div>
  </div>
</template>

<script>
import { inject, computed } from 'vue';
export default {
  props: {
    position: {
      type: Object,
      default: () => ({ left: '30px', bottom: '20px' })
    },
    size: {
      type: Number,
      default: 56
    }
  },
  setup(props) {
    const getMap = inject('getMap');
    const map = getMap();

    const zoomIn = () => {
      map.setZoom(map.getZoom() + 1, {
        checkZoomRange: true
      });
    };

    const zoomOut = () => {
      map.setZoom(map.getZoom() - 1, {
        checkZoomRange: true
      });
    };

    const btnStyle = computed(() => {
      return {
        width: `${props.size}px`,
        height: `${props.size}px`
      };
    });

    return {
      zoomIn,
      zoomOut,
      btnStyle
    };
  }
};
</script>

<style scoped lang="scss">
.controls_zoom {
  position: absolute;
  background: white;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 5px;

  .btn_zoom {
    font-size: 16px;
    text-align: center;
    color: #7b898d;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px !important;
  }
  .s2 {
    border-top: 1px solid #0000001a;
  }
}
</style>
