<template>
  <teleport to="body">
    <flex column class="modal">
      <flex column>
        <slot name="header"></slot>
      </flex>
      <flex fill column class="overflow-auto">
        <slot></slot>
      </flex>
      <flex column>
        <slot name="footer"></slot>
      </flex>
    </flex>
  </teleport>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: #fff;
}
</style>
