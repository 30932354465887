import { inject } from 'vue';

export default {
  props: {
    storageKey: {
      type: String,
      required: true
    },
    template: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const getYmaps = inject('getYmaps');
    const ymaps = getYmaps();

    const layout = ymaps.templateLayoutFactory.createClass(props.template);
    ymaps.layout.storage.add(props.storageKey, layout);

    return () => null;
  }
};
