//import useProtocol from '@/composition/useProtocol';
import helpers from '@/helpers';
import data from '@/schema/fields';
import useProtocol from '@/composition/useProtocol';
import useDirectories from '@/composition/useDirectories';
import useDirectoriesNTO from '@/composition/useDirectoriesNTO';

function getFild(data, index, key, value) {
  let v = data.filter(el => el[index] == key).pop();
  if (!v || v == undefined) {
    v = data.filter(el => el['nsi_dictionary_id'] == key).pop();
  }
  return v[value];
}

export default {
  getFiles(object, value) {
    let data = [];
    let files = [];

    if (object[value] != null && !Array.isArray(object[value])) {
      data = JSON.parse(object[value]);
    }

    data.forEach(el => {
      let f = object.files.filter(e => e.guid === el);
      if (f.length) {
        files.push(f.pop());
      }
    });
    return files;
  },

  objectTitle(object) {
    if (object != null) {
      if (!object.obj_registration_number) {
        return 'УЧЕТНЫЙ НОМЕР НЕ ПРИСВОЕН';
      } else {
        return `УЧ №  ${object.obj_registration_number}  от
                 ${this.changeValue(object, 'obj_registration_date')}`;
      }
    }
  },
  changeValue(ob, key, entity = null) {
    //легальные объекты
    if ('dogovor_num' in ob || 'certificate_num' in ob) {
      let change_field = null;
      if (key == 'address') {
        change_field = helpers.addressConcat({
          area: ob['area_name'],
          district: ob['district_name'],
          street: ob['address']
        });
      }

      if (ob[key] == (undefined || null)) {
        return '  -----';
      }

      return change_field || ob[key];
    }

    let SWITCH = false;
    const codes = ['object_functioning_mark'];

    const landUsageTypes = ['agreement_land_usage_type_id'];

    const keepPlaces = ['keep_place_id'];

    const objectTypes = ['obj_type_id'];

    const violationTypes = ['violation_type_id'];
    /*
    const owner_propety = [
      'object_functioning_mark_name',
      'mcs_mail_status_name',
      'mcs_actions_name',
      'beautification_rules_name',
      'beautification_rules_name_old',
      'full_status_name',
      'object_specialization_name',
      'object_type_name',
      'owner_object_opf_name',
      'owner_object_name',
      'owner_object_status_name',
      'owner_place_name',
      'owner_place_opf_name',
      'owner_place_status_name',
      'owner_position',
      'permitted_use_kind_name',
      'place_electric_networks_mail_status_name',
      'place_electric_networks_name',
      'place_electric_networks_status_name',
      'place_first_line_street_name',
      '',
      '',
      '',
      '',
      '',
      ''
    ];
    */

    const directoryList = [
      'object_type',
      'beautification_rules',
      'owner_object_status',
      'owner_object_opf',
      'owner_place_opf',
      'place_electric_networks',
      'place_electric_networks_status',
      'place_electric_networks_mail_status',
      'place_first_line_street',
      'mcs_mail_status',
      'mcs_actions',
      'object_specialization',
      'permitted_use_kind',
      'beautification_rules_old'
    ];

    const booles = [
      'owner_is_known',
      'warranty_measures_taken',
      'sec_remove_voluntary_is_done',
      'compliance_crosswalk_requirements',
      'compliance_fields_requirements',
      'compliance_kind_requirements',
      'compliance_house_territory_requirements',
      'compliance_facade_requirements',
      'compliance_cultural_heritage_requirements',
      'mark_dismantling',
      'forced_dismantling_mark',
      'fix_doc_result',

      'place_within_lane_of_road',
      'place_in_area_of_engineering_networks',
      'place_in_arches_of_buildings',
      'obstructs_passage',
      'place_in_the_houses_area',
      'place_with_violation_of_requirements',
      'place_without_type_permitted_use',
      'place_15m_to_facade',
      'object_issued_to_owner'
    ];

    const some_keys = [
      'address_keep',
      'address_district_id',
      'place_street&place_house'
    ];

    if (key == 'geo_polygon') {
      if (ob[key] == null) {
        return 'точка не установлена';
      }
    }

    if (key == 'polygon') {
      return data.getPolygon(ob.area_id);
    }

    if (key == 'polygon_center') {
      // return data.getPolygon(ob.area_id, true);
      return ob.geo_polygon?.[0]?.coordinates?.[0]?.[0];
    }
    if (ob[key] == (undefined || null) && !some_keys.includes(key)) {
      return '  -----';
    }
    const date = date => {
      return helpers.dateFormat(date);
    };

    const booleanFormat = (val, param = null) => {
      return helpers.booleanFormat(val, param);
    };

    if (key.indexOf('date') != -1) {
      return date(ob[key]);
    }

    if (booles.includes(key)) {
      key = booleanFormat(ob[key]);
    } else if (entity == 'asot') {
      if (directoryList.includes(key)) {
        key = getFild(data.getDirectories(), 'id', ob[key], 'name');
      } else if (landUsageTypes.includes(key)) {
        key = getFild(data.getDirectories(33), 'id', ob[key], 'name');
      } else if (keepPlaces.includes(key)) {
        key = getFild(data.keepPlaces(), 'id', ob[key], 'name');
      } else if (objectTypes.includes(key)) {
        key = getFild(data.getDirectories(4), 'id', ob[key], 'name');
      } else if (violationTypes.includes(key)) {
        key = getFild(data.getDirectories(32), 'id', ob[key], 'name');
      } else {
        SWITCH = true;
      }
    } else if (directoryList.includes(key)) {
      key = getFild(data.getDirectories(), 'id', ob[key], 'name');
    } else if (landUsageTypes.includes(key)) {
      key = getFild(data.landUsageTypes(), 'value', ob[key], 'text');
    } else if (keepPlaces.includes(key)) {
      key = getFild(data.keepPlaces(), 'value', ob[key], 'text');
    } else if (objectTypes.includes(key)) {
      key = getFild(data.objectTypes(), 'value', ob[key], 'text');
    } else if (violationTypes.includes(key)) {
      key = getFild(data.violationTypes(), 'value', ob[key], 'text');
    } else if (codes.includes(key)) {
      key = 'код не найден';
    } else {
      SWITCH = true;
    }

    if (key.indexOf('&') != -1) {
      let _keys = key.split('&');
      let field = '';
      _keys.forEach(k => {
        field += ob[k] || '';
        field += ' ';
      });
      return field;
    }

    if (SWITCH) {
      switch (key) {
        case 'address_district_id':
          if (!ob.area_name && !ob.district_name && !ob.address_street) {
            key = '----';
          } else {
            key = helpers.addressConcat({
              area: ob['area_name'],
              district: ob['district_name'],
              street: ob['address_street'],
              house: ob['address_house']
            });
          }
          break;

        case 'remove_voluntary_doc_number':
          key = `№ ${ob[key]}`;
          break;

        case 'remove_voluntary_is_done':
          key = booleanFormat(ob[key], {
            yes: 'демонтирован',
            no: 'Не демонтирован'
          });
          break;

        case 'move_data':
          if (ob.move_data.split(' ').length) {
            key = date(ob.move_data[0]);
          }
          break;
        default:
          key = ob[key];
          break;
      }
    }
    return key;
  },
  changeValueProtocol(ob, section, entity = null, currentLandId = null) {
    const { protocol } = useProtocol();
    const { directories } = useDirectories();

    const protocolTypes = [
      { id: 1, name: 'Новый протокол' },
      { id: 2, name: 'После доработки' },
      { id: 3, name: 'После обжалования' }
    ];

    const codes_courtDecision = [
      'decree_court_decision',
      'appeal_complaint_decision'
    ];

    const codes_ownerInfo = ['place_owner_person_info'];

    const codes_ownerStatus = ['place_owner_person_status'];

    const {
      allData,
      ownerStatus,
      ownerInfo,
      courtDecision
    } = useDirectoriesNTO();

    const booles = [
      'owner_is_know',
      'has_appeal_place_owner',
      'has_petition',
      'decree_court_exist',
      'appeal_complaint_exist',
      'has_appeal_object_owner',
      'has_ak_decree_complaint_object_owner',
      'has_ak_decree_complaint_place_owner'
    ];

    let _active = true;
    let type = 'string';
    let func = 'func' in section ? section.func : null;
    let files = 'files' in section ? true : false;
    let template = null;
    let key = section.key;
    let nsi_dictionary_id = null;

    if ('nsi_dictionary_id' in section) {
      nsi_dictionary_id = section.nsi_dictionary_id;
    }

    const codes = [
      'place_owner_person_info',
      'article_offence',
      'article_offence_basis',
      'place_owner_person_info',
      'place_owner_person_status',
      'world_judgment_decision',
      'decree_court_decision',
      'appeal_complaint_decision',
      //'appeal_complaint_decision_description',
      'penalty_payment_status_object_owner',
      'protocol_type',
      'article_offence_object_owner',
      'base_protocol_object_owner',
      'object_owner_person_info',
      'object_owner_person_status',
      'commission_decision_object_owner',
      'commission_decision_object_owner_reject',
      // 'commission_decision_object_owner_descr',
      'penalty_payment_status_object_owner',
      'base_protocol_place_owner',
      'article_offence_place_owner',
      'commission_decision_place_owner',
      //'commission_decision_place_owner_descr',
      'commission_decision_place_owner_reject',
      'ak_decree_complaint_court_place_owner',
      'ak_decree_complaint_decision_place_owner',
      'penalty_payment_status_place_owner',
      'voluntary_payment_status_place_owner',
      'ssp_enforcement_status_place_owner'
    ];

    const date = date => {
      if (date != null) {
        return helpers.dateFormat(date);
      } else {
        return '----';
      }
    };

    const booleanFormat = (val, variants = null) => {
      return helpers.booleanFormat(val, variants);
    };

    if (section.numberDate) {
      if (key.indexOf(' ') != -1) {
        let keys = key.split(' ');
        if (keys.length > 1) {
          keys.forEach(el => {
            if (el.indexOf('date')) {
              el = date(el);
            }
          });
          let number = ob[keys[0]] ? ob[keys[0]] : '  -----';
          let Date = ob[keys[1]] ? ob[keys[1]] : '  -----';

          key = `№ ${number} от ${Date}`;
          type = null;
        } else {
          key = null;
        }
      } else if (ob[key] == undefined) {
        return {
          value: '  -----',
          template: template
        };
      }
    }

    if (func) {
      let data_func = func(ob);
      if (data_func.value) {
        template = data_func.template;
      } else {
        template = data_func.template;
        key = data_func.value ? ob[data_func.value] : key;
        _active = false;
        // type = null;
      }
    }

    if (type == 'string') {
      if (key.indexOf('date') != -1) {
        key = date(ob[key]);
      } else {
        if (codes.includes(key)) {
          if (ob[key] != null) {
            if (entity == 'ro' || entity == 'asot') {
              key = getFild(directories.value, 'id', ob[key], 'name');
            } else {
              let datas = protocol.value;
              let val_k = 'nsi_dictionary_id';

              if (entity == 'rk') {
                if (key == 'place_owner_person_info') {
                  datas = protocol.value.info;
                  val_k = 'id';
                } else if (key == 'place_owner_person_status') {
                  datas = protocol.value.status;
                  val_k = 'id';
                }
              }

              if (entity == 'nto') {
                if (currentLandId == 2 && nsi_dictionary_id) {
                  datas = directories.value.filter(
                    el => el.nsi_dictionary_id == nsi_dictionary_id
                  );
                  val_k = 'id';
                } else if (codes_courtDecision.includes(key)) {
                  datas = courtDecision.value.data;
                  val_k = 'id';
                } else if (codes_ownerStatus.includes(key)) {
                  datas = ownerStatus.value;
                  val_k = 'id';
                } else if (codes_ownerInfo.includes(key)) {
                  datas = ownerInfo.value;
                  val_k = 'id';
                } else if (key == 'protocol_type') {
                  datas = protocolTypes;
                  val_k = 'id';
                } else {
                  datas = allData.value;
                  val_k = 'id';
                }
              }
              key = getFild(datas, val_k, ob[key], 'name');
            }
            //key = protocol.value.filter(el => el.nsi_dictionary_id == ob[key]).pop();
            key = key ? key : 'из базы';
          } else {
            key = '----';
          }
        } else if (booles.includes(key)) {
          key = booleanFormat(ob[key]);
        } else {
          switch (key) {
            case 'request_establishment_owner_result':
              key = booleanFormat(ob[key], {
                yes: 'Собственник установлен',
                no: 'Собственник не установлен'
              });
              break;

            default:
              key = ob[key];

              break;
          }
        }
      }
    }
    if (files) {
      key = section.key;
    }

    if (key == null) {
      key = '----';
    }

    if (section.key == 'amount_of_penalty') {
      key = Math.round(key);
    }

    return {
      value: key,
      files,
      template: template,
      _active: _active
    };
  }
};
