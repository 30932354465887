import { ref, computed } from 'vue';
import useDefaultValues from '@/composition/useDefaultValues';

const { defaultValues } = useDefaultValues();

const lands = ref([
  {
    id: 1,
    alias: 'municipal',
    name: 'Муниципальная',
    shortName: 'Муницип.'
  },
  {
    id: 2,
    alias: 'private',
    name: 'Частная',
    shortName: 'Част.'
  }
]);

const currentLandId = ref(defaultValues.value.LAND_ID);

const currentLand = computed(() => {
  return lands.value.find(land => land.id === currentLandId.value);
});

export default () => {
  return {
    lands,
    currentLandId,
    currentLand
  };
};
