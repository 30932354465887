export default {
  dateFormat(dateString) {
    // return new Date(dateString).toLocaleDateString('ru');

    if (dateString == null) {
      return '';
    }
    // safari fix
    const match = dateString.match(/\d{4}-\d{2}-\d{2}/) || [];
    dateString = match[0] || '';
    if (!dateString) return '';
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    mm = mm.toString();

    let dd = date.getDate();
    dd = dd.toString();

    if (mm.length < 2) {
      mm = 0 + mm;
    }
    if (dd.length < 2) {
      dd = 0 + dd;
    }

    return `${dd}.${mm}.${yyyy}`;
  },

  booleanFormat(value, variants = null) {
    if (value === true) return variants ? variants.yes : 'Да';
    if (value === false) return variants ? variants.no : 'Нет';
    return 'Нет';
  },

  addressConcat({ area, district, street, house }) {
    let address = '';
    address += (address && area ? ', ' : '') + (!area ? '' : area);
    address += (address && district ? ', ' : '') + (!district ? '' : district);
    address += (address && street ? ', ' : '') + (!street ? '' : street);
    address += address && house ? `, ${house}` : '';
    return address;
  }
};
