<template>
  <div class="object-protocol-grid">
    <iopr-protocols-grid :columns="columns">
      <iopr-protocols-grid-row
        v-if="schema.length > 1"
        :merge-columns="[
          { columns: ['date', 'number', 'status'], name: 'total' }
        ]"
        class="total"
      >
        <template #col-total>Итого</template>
        <template #col-penalty>
          {{ total('penalty').toFixed(2) }}
        </template>
        <template #col-penalty_payment>
          {{ total('penalty_payment').toFixed(2) }}
        </template>
      </iopr-protocols-grid-row>
      <iopr-protocols-grid-row
        v-for="(group, iGroup) in schema"
        :key="iGroup"
        :merge-columns="[
          { columns: ['date', 'number', 'status'], name: 'title' }
        ]"
        class="group"
      >
        <template #col-title>{{ group.title }}</template>
        <template #col-penalty>
          {{ summ(group.offence, group.props.penalty).toFixed(2) }}
        </template>
        <template #col-penalty_payment>
          {{ summ(group.offence, group.props.penalty_payment).toFixed(2) }}
        </template>
        <template #childrens>
          <iopr-protocols-grid-row
            v-for="(protocol, i) in group.offence"
            :key="i"
          >
            <template #col-date>
              {{ formatDate(protocol[group.props.date]) || '—' }}
            </template>
            <template #col-number>
              {{ protocol[group.props.number] || '—' }}
            </template>
            <template #col-status>
              {{ protocol[group.props.status] || '—' }}
            </template>
            <template #col-penalty>
              {{ protocol[group.props.penalty] || '—' }}
            </template>
            <template #col-penalty_payment>
              {{ protocol[group.props.penalty_payment] || '—' }}
            </template>
            <template #content>
              <slot :group="iGroup" :item="protocol"></slot>
            </template>
          </iopr-protocols-grid-row>
        </template>
      </iopr-protocols-grid-row>
    </iopr-protocols-grid>
  </div>
</template>

<script>
import helpers from '@/helpers';

export default {
  props: {
    schema: {
      type: Array, // Array of objects
      required: true
    }
  },
  data: () => ({
    columns: [
      {
        name: 'date',
        label: `Дата<br>протокола`,
        width: '18%'
      },
      {
        name: 'number',
        label: 'Номер<br>протокола',
        width: '14%'
      },
      {
        name: 'status',
        label: 'Статус<br>протокола',
        width: '16%'
      },
      {
        name: 'penalty',
        label: 'Сумма выставленного<br>штрафа, руб.',
        align: 'right',
        width: '22%'
      },
      {
        name: 'penalty_payment',
        label: 'Сумма оплаченного<br>штрафа, руб.',
        align: 'right',
        width: '22%'
      }
    ]
  }),
  methods: {
    summ(arr, prop) {
      return arr.reduce((r, item) => (r += parseFloat(item[prop]) || 0), 0);
    },
    total(prop) {
      return this.schema.reduce((res, group) => {
        return (res += this.summ(group.offence, group.props[prop]) || 0);
      }, 0);
    },
    formatDate(date) {
      return helpers.dateFormat(date);
    }
  }
};
</script>

<style lang="scss">
.object-protocol-grid {
  width: 740px;

  .iopr-protocols-grid {
    thead {
      tr {
        border-bottom: 3px solid #e8e8e8;

        th {
          color: rgba(128, 128, 128, 0.4);
          font-size: 13px;
          white-space: normal;
          word-break: normal;
          line-height: 15px;
          padding: 10px 0;

          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }
      }
    }

    tbody {
      .total {
        & > tr {
          background: #fff;
        }
      }

      .group {
        & > tr {
          background: #f2f2f2;
        }
        div:nth-child(odd) tr:first-child {
          background: rgba(242, 242, 242, 0.3);
        }
        tr:first-child:hover {
          background: rgba(245, 247, 250, 1) !important;
        }
      }

      .group,
      .total {
        & > tr {
          text-transform: uppercase;
          font-weight: 900;
        }
      }

      tr {
        border-bottom: 1px solid #e8e8e8;

        td {
          padding: 10px 0;

          &:last-child {
            padding-right: 20px;
          }
        }
      }

      .icon-edit {
        font-size: 15px;
      }
    }
  }
}
</style>
