<template>
  <textarea
    class="uiTextArea"
    :rows="rows"
    :class="{ small, large }"
    :placeholder="placeholder"
    v-bind="$attrs"
    v-model="model"
  />
</template>

<script>
import { computed } from 'vue';

export default {
  inheritAttrs: false,
  props: {
    rows: {},
    modelValue: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    },
    placeholder: {
      default: null
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    return {
      model
    };
  }
};
</script>

<style lang="scss" scoped>
.uiTextArea {
  font: normal normal normal 13px/15px Roboto;
  color: #333333;
  border: 1px solid scale-color($gray, $lightness: 85%);
  border-radius: 5px;
  background-color: white;
  width: 100%;
  line-height: 20px;
  padding: 10px 15px;
  outline: none;

  &:disabled {
    opacity: 0.7;
  }

  &.small {
    line-height: 32px;
    height: 32px;
    padding: 0 10px;
  }

  &.large {
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
  }
}
</style>
