<template>
  <div
    class="flex"
    :class="[columnClass, fillClass, alignItemsClass, justifyContentClass]"
  >
    <slot></slot>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'flex',
  props: {
    column: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    alignItems: {
      type: String
    },
    justifyContent: {
      type: String
    }
  },
  setup(props) {
    const columnClass = computed(() => (props.column ? `column` : ''));
    const fillClass = computed(() => (props.fill ? 'fill' : ''));
    const alignItemsClass = computed(() =>
      props.alignItems ? `align-items__${props.alignItems}` : ''
    );
    const justifyContentClass = computed(() =>
      props.justifyContent ? `justify-content__${props.justifyContent}` : ''
    );

    return {
      columnClass,
      fillClass,
      alignItemsClass,
      justifyContentClass
    };
  }
};
</script>
