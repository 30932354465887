import { reactive, toRef, computed } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';

const { apiRouts } = useApiRouts();

const state = reactive({
  list: [],
  loading: false,
  currentDistrict: null
});

const areas = computed(() => {
  return (
    state.list.filter(
      item => item.area_parent_id === null || item.area_parent_id === -1
    ) || []
  );
});

const getAreaById = areaId => {
  return state.list.find(item => item.area_id === areaId);
};

const districts = computed(() => {
  return areaId => {
    return state.list.filter(item => item.area_parent_id === areaId) || [];
  };
});

const load = async () => {
  if (state.list.length) return;
  try {
    state.loading = true;
    const {
      data: { data: list }
    } = await api.post(apiRouts.value.GET_DISTRICTS, {
      system_entry_point_id: 2
    });
    state.list = list;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    loading: toRef(state, 'loading'),
    areas,
    districts,
    currentDistrict: toRef(state, 'currentDistrict'),
    load,
    getAreaById
  };
};
