import app from './app.js';
// import './registerServiceWorker';

import './assets/icons/style.css';
import './assets/scss/main.scss';

import './components/iopr';
import './components/ui';
import './components/yandex-map';
import './components/map';

app.mount('#app');
