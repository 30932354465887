<template>
  <modal full>
    <iopr-header class="py-10 px-20 border-bottom">
      <template #title>
        <div>
          <span class="bold font-13 font-uppercase font-color-gray">
            укажите причину удаления</span
          >
        </div>
      </template>
      <template #action>
        <icon
          name="close"
          :size="20"
          class="text-gray"
          @click="$emit('close'), clean"
        />
      </template>
    </iopr-header>
    <div class="px-20 py-20">
      <flex class="mb-20">
        Для подтверждения удаления объекта укажите причину удаления,
        подтверждающий действие.
      </flex>
      <div v-if="!checkPermissionFull()">
        <ui-text-area
          v-model="statusDelete.comment"
          :placeholder="
            'Введите номер заявки на удаление в формате Ticket#123123 от ДД.ММ.ГГГГ'
          "
          :rows="4"
        ></ui-text-area>
        <edit-files
          titleDisabled
          :proxyData="statusDelete.files"
          @update:modelValue="update($event)"
          :uploadFolder="'delete_reason'"
          :tooltipText="'добавить служебную записку'"
        ></edit-files>
      </div>
      <div v-else>
        <ui-text-area
          v-model="statusDelete.comment"
          :placeholder="'Введите причину'"
          :rows="4"
        ></ui-text-area>
      </div>
    </div>
    <flex class="mt-20 controls_">
      <flex class="pr-10 pl-10" fill>
        <ui-button @click="$emit('close'), clean">отмена</ui-button>
      </flex>
      <flex class="pr-10" fill>
        <ui-button
          v-if="!checkPermissionFull()"
          :disabled="statusDelete.files.length < 1 || !statusDelete.comment"
          primary
          @click="$emit('action', statusDelete), clean"
          >подтвердить</ui-button
        >

        <ui-button
          v-else
          :disabled="!statusDelete.comment"
          primary
          @click="$emit('action', statusDelete), clean"
          >подтвердить</ui-button
        >
      </flex>
    </flex>
  </modal>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    permission: {}
  },
  setup(props) {
    const statusDelete = ref({
      comment: null,
      files: []
    });

    const update = ev => {
      if (!ev.length) {
        statusDelete.value.files = [];
      }
    };

    const clean = () => {
      statusDelete.value.comment = null;
    };

    const checkPermissionFull = () => {
      return props.permission.indexOf('full') != -1;
    };

    return {
      update,
      checkPermissionFull,
      statusDelete,
      clean
    };
  }
};
</script>

<style scoped>
.controls_ {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
}
</style>
