<template>
  <teleport to="body">
    <flex class="modal" align-items="center" justify-content="center">
      <div class="layout" @click="$emit('click-layout')"></div>
      <flex
        class="content"
        :class="{ full, fullHeight, fullWidth }"
        :style="{ width, maxWidth, height, maxHeight }"
        v-bind="$attrs"
        column
      >
        <slot></slot>
      </flex>
    </flex>
  </teleport>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    width: {
      type: String
    },
    maxWidth: {
      type: String
    },
    height: {
      type: String
    },
    maxHeight: {
      type: String
    },
    full: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.modal,
.layout {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.layout {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  background: black;
}
.content {
  position: absolute;
  background-color: white;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;

  &.full {
    width: 100%;
    height: 100%;

    &Height {
      height: 100%;
    }

    &Width {
      width: 100%;
    }
  }
}
</style>
