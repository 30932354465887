import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';
import useLands from '@/composition/useLands';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();

const state = reactive({
  history: [],
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const currentKey = Object.entries({
    ...params,
    landId: currentLandId.value
  })
    .flat()
    .sort()
    .join();
  if (currentKey === lastKey.value) return;
  try {
    state.loading = true;

    const url = (() => {
      if (currentLandId.value === 1) return apiRouts.value.GET_OBJECT_HISTORY;
      if (currentLandId.value === 2)
        return apiRouts.value.GET_PRIVATE_OBJECT_HISTORY;
    })();
    const { data } = await api.post(url, {
      guid: params.guid
    });
    state.history = data;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
