import { inject, watch, onBeforeUnmount, ref } from 'vue';

import { BalloonAppend, BalloonAppendDo } from './layouts/BalloonAppend';
import { defaultMapValues } from '@/components/yandex-map/data/defaultValues';
import { ymap } from '@/components/yandex-map/map';

export default {
  props: {
    areaId: {},
    entity: {
      type: String,
      default: null
    },
    place: {
      type: Array,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    fitToViewport: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    polygon: {
      type: Boolean,
      default: false
    },
    districtId: {},
    disabled: {
      type: Boolean,
      default: false
    },
    ballon: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const getYmaps = inject('getYmaps');
    const ymaps = getYmaps();

    const getMap = inject('getMap');
    const map = getMap();
    const remove = ref(null);

    const currentPolygon = ref({});

    let mainData = {
      placemarkObj: null,
      mainCollection: {}
    };
    mainData.mainCollection = new ymaps.GeoObjectCollection();

    const setBoundsToViewport = () => {
      if (props.items.length) {
        const bounds = objectManager.getBounds();
        map.setBounds(bounds);
      }
    };

    const LayoutFactory = layout => {
      return ymaps.templateLayoutFactory.createClass(
        layout.template,
        layout.config
      );
    };

    const addMainObjectLayout = coords => {
      const addPoint = () => {
        let ballon = LayoutFactory(BalloonAppend);

        if (props.entity == 'ro') ballon = LayoutFactory(BalloonAppendDo);

        let options = defaultMapValues.placeMark.options(ballon);

        if (!props.ballon) {
          options.hasBalloon = false;
        }

        mainData.placemarkObj = new ymaps.Placemark(
          coords,
          {
            name: 'Добавить'
          },
          options
        );

        mainData.mainCollection.add(mainData.placemarkObj);
        map.geoObjects.add(mainData.mainCollection);

        if (!props.disabled && props.ballon) {
          mainData.placemarkObj.balloon.open();
        }
      };

      if (!map.balloon.isOpen()) {
        remove.value = false;
        isDrowPoint();

        if (props.place) {
          addPoint();
        }
        if (!props.place && !remove.value) {
          addPoint();
        }
        ymap.setMainData(mainData);
      } else {
        isDrowPoint();
        map.balloon.close();
      }
    };

    const isDrowPoint = () => {
      if (mainData.placemarkObj != null) {
        mainData.mainCollection.remove(mainData.placemarkObj);
        mainData.placemarkObj = null;
        remove.value = true;
      }
    };

    const objectManager = new ymaps.ObjectManager(props.options);

    objectManager.add({
      type: 'FeatureCollection',
      features: props.items
    });
    map.geoObjects.add(objectManager);
    const onClickPoint = event => {
      event.MAP = map.action.getCurrentState();
      emit('click', event);
    };

    const onClickDict = event => {
      var objectId = event.get('objectId');
      var overlay = objectManager.objects.overlays.getById(objectId);

      if (
        (props.districtId || overlay._data.properties.areaId != -1) &&
        !props.place
      ) {
        if (overlay._data.properties.areaId != -1) {
          addMainObjectLayout(event.get('coords'));
          event.MAP = map.action.getCurrentState();
          emit('addPoint', event);
        }
      } else if (
        /*currentPolygon.value == id && */
        props.place
      ) {
        addMainObjectLayout(event.get('coords'));
        emit('click-dict', event);
      } else {
        if (props.districtId == null) {
          emit('click-dict', event);
        }
      }
      currentPolygon.value = props.districtId;
    };

    const addPlace = () => {
      addMainObjectLayout(props.place);
    };

    watch(
      () => props.place,
      () => {
        addMainObjectLayout(props.place);
      }
    );

    if (props.place) {
      addPlace();
    }

    if (props.items.length && !props.disabled) {
      switch (props.items[0].geometry.type) {
        case 'Polygon':
          objectManager.objects.events.add('click', onClickDict);
          break;

        case 'Point':
          objectManager.objects.events.add('click', onClickPoint);
          break;
      }
      objectManager.clusters.events.add('add', function(e) {
        var cluster = objectManager.clusters.getById(e.get('objectId'));
        objectManager.clusters.setClusterOptions(cluster.id, {
          gridSize: 160
        });
      });
    }

    watch(
      () => props.items,
      value => {
        objectManager.removeAll();

        if (props.items.length) {
          switch (props.items[0].geometry.type) {
            case 'Polygon':
              props.items.forEach(() => {
                objectManager.add({
                  type: 'FeatureCollection',
                  features: value
                });
              });
              break;

            default:
              objectManager.add({
                type: 'FeatureCollection',
                features: value
              });
              break;
          }
          if (props.fitToViewport) {
            setTimeout(setBoundsToViewport, 0);
          }
        }
      }
    );

    onBeforeUnmount(() => {
      objectManager.removeAll();
      objectManager.objects.events.remove('click', onClickPoint);
      objectManager.objects.events.remove('click', onClickDict);
      map.geoObjects.remove(objectManager);
    });

    return () => null;
  }
};
