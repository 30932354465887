import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from '@/composition/useApiRouts.js';
import useLands from '@/composition/useLands';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();

const state = reactive({
  tree: [],
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const currentKey = Object.entries({
    ...params,
    landId: currentLandId.value
  })
    .flat()
    .sort()
    .join();

  if (currentKey === lastKey.value) return;
  try {
    state.loading = true;
    const url = (() => {
      return apiRouts.value.GET_OBJECT_TREE;
    })();
    const {
      data: { data: tree }
    } = await api.post(url, {
      area_list: params.areaId,
      district_list: params.districtId,
      system_entry_point_id: 2
    });
    state.tree = tree;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
