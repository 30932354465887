<template>
  <flex
    column
    v-for="(field, i) in dataFields"
    :id="`${uniqId}_${i + 1}`"
    :key="i + 1"
    :class="field.fieldClass"
  >
    <template v-if="('_if' in field && field._if(data)) || !('_if' in field)">
      <div>
        <flex v-if="field.label && $slots.label" class="label">
          <slot class="font-uppercase" name="label" :text="field.label"></slot>
        </flex>
        <flex column :data-parent="`${uniqId}_${i + 1}`" :id="field.property">
          <component
            :is="field.component"
            v-bind="field.componentAttrs"
            :modelValue="data[field.property]"
            @update:modelValue="updateProperty(field, $event)"
          />
        </flex>
        <slot name="required" :property="field.property"></slot>
      </div>
    </template>
  </flex>
</template>

<script>
import { computed, watch } from 'vue';
import formHelpers from '@/composition/object/formHelpers';

export default {
  props: {
    uniqId: { default: 0 },
    cleanForm: {},
    modelValue: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: [Array, Object],
      default: () => []
    },
    validation: { type: Object },
    requiredFields: {}
  },
  setup(props, { emit }) {
    const requiredFieldsData = computed({
      get() {
        return props.requiredFields;
      },
      set(data) {
        emit('update:requiredFields', data);
      }
    });

    const data = computed({
      get() {
        return props.modelValue;
      },
      set(data) {
        emit('update:modelValue', data);
      }
    });

    const setParams = (field, value) => {
      data.value = {
        ...data.value,
        [field.property]: value
      };
    };

    const dataFields = computed({
      get() {
        return props.fields;
      },
      set(data) {
        emit('update:fields', data);
      }
    });

    watch(
      () => props.clean,
      () => {
        //data.value = {};
      }
    );

    const updateProperty = (field, value) => {
      new Promise(resolve => {
        if (typeof value == 'object' && value != null) {
          if ('init' in value) {
            dataFields.value = value.init(dataFields, field);
            if ('data' in value) {
              data.value = {
                ...data.value,
                ...value.data()
              };
            }
          } else {
            setParams(field, value);
          }
        } else {
          setParams(field, value);
        }
        resolve();
      }).then(() => {
        if (props.validation) {
          checkFields(dataFields.value, {
            field: field.property,
            value: value,
            required: 'required' in field ? field.required : false,
            component: field.component
          });
          dataFields.value = props.validation.init(
            field,
            dataFields.value,
            value
          );
        }
      });
      if (field.onChange) field.onChange(value);
    };

    //проверяет поля на заполнение
    const checkFields = (fields, prop) => {
      for (var key in data.value) {
        if (prop.required && prop.value) {
          prop.required.forEach(el => {
            if (key == el) {
              requiredFieldsData.value.unshift(el);
              formHelpers.searchEl(
                requiredFieldsData.value,
                key,
                el,
                fields,
                data,
                prop,
                true
              );
            }
          });
        } else if (prop.required && !prop.value) {
          formHelpers.searchEl(
            requiredFieldsData.value,
            key,
            key,
            fields,
            data,
            prop,
            true
          );
          prop.required.forEach(el => {
            if (key == el) {
              let iNDEX = requiredFieldsData.value.find(l => {
                return l == el;
              });
              if (iNDEX != -1 && el != 'violation_type_id') {
                requiredFieldsData.value.splice(iNDEX, 1);
              }
              prop.value = 'white';

              props.fields.forEach(ell => {
                if (ell.property == key) {
                  prop.field = ell.property;
                  prop.component = ell.component;
                  delete prop.required;
                }
              });
              formHelpers.searchEl(
                requiredFieldsData.value,
                key,
                el,
                fields,
                data,
                prop,
                true
              );
            }
            /* formHelpers.searchEl(
              requiredFieldsData.value,
              key,
              prop.field,
              fields,
              data,
              prop
            );*/
            /* let iNDEX = requiredFieldsData.value.find(l => {
              return l == el;
            });
            if (iNDEX != -1) {
              //requiredFieldsData.value.splice(iNDEX, 1);
            }*/
          });
        } else {
          formHelpers.searchEl(
            requiredFieldsData.value,
            key,
            prop.field,
            fields,
            data,
            prop
          );
        }
      }
    };

    return {
      data,
      dataFields,
      updateProperty
    };
  }
};
</script>

<style lang="scss">
input[disabled],
select[disabled],
textarea[disabled] {
  background-color: #e4e4e4 !important;
}
</style>
