const options = {
  v: process.env.VUE_APP_YANDEX_MAP_VER,
  lang: 'ru_RU',
  coordorder: 'longlat',
  apikey: process.env.VUE_APP_YANDEX_MAP_KEY
};

let loading = false;

let wait = [];

export default () => {
  return new Promise((resolve, reject) => {
    if (window.ymaps) return resolve(window.ymaps);
    if (!loading) {
      loading = true;
      const scriptTag = document.createElement('script');
      scriptTag.onload = () => {
        window.ymaps.ready(() => {
          loading = false;
          wait.forEach(item => {
            item.resolve(window.ymaps);
          });
          wait = [];
          resolve(window.ymaps);
        });
      };
      scriptTag.onerror = () => {
        loading = false;
        const error = new Error('Ошибка загрузки');
        wait.forEach(item => {
          item.reject(error);
        });
        wait = [];
        reject(error);
      };
      const baseUrl = 'https://enterprise.api-maps.yandex.ru';
      scriptTag.setAttribute(
        'src',
        `${baseUrl}/${options.v}?apikey=${options.apikey}&lang=${options.lang}&coordorder=${options.coordorder}`
      );
      document.body.appendChild(scriptTag);
    } else {
      wait.push({
        resolve,
        reject
      });
    }
  });
};
