<template>
  <div>
    <flex
      fill
      class="px-20 py-10"
      justify-content="space-around"
      align-items="end"
    >
      <flex class="search-control">
        <icon
          name="search"
          :size="20"
          class="text-gray mr-20"
          @click="$emit('searchmap')"
        />
        <icon
          name="filter"
          :size="20"
          class="text-gray"
          @click="$emit('filtersmap', 'filter')"
        />
      </flex>
    </flex>

    <modal full v-if="isOpenLegend" class="filters">
      <flex column>
        <iopr-header class="py-10 px-20">
          <template #title>
            <span class="font-uppercase font-bold font-size-13">
              легенда и статистика
            </span>
          </template>
          <template #action>
            <icon
              name="close"
              :size="20"
              class="text-gray"
              @click="(isOpenLegend = false), $emit('legendMap', false)"
            />
          </template>
        </iopr-header>
      </flex>

      <flex fill class="border-top overflow-auto">
        <iopr-legend
          :list="sections"
          :parent-id="parentId"
          @open-object="openObj"
          @filter-objects="filterObjects"
        >
        </iopr-legend>
      </flex>
    </modal>

    <transition>
      <flex
        class="flex-center"
        align-items="center"
        v-if="!isOpenLegend"
        id="show-legend"
        @click="isOpenLegend = 1"
      >
        <p>Показать легенду и статистику</p>
      </flex>
    </transition>
  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  props: {
    sections: {
      default: []
    },
    area: {}
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const thumbler = reactive({
      legendOpen: false
    });

    const openObj = obj => {
      //thumbler.legendOpen = !thumbler.legendOpen;
      emit('openObject', obj);
    };

    const filterObjects = val => {
      emit('filtersMap', val);
    };

    const filtersMap = val => {
      emit('filters-map', val);
    };

    const parentId = computed(() =>
      parseInt(route.query.districtId) && !parseInt(route.query.districtsAll)
        ? parseInt(route.query.districtId)
        : props.area
    );

    const isOpenLegend = computed({
      get() {
        return Boolean(route.query.legend);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            legend: value
          }
        });
      }
    });

    const district = computed(() => {
      if (
        parseInt(route.query.districtId) &&
        !parseInt(route.query.districtsAll)
      ) {
        let d = props.sections.filter(
          el => el.id == parseInt(route.query.districtId)
        );
        return d.length ? d[0].name : false;
      }
      return false;
    });

    return {
      district,
      parentId,
      filtersMap,
      thumbler,
      openObj,
      isOpenLegend,
      filterObjects
    };
  }
};
</script>

<style lang="scss" scoped>
.district {
  border-left: #6d8bd0 5px solid;
  padding-left: 16px;
  height: 50px;
}
#map-panel {
  height: 70px;
  margin: auto;
  display: inline-block;
  i {
    color: #7b898d;
    margin-right: 20px;
  }
}
.header {
  padding-top: 8px;
  padding-bottom: 8px;

  .empty {
    visibility: hidden;
  }

  .icon.close {
    transform: rotate(-90deg);
  }

  .count {
    color: $gray;
  }

  &.selected {
    background: $primary;
    color: #fff;

    .count {
      color: #fff;
    }
  }
}
#show-legend {
  p {
    margin-left: 30px;
  }
  cursor: pointer;
  text-align: left;
  position: absolute;
  height: 70px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  background: #ffffff 0% 0% no-repeat padding-box;
  p {
    color: #6d8bd0;
    opacity: 1;
  }
}

.flex-center {
  display: flex;
  flex: 1;

  & > * {
    flex: 1;
    text-align: center;
  }
}
</style>
