import { createRouter, createWebHistory } from 'vue-router';
import useAuth from '@/composition/useAuth.js';
import useUser from '@/composition/useUser.js';
import useApiRouts from '@/composition/useApiRouts.js';
import useDefaultValues from '@/composition/useDefaultValues.js';
import nto from './nto.js';
import asot from './asot.js';
import rk from './rk.js';
import ro from './ro.js';

import modulesSchema from '@/schema/modules';

const routes = [
  {
    path: '/',
    alias: '/welcome',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules.vue'),
    props: () => {
      return {
        modulesSchema
      };
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue')
  },
  ...nto,
  ...asot,
  ...ro,
  ...rk
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (savedPosition === null) {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

router.beforeEach(async to => {
  const { access_token: token, logout } = useAuth();
  const { user, load: loadUser, checkPermission } = useUser();
  const { apiRouts } = useApiRouts();
  const { defaultValues } = useDefaultValues();

  const module = (to.fullPath.match(/nto|rk|asot|ro/) || [])[0];

  apiRouts.value = {};
  defaultValues.value = {};
  if (to.meta.apiRouts) {
    apiRouts.value = to.meta.apiRouts;
  }
  if (to.meta.defaultValues) {
    defaultValues.value = to.meta.defaultValues;
  }

  if (to.name !== 'login' && !token.value) {
    logout();
    return '/login';
  }
  if (to.name !== 'login' && token.value && !user.value) {
    try {
      await loadUser();
    } catch (error) {
      alert(error.message);
      logout();
      return '/login';
    }
  }
  if (module && !checkPermission(`${module}.module`)) return '/login';
  if (to.name === 'login' && token.value) {
    return '/';
  }
});

export default router;
