export const defaultMapValues = {
  point: (id, item, entity) => {
    let coordinates;

    if (entity == 'asot') {
      coordinates = JSON.parse(JSON.parse(item.geo_polygon).pop())
        .coordinates.pop()
        .pop();
    } else {
      //coordinetes = JSON.parse(item.geo_polygon).coordinates
      coordinates = JSON.parse(item.geo_polygon);
    }
    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'Point',
        coordinates
      },
      properties: {
        property_type_id: item.property_type_id,
        violation_type_id: item.violation_type_id,
        data: item
      },
      options: {
        iconLayout: `placemark#violation`,
        iconShape: {
          type: 'Circle',
          coordinates: [0, 0],
          radius: 20
        }
      }
    };
  },
  polygon: obj => {
    return {
      type: 'Feature',
      id: obj.id,
      geometry: {
        type: 'Polygon',
        coordinates: obj.geometry.coordinates
      },
      properties: {
        ...obj.properties
      },
      options: {
        fillColor: '#6DA8CD',
        fillOpacity: 0.1,
        strokeColor: '#707070',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeWidth: 1.5
      }
    };
  },
  placeMark: {
    options: layout => {
      return {
        openBalloonOnClick: false,
        hideIconOnBalloonOpen: false,
        balloonContentLayout: layout,
        balloonPanelMaxMapArea: 0,
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        iconImageHref: '/img/icons/point.png',
        iconImageSize: [9, 9],
        iconImageOffset: [-3, 2],
        closeButton: false
      };
    }
  }
};
