<template>
  <flex column>
    <router-link
      v-for="({ to, title }, i) in schema"
      :key="i"
      :to="to"
      active-class="active"
      custom
      v-slot="{ href, isActive }"
    >
      <a
        :href="href"
        @click.prevent="navigate(href)"
        class="iopr-nav-link px-20"
        :class="{ active: isActive }"
      >
        {{ title }}
      </a>
    </router-link>
  </flex>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    schema: {
      type: Array,
      required: true
    },
    beforeNavigate: {
      type: Function,
      default: next => next()
    },
    queryParams: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const router = useRouter();

    const navigate = href => {
      props.beforeNavigate(() => {
        router.replace({
          path: href,
          query: props.queryParams
        });
      });
    };

    return { navigate };
  }
};
</script>

<style lang="scss" scoped>
.iopr-nav-link {
  height: 50px;
  background: white;
  color: $gray;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  border-top: 1px solid scale-color($gray, $lightness: 85%);

  &.active {
    position: relative;
    color: black;

    &:after {
      content: '';
      background: $primary;
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }
  }
}
</style>
