<template>
  <select
    :placeholder="placeholder"
    class="uiSelect"
    v-model="model"
    :class="{ slim, large, small }"
  >
    <slot>
      <template v-if="options">
        <optgroup v-for="(header, i) in items" :key="i" :label="header.text">
          <option
            v-for="(item, x) in header.options"
            :key="x"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </optgroup>
      </template>
      <template v-else-if="base">
        <option v-for="(item, i) in items" :key="i" :value="item">
          {{ item }}
        </option>
      </template>
      <template v-else>
        <option v-for="(item, i) in items" :key="i" :value="item.value">
          {{ item.text }}
        </option>
      </template>
    </slot>
  </select>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    base: Boolean,
    modelValue: {
      type: [String, Number]
    },
    placeholder: {},
    items: {},
    options: Boolean,
    slim: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    return {
      model
    };
  }
};
</script>

<style lang="scss" scoped>
.uiSelect {
  border: 1px solid scale-color($gray, $lightness: 85%);
  border-radius: 5px;
  background-color: white;
  width: 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 15px;
  outline: none;
  font-size: 13px;
  font-family: 'Roboto';

  &:disabled {
    opacity: 0.7;
  }

  &.small {
    line-height: 32px;
    height: 32px;
    padding: 0 10px;
  }

  &.large {
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
  }

  &.slim {
    padding: 0;
    border: 0;
    height: auto;
    line-height: normal;
  }
}
</style>
