<template>
  <flex column class="iopr-header">
    <flex align-items="center">
      <flex v-if="$slots.icon">
        <slot name="icon"></slot>
      </flex>
      <flex fill v-if="$slots.title" class="title font-size-13">
        <slot name="title"></slot>
      </flex>
      <flex v-if="$slots.action">
        <slot name="action"></slot>
      </flex>
    </flex>
    <slot name="about" :toggle="toggleAbout" :isOpen="showAbout"></slot>
  </flex>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const showAbout = ref(false);
    const toggleAbout = () => {
      showAbout.value = !showAbout.value;
    };

    return {
      showAbout,
      toggleAbout
    };
  }
};
</script>
