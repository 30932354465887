import helpers from '@/helpers';

export default [
  {
    name: '',
    width: 50,
    value: object => {
      switch (object.system_entry_point_name) {
        case 'desktop':
          return '';
        case 'mobile':
          return '<i class="smartphone font-color-primary font-size-20"></i>';
      }
    }
  },
  {
    name: 'Создан',
    width: 100,
    value: object => helpers.dateFormat(object.creation_date)
  },
  {
    name: 'Уч. №',
    width: 70,
    value: object => object.obj_registration_number
  },
  {
    name: 'На учет',
    width: 90,
    value: object => helpers.dateFormat(object.obj_registration_date)
  },
  {
    name: 'Район',
    width: 150,
    value: object => object.district_name
  },
  {
    name: 'Адрес',
    width: 250,
    value: object => {
      return helpers.addressConcat({
        street: object.address_street,
        house: object.address_house
      });
    }
  }
];
