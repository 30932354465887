// import AxiosProvider from './providers/axios';
import FetchProvider from './providers/fetch';
import Api from './api';
import useAuth from '@/composition/useAuth';

const provider = new FetchProvider({
  baseURL: process.env.VUE_APP_BASE_API_URL
});

provider.requestInterceptor = config => {
  const { access_token } = useAuth();
  config.headers['Authorization'] = `Bearer ${access_token.value}`;
  return config;
};

provider.responseErrorInterceptor = response => {
  if (response.status === 401) {
    const { logout } = useAuth();
    logout();
  }
};

export default new Api({
  provider
});
