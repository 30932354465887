export default [
  {
    to: '/asot/objects',
    title: 'Объекты'
  },
  {
    to: '/asot/map',
    title: 'Карта'
  }
];
