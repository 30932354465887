export const moduleOptions = {
  icon: 'asot',
  alias: 'asot',
  name: `Демонтаж\nавтостоянок`
};

export const apiRouts = {
  GET_DISTRICTS: '/asot/directory/districts',
  GET_YEARS: '/asot/private/years',
  GET_MUNICIPAL_OBJECTS: '/asot/municipal/objects',
  GET_PRIVATE_OBJECTS: '/asot/private/objects',
  GET_MUNICIPAL_OBJECT: '/asot/municipal/object',
  GET_PRIVATE_OBJECT: '/asot/private/object',
  GET_MUNICIPAL_STATUSES: '/asot/municipal/filters',
  GET_PRIVATE_STATUSES: '/asot/private/filters',

  GET_OBJECT_HISTORY: '/asot/municipal/history',
  GET_PRIVATE_OBJECT_HISTORY: '/asot/private/history',
  GET_POLYGONS: '/dict/asot/polygon',
  GET_OBJECT_TREE: '/asot/map-object-tree',
  GET_MAP_OBJECTS: '/asot/objects-map',
  GET_MAP_AREAS: '/asot/map-area-list',

  GET_PRIVATE_DIRECTORIES: '/asot/private/dictionaries',

  GET_MUNICIPAL_DIRECTORIES: '/asot/municipal/dictionaries',
  GET_VIOLATION_TYPES: '/dict/asot/violations',
  GET_OBJECT_TYPES: '/dict/asot/objtypes',
  SAVE_OBJECT: '/asot/municipal/save',

  SAVE_OBJECT_PRIVATE: '/asot/private/save',
  GET_PROTOCOL: '/asot/municipal/dictionaries',

  SEARCH_MAP: '/asot/map/search',

  REMOVE_OBJECT: '/asot/municipal/delete',
  REMOVE_OBJECT_FULL: '/asot/municipal/delete-full',

  REMOVE_OBJECT_PRIVATE: '/asot/private/delete',
  REMOVE_OBJECT_FULL_PRIVATE: '/asot/private/delete-full',

  GET_LAND_USAGE_TYPES: '/dict/asot/lands',
  GET_KEEP_PLACES: '/asot/directory/keeps',

  REGISTER_OBJECT_PRIVATE: '/asot/private/register',
  REGISTER_OBJECT: '/asot/municipal/register'
};

export const defaultValues = {
  LAND_ID: 1,
  AREA_ID: 41,
  DISTRICT_ID: null,
  MUNICIPAL_STATUS_ID: 1,
  PRIVATE_STATUS_ID: 218
};
