<template>
  <flex fill class="iopr-status-list">
    <flex
      v-if="!deepLevel"
      class="label font-color-gray font-uppercase font-bold font-size-13 mx-20"
    >
      статус
    </flex>
    <flex v-if="!deepLevel && loading" class="loading"></flex>
    <flex column v-if="!loading">
      <div v-for="item in currentList" :key="item.id">
        <flex
          :style="{ paddingLeft: `${20 + item.lvl * 10}px` }"
          class="header pr-20"
          :class="{ selected: item.node.status_id === modelValue }"
          @click="$emit('update:modelValue', item.node.status_id)"
        >
          <icon
            name="down-small"
            class="icon-toggle"
            :class="{
              open: hasOpen(item.id),
              hidden: !hasChildren(item.id)
            }"
            @click.stop="setExpander(item.id, !hasOpen(item.id))"
          />
          <flex fill class="pl-10 pr-20">{{ item.node.status_name }}</flex>
          <icon
            v-if="item.node.status_id === modelValue"
            name="info"
            :size="15"
            class="info mr-10"
            @click="openInfo"
          />
          <flex class="count font-bold">{{ item.node.cnt }}</flex>
        </flex>
      </div>
    </flex>
  </flex>

  <modal
    v-if="currentStatus && isOpenInfo"
    class="border-radius-5"
    maxWidth="90%"
    maxHeight="300px"
    @click-layout="$router.back()"
  >
    <flex>
      <div class="px-20 py-5" v-html="currentStatus.status_desc"></div>
      <flex column>
        <icon
          name="close"
          :size="20"
          class="sticky font-color-gray mr-10"
          style="top: 10px"
          @click="$router.back()"
        />
      </flex>
    </flex>
  </modal>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useStatusList from '@/composition/useStatusList';
import {
  treeToFlat,
  setExpander,
  hasOpen,
  hasChildren,
  setIdKey,
  setPidKey
} from '@/composition/useFlatTree';

export default {
  name: 'iopr-status-list',
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      default: null
    },
    deepLevel: {
      type: Number,
      default: 0
    },
    openList: Array
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const { statusList, loading } = useStatusList();

    setIdKey('status_id');
    setPidKey('parent_status_id');
    const currentList = computed(() => {
      return treeToFlat(statusList.value);
    });

    const currentStatus = (() => {
      if (props.deepLevel) return null;
      return computed(() => {
        return statusList.value.find(status => {
          return status.status_id === props.modelValue;
        });
      });
    })();

    const isOpenInfo = (() => {
      if (props.deepLevel) return null;
      return computed(() => Boolean(route.query.info));
    })();
    const openInfo = () => {
      router.push({
        path: route.path,
        query: {
          ...route.query,
          info: 1
        }
      });
    };

    return {
      loading,
      currentStatus,
      isOpenInfo,
      openInfo,
      currentList,
      setExpander,
      hasChildren,
      hasOpen
    };
  }
};
</script>

<style lang="scss" scoped>
.iopr-status-list {
  .label {
    $border: 1px solid scale-color($gray, $lightness: 80%);
    border-top: $border;
    border-bottom: $border;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .header {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 13px;
    line-height: 15px;

    .count,
    .info {
      color: $gray;
    }

    &.selected {
      background: $primary;
      color: white;

      .count,
      .info {
        color: white;
      }
    }

    .icon-toggle {
      &:before {
        transform: rotate(-90deg);
      }

      &.open:before {
        transform: none;
      }

      &.hidden {
        visibility: hidden;
      }
    }
  }
}
</style>
