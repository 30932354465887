<template>
  <div class="map-place">
    <div v-if="!disabled" class="mb-15 title-common">
      {{ 'УТОЧНИТЕ НА КАРТЕ МЕСТО РАСПОЛОЖЕНИЯ ОБЪЕКТА' }}
    </div>
    <yandex-maps
      style="height: 250px"
      v-if="!loadingDistricts && !loadingAreas"
    >
      <!--
        :controls="['geolocationControl']"
-->
      <yandex-map
        :height="'300px'"
        :center="center"
        :zoom="17"
        :behaviors="['drag', 'scrollZoom']"
      >
        <yandex-geolocation
          v-if="!disabled"
          class="geolocation-button font-color-gray shadow"
          provider="browser"
          :position="{ top: '20px', left: '30px' }"
          :options="{ mapStateAutoApply: true }"
        >
          <icon name="geolocation" :size="30" />
        </yandex-geolocation>
        <zoom-map v-if="!disabled"></zoom-map>
        <yandex-object-manager
          :disabled="disabled"
          @click-dict="up($event)"
          :place="center"
          :items="itemsCurrent"
          fitToViewport
        />
      </yandex-map>
      <!-- "-->
    </yandex-maps>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { defaultMapValues } from '@/components/yandex-map/data/defaultValues';
import useDefaultValues from '@/composition/useDefaultValues';
import useAreas from '@/composition/map/useAreas';
import useDistricts from '@/composition/useDistricts';
import { useRoute } from 'vue-router';

export default {
  props: {
    polygon: {},
    disabled: {
      type: Boolean,
      default: false
    },
    center: Array
  },
  setup(props, { emit }) {
    const route = useRoute();

    const { defaultValues } = useDefaultValues();
    const { areas, loading: loadingAreas, load: loadAreas } = useAreas();

    const { currentDistrict, loading: loadingDistricts } = useDistricts();

    const areaId = computed(
      () => parseInt(route.query.areaId) || defaultValues.value.AREA_ID
    );

    const districtId = computed(
      () => parseInt(route.query.districtId) || defaultValues.value.DISTRICT_ID
    );

    const itemsCurrent = ref({});

    const filterObjects = ref({
      violation_type_id: null,
      property_type_id: null,
      parent_id: null
    });

    const currentPolygon = async () => {
      await loadAreas({ areaId: areaId.value }).then(() => {
        currentDistrict.value = areas.value.filter(
          el =>
            el.area_parent_id == areaId.value /* || el.area_id == areaId.value*/
        );
      });

      /* if (!districtId.value) {

      } else {
        currentDistrict.value = areas.value.filter(
          el => el.area_id == filterObjects.value.parent_id
        );
      }
      */
      let poly = [];

      if (currentDistrict.value.length && !props.polygon) {
        currentDistrict.value.forEach(el => {
          // poly.push({
          //   polygon: JSON.parse(el.area_polygon),
          //   id: el.area_id
          // });
          const coord = JSON.parse(el.area_polygon);
          const coordList =
            el.area_geo_type === 'multipolygon' ? coord : [coord];
          coordList.forEach(coord => {
            poly.push({
              polygon: coord,
              id: el.area_id,
              center: [el.area_lon, el.area_lat]
            });
          });
        });
      } else {
        currentDistrict.value = [
          poly.push({
            id: 10,
            polygon: props.polygon
          })
        ];
      }

      if (!currentDistrict.value) return [];

      let data = [];
      poly.forEach(el => {
        data.push(
          defaultMapValues.polygon({
            // id: el.id,
            id: Math.random(),
            geometry: {
              type: 'Polygon',
              coordinates: el.polygon
            }
          })
        );
      });
      itemsCurrent.value = data;
    };

    const model = computed({
      get() {
        return props.center;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    // model.value = 1;

    const up = event => {
      model.value = event.get('coords');
    };

    const init = () => {
      filterObjects.value.parent_id = districtId.value;
      currentPolygon();
    };
    init();

    watch(
      () => props.polygon,
      () => {
        if (props.polygon) {
          init();
        }
      }
    );

    return {
      up,
      model,
      itemsCurrent,
      loadingDistricts,
      loadingAreas
    };
  }
};
</script>

<style scoped>
.map-place {
  position: relative;
}
</style>
