export default {
  PROPERTY_TYPE_PUBLIC: 1,
  PROPERTY_TYPE_PRIVATE: 2,
  PROPERTY_TYPE_LEGAL: 3,
  codes_violation_type_legal_after: [
    6438 //nto
  ],
  codes_violation_type_legal_before: [
    3643, //nto
    5487 //rk
  ]
};
