<template>
  <div class="card_content px-10">
    <div class="py-10" v-for="(_section, x) in sections" :key="x">
      <div>
        <div class="card-title_ px-20">
          {{ _section.title }}
        </div>
      </div>
      <div class="card_value px-20">
        <div v-if="_section.type == 'email'">
          <a :href="`mailto:${object[_section.key]}`">{{
            object[_section.key]
          }}</a>
        </div>
        <div v-else>
          {{ object[_section.key] != null ? object[_section.key] : '-----' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: { object: { type: Object } },
  setup() {
    const sections = ref([
      {
        title: 'ОРГАНИЗАЦИЯ',
        key: 'owner_organization'
      },
      {
        title: 'ФИО',
        key: 'owner_name'
      },
      {
        title: 'ДОЛЖНОСТЬ',
        key: 'owner_position'
      },
      {
        title: 'E-MAIL',
        type: 'email',
        key: 'owner_email'
      },
      {
        title: 'КОНТАКТНЫЙ ТЕЛЕФОН',
        key: 'phone_work'
      },
      {
        title: 'ДОПОЛНИТЕЛЬНЫЙ ТЕЛЕФОН',
        key: 'phone_add'
      }
    ]);

    return { sections };
  }
};
</script>

<style lang="scss">
.card_content {
  .card-title_ {
    border-top: 1px solid #bebebe;
    padding-top: 10px;
    font: normal normal bold 13px/15px Roboto;
    color: #333333;
    opacity: 0.8;
  }
  .card_value {
    color: #808080;
    font: normal normal normal 13px/15px Roboto;
    margin-top: 5px;
  }
}
</style>
