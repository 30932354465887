<template>
  <div style="position:relative">
    <ui-input
      :disabled="disabled"
      type="text"
      :placeholder="placeholder"
      @update:modelValue="search()"
      v-model="addressQuery"
    >
      <template #after>
        <icon class="font-color-gray" name="search" />
      </template>
    </ui-input>
    <div
      v-if="searchAddressResults.length"
      :class="{ loading }"
      role="region"
      class="el-autocomplete-suggestion el-popper region"
      x-placement="bottom-start"
    >
      <div class="el-scrollbar">
        <div
          class="el-autocomplete-suggestion__wrap el-scrollbar__wrap"
          style="margin-bottom: -17px; margin-right: -17px;"
        >
          <ul
            class="el-scrollbar__view el-autocomplete-suggestion__list"
            role="listbox"
          >
            <li
              @click="handleSelectAddress(item)"
              v-for="(item, i) in searchAddressResults"
              :key="i"
              :id="i + 'item'"
              role="option"
              class=""
            >
              {{ item.value }}
            </li>
          </ul>
        </div>
        <div class="el-scrollbar__bar is-horizontal">
          <div
            class="el-scrollbar__thumb"
            style="transform: translateX(0%);"
          ></div>
        </div>
        <div class="el-scrollbar__bar is-vertical">
          <div
            class="el-scrollbar__thumb"
            style="transform: translateY(0%); height: 73.8764%;"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref, onUnmounted } from 'vue';
import useDadata from '@/composition/useDadata';
import helpers from '@/helpers';

export default {
  props: {
    disabled: {
      default: false
    },
    areaId: {},
    districtId: {
      default: 'area'
    },
    object: {},
    placeholder: {
      default: ''
    },
    modelValue: {
      default: {
        address_full: '',
        address_street: '',
        address_house: ''
      }
    }
  },
  setup(props, { emit }) {
    const proxyData = ref({});

    const setval = async () => {
      new Promise(resolve => {
        proxyData.value = {
          address_full: helpers.addressConcat({
            area: props.object.area_name,
            district: props.object.district_name,
            street: props.object.address_street,
            house: props.object.address_house
          }),
          address_street: props.object.address_street,
          address_house: props.object.address_house
        };
        resolve();
      }).then(() => {
        if (props.object.guid) {
          emit('update:modelValue', {
            data: data => {
              let v = {
                address_full: addressQuery.value
              };
              return {
                ...data,
                ...v
              };
            }
          });
        }
      });
    };
    setval();

    const obj = ref({
      area_name: '',
      address_street: '',
      address_house: ''
    });

    const {
      loading,
      search: searchArress,
      searchByFias,
      setKladrId,
      searchAddressResults
    } = useDadata();

    watch(
      () => props.areaId,
      () => {
        setKladrId(props.areaId, null);
      }
    );

    watch(
      () => props.districtId,
      () => {
        setKladrId(props.areaId, props.districtId);
      }
    );

    const addressQuery = computed({
      get() {
        return proxyData.value.address_full;
      },
      set(addressString) {
        proxyData.value.address_full =
          typeof addressString === 'string' ? addressString.trim() : '';
        proxyData.value.address_street = proxyData.value.address_full;
        proxyData.value.address_house = '';
      }
    });

    const search = () => {
      searchArress({
        query: addressQuery.value
      });
    };

    const updateData = point => {
      emit('update:modelValue', {
        data: () => {
          obj.value.address_full = addressQuery.value;

          return obj.value;
        },
        init: (dataFields, field) => {
          if (field.property == 'address_full') {
            dataFields.value.map(el => {
              if (el.property == 'geo_polygon') {
                if (el.component != 'mapPlaceAsot') {
                  obj.value.geo_polygon = point;
                } else {
                  el.componentAttrs.zoom = 17;
                }
                el.componentAttrs.center = point;
              }

              return el;
            });
          }
          return dataFields.value;
        }
      });
    };
    const handleSelectAddress = item => {
      if (item.data) {
        proxyData.value.address_street =
          item.data.street_with_type || item.data.settlement_with_type;
        proxyData.value.address_house = item.data.house;

        obj.value = {
          area_name: item.data.city,
          address_street: proxyData.value.address_street,
          address_house: proxyData.value.address_house
        };

        addressQuery.value = item.value;
        searchAddressResults.value = [];

        if (item.data.geo_lat && item.data.geo_lon) {
          let lon = parseFloat(item.data.geo_lon);
          let lat = parseFloat(item.data.geo_lat);
          updateData([lon, lat]);
        } else {
          searchByFias({
            query: item.data.fias_id
          }).then(list => {
            if (list.length === 1) {
              item = item.data[0];
              let lon = parseFloat(item.data.geo_lon);
              let lat = parseFloat(item.data.geo_lat);
              updateData([lon, lat]);
            }
          });
        }
      }
    };

    onUnmounted(async () => {
      searchAddressResults.value = [];
    });

    setKladrId(props.areaId, null);

    return {
      loading,
      addressQuery,
      search,
      searchAddressResults,
      handleSelectAddress
    };
  }
};
</script>

<style lang="scss">
.el-autocomplete-suggestion {
  margin: 5px 0;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}
.el-scrollbar__bar.is-vertical > div {
  width: 100%;
}
.el-scrollbar__bar.is-vertical {
  width: 6px;
  top: 2px;
}
.el-scrollbar__bar.is-horizontal {
  height: 6px;
  left: 2px;
}
.el-autocomplete-suggestion__wrap {
  max-height: 280px;
  padding: 10px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-popper[x-placement^='bottom'] {
  margin-top: 12px;
}
.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%;
}
.el-autocomplete-suggestion__list {
  margin: 0;
  padding: 0;
}
.el-autocomplete-suggestion li {
  padding: 0 20px;
  margin: 0;
  line-height: 34px;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.region {
  transition: 2s;
  position: absolute;
  transform-origin: center top;
  z-index: 2018;
  width: 100%;
  top: 30px;
  left: 0;
}
.el-scrollbar {
  overflow: hidden;
  position: relative;
}
</style>
