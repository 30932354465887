import app from '@/app.js';
import flex from './flex.vue';
import icon from './icon.vue';
import modal from './modal.vue';
import modalSlots from './modalSlots.vue';
import uiTable from './uiTable.vue';
import uiSelect from './uiSelect.vue';
import uiInput from './uiInput.vue';
import uiButton from './uiButton.vue';
import formGenerator from './form-generator.vue';
import expansionPanel from './expansion-panel.vue';
import radioGroup from './radio-group.vue';
import radioOption from './radio-option.vue';
import uiTextArea from './uiTextArea.vue';

import menuItem from './menuItem.vue';
import uiDatePicker from './uiDatePicker.vue';
import datePicker from './datePicker.vue';
import editPhotos from './edit-photos.vue';
import editFiles from './edit-files.vue';
import formSectionTitle from './formSectionTitle';

app.component('formSectionTitle', formSectionTitle);
app.component('editFiles', editFiles);
app.component('editPhotos', editPhotos);
app.component('datePicker', datePicker);
app.component('uiDatePicker', uiDatePicker);
app.component('uiTextArea', uiTextArea);
app.component('menuItem', menuItem);
app.component('flex', flex);
app.component('icon', icon);
app.component('modal', modal);
app.component('modalSlots', modalSlots);
app.component('uiTable', uiTable);
app.component('uiSelect', uiSelect);
app.component('uiInput', uiInput);
app.component('uiButton', uiButton);
app.component('form-generator', formGenerator);
app.component('expansion-panel', expansionPanel);
app.component('radio-group', radioGroup);
app.component('uiRadio', radioGroup);
app.component('radio-option', radioOption);
